//	***********************
//	***********************
//	Calculators page CSS
//	***********************
//	***********************
//Dimensional Weight Calculator
//

#calculator-page{
  margin-top: 4.5rem;
}
.calc-menu{  
    color:$medium-gray!important;
    background: #fafafa!important;      
    cursor: default!important;
    outline: none!important;
}
.number-label{
  margin-left:0.6666rem;
  padding-top: 0.3333rem;
  text-align: center;
  font-size: medium;
  color: $medium-gray;
}

.asterisk-label{
  margin-right:1rem;
  text-align: start;
  font-size: small;
  color: #d91a1a;
  cursor: pointer;
}


#calculatorsGeneralRateIncrease .table {
  background-color: white;
  >tbody>tr:hover{
    background: none!important;
    color:black;
  }  
  >tfoot{
    >tr{     
       >td{        
        cursor: default;
        text-align: right;
        padding: 0.5rem 2rem 0.5rem 0.5rem;
        color: $light-blue;
        font-weight: bold;
        font-size: 80%;
        &:first-child{            
            cursor: default;
            text-align: left;
        }
      }      
    }
  }
  >thead, >tbody{
    >tr{     
      >th, >td{        
        cursor: default;
        text-align: right;
        font-size: 80%;
        &:first-child{            
            cursor: default;
            text-align: left;
            padding-left: 0.75rem;
        }
      }      
    }
  }
}

#calculatorsDimensionalWeight .settings-field-label{
  width: 53%;
}

#calculatorsDimensionalWeight .settings-field-value{
  width: 40%;
}

#calculatorsGeneralRateIncrease .settings-field-label{
  width: 47%;
  font-size: 70%;
  padding-right: 1px;
}

#calculatorsGeneralRateIncrease .settings-field-value{
  width: 45%;
}

.popover{
  background-color: rgba(0, 0, 0, 0.8)!important;
}.left{
  >.arrow{
    &:after{
      border-left-color: rgba(0, 0, 0, 0.8)!important;  
    }
  }
}.top{
  >.arrow{
    &:after{
      border-top-color: rgba(0, 0, 0, 0.8)!important;  
    }
  }
}.right{
  >.arrow{
    &:after{
      border-right-color: rgba(0, 0, 0, 0.8)!important;  
    }
  }
}

#calculatorsDimensionalWeight .card{
  width: 49%;
}

#calculatorsDimensionalWeight input,
#calculatorsGeneralRateIncrease input {
  text-align: right;
}
#calculatorsDimensionalWeight output {
  float: right;
  text-align: left;
  font-weight: bold;
  color:$light-blue;
}

#calculatorsDimensionalWeight 
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button,
#calculatorsGeneralRateIncrease 
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button{ 
  -webkit-appearance: none;  
}

#calculatorsDimensionalWeight 
input[type=number],
#calculatorsGeneralRateIncrease input[type=number]  { 
  -moz-appearance: textfield;  
}

#calculatorsDimensionalWeight .card,
#calculatorsGeneralRateIncrease .card {
  float: left;
}

#calculatorsDimensionalWeight .card:last-child,
#calculatorsGeneralRateIncrease .card:last-child{
  margin-left: 1%;
}

#calculatorsGeneralRateIncrease .inputs-card{
  width: 30%;
}

#calculatorsGeneralRateIncrease .outputs-card{
  width: 69%;
}
#calculatorsGeneralRateIncrease .outputs-card .card-body{
padding: 1.3333rem 0.6667rem; 
}

#calculatorsGeneralRateIncrease .inputs-card .card-body{
padding: 1.333rem 7%; 
}

.button-GeneralRateIncrease{
  position: absolute;
  top:500px;
}


#calculatorMinimumChargeImpact .outputs-card{
  width: 49%;
  float:left;
  margin-top: 1rem; 
}

#calculatorMinimumChargeImpact .outputs-card #table-holder-a-view-option,
#calculatorMinimumChargeImpact .outputs-card #table-holder-b-view-option{
  border:none;
  align-items: right;
  width: auto;
  
}

#calculatorMinimumChargeImpact select {
  cursor: pointer;
  background: url(/img/arrow.png) no-repeat 95% 50%;
      -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    padding-right: 40px;
}
select::-ms-expand {
    display: none;
}
#calculatorMinimumChargeImpact select:not(:-internal-list-box) {
  overflow: hidden!important;
}

#calculatorMinimumChargeImpact .settings-field-label{
  width: 25%;
}

#calculatorMinimumChargeImpact .settings-field-value{
  width: 73%;
}
#calculatorMinimumChargeImpact .settings-field-value select{
  width: 47%;
  margin-left: 1%;
  cursor: pointer;
}

#calculatorMinimumChargeImpact .settings-field-value input{
  width: 47%;
  background-color: white;
  border: rgb(169,169,169) 1px solid;
  margin-left: 1%;
}

.slider.slider-horizontal{  
  height: 14px; 
  width: 47%;    
  margin: 0 1% 0 1% !important;
}
.slider.slider-horizontal .slider-track{
  height: 2px;
}

.slider-tooltip-bubble .tooltip{
  box-shadow: 0px 0px 0px 0px  rgba(0, 0, 0, 0.3)!important;
  background-color: #fff;
  border-radius: 8px;
  padding-right: 24px!important;
  z-index: 1010;
}

#calculatorMinimumChargeImpact .tooltip{  
  z-index: 0!important;
}

//fixing IE11 and Edge select, changint to bootstrap selectpicker
#calculatorMinimumChargeImpact{
  .bootstrap-select:not([class*=col-]):not([class*=form-control]):not(.input-group-btn){
    width:47%!important;
    padding:0rem!important;
    margin-left: 3px;
  }

  .btn.dropdown-toggle.btn-default{
    padding-top:5px;
    padding-bottom: 5px;
  border: #a9a9a9 1px solid;
  line-height: 28px;  
  }
  .dropdown-menu.open{
    z-index: 4!important;
  }

  .filter-option.pull-left{
    font-size:16px!important;
  }
  #service-type-b-id{
    padding-top: 5px;
  }
}


.slider.slider-horizontal .slider-tick-label-container {
  margin-top: 10px;
  margin-left: -20px!important;
  margin-right: -20px!important;
  overflow: hidden;
}

.slider-tick-label-container {
  .slider-tick-label:first-child {
    margin-left: -24% !important;
  }
  .slider-tick-label:last-child {
    margin-left: 20% !important;
  }
}

.slider.slider-horizontal .slider-tick-label-container .slider-tick-label:nth-of-type(1) {
    width: 62%!important;
}
.slider.slider-horizontal .slider-tick-label-container .slider-tick-label:nth-of-type(2) {
    width: 57%!important;
}
.slider-handle {
  width: 10px;
  height: 10px;
}

 .table_holder_a_view_option, .table_holder_b_view_option{
   width: 77%;
   text-align: right;
 }

 #service_type_a_name, #service_type_b_name{
   width: 23%;
   text-align: left;
 }

 .minimum_charge_amount_text{
   color:red;
   text-align: left;
 }

 #calculatorMinimumChargeImpact .table {
  >tbody>tr:hover{
    background: none!important;
    color:black;
  }  
  >thead, >tbody{
    >tr{     
      >th, >td{        
        cursor: default;
        padding-right: 0;
        &:first-child{            
            cursor: default;
        }
      }      
    }
  }
}
.calcminright {        
        margin-left: 1rem; 
}

.pagination {
  .paginate_button {
    a {
      border: none;
      outline: none;
      background-color: inherit !important;
      color: $light-blue;
    }
    a:hover {
      color: $light-blue-hover;
    }
  }
  .paginate_button.active {
    a {
      color: rgba(black, 0.5) !important;
    }
  }
  .paginate_button.disabled {
    a {
      color: $medium-gray;
      cursor: default;
    }
  }
}

#calculatorMinimumChargeImpact{
  .custom-radio {
    display: none;
    &:checked + .custom-radio-label {
      &:before {
        background-color: #20a1db;
      }
    }
  }
  .custom-radio-label {
    display: flex;
    align-items: center;
    &:before {
      content: '';
      display: inline-block;
      width: 16px;
      height: 16px;
      margin-right: 10px;
      background-color: #fff;
      border-radius: 50%;
      border: 3.5px solid #fff;
      box-shadow: 0px 0px 0px 1.5px rgba(149,152,154,1);
    }
  }
}
.columnize-2 {
      width: 230px;
      position: absolute;
      margin-left: 210px;
      height: 600px;
}
.discount-percent-input-box {
  width: 150px;
  background-color: #fff;
  border: #a9a9a9 1px solid;
  margin-left: 1%;
  height: 40px;
  margin-bottom: 10px;
  padding-left: 14px;
}
.columnize-2-right {
  width: 230px;
  margin-left: 453px;
}
.columnize-2 label, .columnize-2-right label {
font-size: 14px;
min-width: 80px;
margin-bottom: 15px;
text-align: right;
padding-right: 14px;
}
.columnize-title {
  height: 20px;
  padding-bottom: 10px;
  font-size: 14px;
}
.columnize-title-left {
  height: 20px;
  padding-bottom: 10px;
  font-size: 14px;
  text-align: right;
  padding-right:14px;
}
.tooltip {
  z-index:0;
}

.slider-tooltip-bubble .tooltip-inner {
  margin-left: 3px!important;
}
.slider-tooltip-bubble .tooltip.top .tooltip-arrow {
  margin-left: -12px!important;
}