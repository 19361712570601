/*
//	**************
//	**************
//	Datepicker CSS
//	**************
//	**************
*/


.date-picker {
  margin-left: 80px;
  display: inline-flex;
  >.flex{
    align-items: center;
  }
  .selected-week{
    width: 6rem;
    &.active{
      background: #ddf0f9;
      background: rgba($light-blue, 0.12);
      border: solid $light-blue 0.01625rem;
    }
  }
}
.ui-datepicker{
  padding: 0;
  width: 12.5rem;
  box-shadow: 0.01625rem 0.01625rem 0.3125rem;
  >.ui-datepicker-header{
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    background-color: $light-blue;
    border: none;
    color: white;
    font-weight: normal;
    .ui-datepicker-next, .ui-datepicker-prev{
      display: flex;
      align-items: center;
      height: 100%;
      top: auto;
      justify-content: center;
      span{
        top: auto;
        left: auto;
        margin: auto;
        background-image: none;
        font-family: 'Material Icons';
        font-weight: normal;
        font-style: normal;
        font-size: 1.5rem;
        line-height: 1;
        letter-spacing: normal;
        text-transform: none;
        display: inline-block;
        white-space: nowrap;
        word-wrap: normal;
        direction: ltr;
        -webkit-font-feature-settings: 'liga';
        -webkit-font-smoothing: antialiased;
        text-indent: 0;
        width: auto;
        height: auto;
        transform: translateY(-10%);
        color: white;
        cursor: pointer;
        position: relative;
        font-feature-settings: "liga" 1;
      }
    }
    .ui-state-hover{
      color: white;
      background-color: transparent;
      top: 2px;
      border: none;
      background-image: none;
      &.ui-datepicker-prev{
        left: 2px;
      }
      &.ui-datepicker-next{
        right: 2px;
      }
    }
  }
  >.ui-datepicker-calendar{
    th{
      color: #7f7f7f;
      color: rgba(black, 0.5);
    }
    th,td{
      &:first-child{
        padding-left: 0.7rem;
      }
      &:last-child{
        padding-right: 0.7rem;
        
      }
    }
    .ui-datepicker-selected-week{
      opacity: 1;
      >.ui-state-default{
        color: $light-blue;
      }
      &.ui-datepicker-current-day{
        >.ui-state-default{
          background: $light-blue;
          color: white;
        }
      }
    }
    .ui-state-default{
      background: transparent;
      border: none;
      text-align: center;
      border-radius: 50%;
    }
    .ui-state-active{
      color: initial;
    }
  }
}

.ui-datepicker table{
  font-size: getrem(16);
}

.selected-week.hasDatepicker.disabled {
  color: $dark-gray;
}

.date-range-on-search-page {
  margin-right: 60px;
  margin-top: 64px;
}
@media (max-width:1440px) {
  .date-range-on-search-page {
    margin-right: 300px;
  }
  }