#record-count{
	color: $light-blue;
}
.invoice-container{
	margin-left: -1rem;
}
.package-detail-table-title {
	margin-top:30px;
}
.button.flat.sidebar-btn-close {
	color: $dark-blue;
}

// Invoice details
#charge-summary-part{
	.details-block{
		table { background: rgb(238,238,238); }
		.details-header { padding-left: 1.333rem;}  
	}
	.invoice-category-header { font-weight: 700; page-break-before: always; }
	.invoice-subcategory {
		padding-left: 32px;
		color:#a7a7a7;
	}
	.invoice-sub-subcategory {
		vertical-align: bottom; color:$dark-blue;
		padding-left: 42px;
	}
	.invoice-item {
		padding-left: 62px;
	}
	#result-table-charges{
		width: calc(100% + 60px);
		max-width: calc(100% + 60px);
		margin-bottom: 0;
		tr{
			color:black;  
			>td,th{
				cursor: default!important;
				border: none!important;
				padding-right: 1rem!important;
				&:first-child{
					text-align: left;
				}
			}
			&:hover{
				background: rgb(238,238,238);
			}
			
		}
		.color-blue { color: #20A1DB}
		.charge-summary-total { 
			color: black;
			font-weight: 700;
			>td{
				border-top:.05556rem solid #dfdfdf!important;
			}
		}
	}
}
@media screen and (max-width: 1440px) {
#charge-summary-part .details-block .details-header{
	padding-left:0.5rem; 
}
}