table.table{
  background-color: white;
  >tbody>tr:hover{
    background: #20A1DB;
    color: #fff;
  }
  >thead{
    font-size: getrem(14);
  }
  >tbody{
    font-size: getrem(16);
  }
  >thead, >tbody{
    >tr{
      height: getrem(48);
      text-align: right;
      >th, >td{
        border-top: solid getrem(1) $outline-grey;
        border-bottom: none;
        padding: 0;
        vertical-align: middle;
        cursor: pointer!important;
        text-align: right;
        padding-right: 2rem;
        &:first-child{
            padding-left: getrem(24);
            cursor: pointer!important;
        }
      }
      >th{
        color: #888888;
        color: rgba(black, 0.5);
        .custom-dropdown-submenu{
          label{
            color: rgba(black, 0.87);
          }
          label.disabled {
            color: $medium-gray;
            pointer-events: none;
          }
        }
      }
    }
  }
}

//classes for column width contract table
.contract-first-column-width{
  width: 55%!important;
}
.contract-sec-third-column-width{
  width: 12%!important;
}
.contract-fourth-column-width{
  width: 15%!important;
}

#calculatorMinimumChargeImpact .table>thead>tr>th, #service_a_table_data .table>tbody>tr>th {
        padding-right: 0!important;
}
.result-item {
  .coresults-upload-complete.material-icons, .coresults-upload-failed.material-icons, .coresults-upload-in-process.material-icons {
    display: table-cell;
  }
}

.no-select-datalist {
  cursor: default !important;
  background-color: white !important;
  color: black !important;
}

table.table {
  > tbody {
    > tr.no-select-datalist {
      > td {
        cursor: default !important;
        &:first-child {
          cursor: default !important;
        }
      }
    }
  }
}
.sorting_disabled{
  width: 85px!important;
        }
.textcolumn-left {
  text-align: left!important;
        }
.textcolumn-center {
  text-align: center!important;
        }

.table>thead:first-child>tr:first-child>th.select-column-finder{
  padding: 0 !important;
  width: 20px!important;
  text-align: center!important;
}
.select-column-finder {
  padding: 0 !important;
  width: 27px!important;
  text-align: right!important;
}
.search-icon-column {
  padding: 10px!important;
  width: 40px!important;
  text-align: center!important;
}
.company-id-width{
  width: 40%;
}
.company-name-width{
  width: 40%;
}

.users {
  tr:hover td>span.user-company-count{
      color: #fff!important;
    }
  

  tbody>tr>td>span.user-company-count{
    text-decoration:underline;
    color: #20a1db;
  }

  .tooltip.in {
    opacity: 1 !important;
    filter: alpha(opacity=100);
  }

.tooltip-inner {
  max-width: fit-content!important;
  background-color: #fff !important;
  color: #000;
  font-size: 14px!important;
  
  div{
    margin-top:10px;
    margin-bottom: 10px;    
  }
}

.tooltip.right .tooltip-arrow {
  border-right-color: #fff;
}

}