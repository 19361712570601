@mixin transition($transition) {
  -webkit-transition: $transition;
     -moz-transition: $transition;
      -ms-transition: $transition;
       -o-transition: $transition;
          transition: $transition;
}

// a {
//   color: gray;
//   @include transition(color .3s ease);
//   &:hover {
//     color: black;
//   }
// }





@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
     -moz-border-radius: $radius;
      -ms-border-radius: $radius;
          border-radius: $radius;
}





@mixin opacity($opacity) {
  opacity: $opacity;
  $opacity-ie: $opacity * 100;
  filter: alpha(opacity=$opacity-ie); //IE8
}

//ex .faded-text {
//  @include opacity(0.8);
//}

@function calculatePx($rem) {
  $pxSize: $rem * calc(21 / 1rem);
  @return $pxSize * 1px;
}

@mixin font-size($rem) {
  font-size: calculatePx($rem);
  font-size: $rem;
}

// p {
//   @include font-size(2rem)
// }



@function calculateRem($size) {
  $remSize: calc($size / 21px);
  @return $remSize * 1rem;
}

@mixin font-size-px($size) {
  font-size: $size;
  font-size: calculateRem($size);
}

// p {
//   @include font-size-px(14px)
// }

@mixin vertical-align($position: relative) {
  position: $position;
  top: 50%;
  transform: translateY(-50%);
}


