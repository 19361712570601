.modal-open #confirmation-modal{
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10010;
  .modal-dialog{
    border-radius: 0;
    margin: 0;
    .modal-content{
      border-radius: 0;
      .modal-header{
        color: $light-blue;
        padding: getrem(24) getrem(24) getrem(16);
        border: none;
      }
      .modal-body{

        .modal-subheader{
          color: $dark-blue;
          padding: getrem(24) getrem(24) getrem(16);
          font-weight: bold;
          border: none;
        }

        max-height: 60vh;
        padding: 0 getrem(24);
        overflow-y: visible;
      }
      .modal-footer{
        display: flex;
        align-items: center;
        justify-content: flex-end;
        border: none;
        padding: 0 getrem(24) getrem(24) getrem(24);
      }
    }
  }
}



.popover{
  background-color: $half-black-fallback;
  background-color: $half-black;
  color: #ffffff;
  max-width: 19rem;
}
.popover.right{
  >.arrow{
    &:after{
      border-right-color: $dark-gray;  
    }
  }
}
.popover.left{
  >.arrow{
    &:after{
      border-left-color: $dark-gray;  
    }
  }
}
.popover.top{
  >.arrow{
    &:after{
      border-top-color: $dark-gray;  
    }
  }
}
.popover.bottom{
  >.arrow{
    &:after{
      border-bottom-color: $dark-gray;  
    }
  }
}
.popover-content{
  padding: calculateRem(24px);
}
body.modal-open div.modal-backdrop {
  z-index: 1049;
}