.headline-panel{
    border-radius: 0;
    height: 11.66rem;
    &.carousel{
        padding:0;
        .carousel-inner{
            height: 100%;
            .item{
                background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('http://placehold.it/350x150');
                height: 100%;
                padding: getrem(24) getrem(24) 0;
                color: white;
                >*:not(:first-child){
                    margin-top: getrem(24);
                }
            }
        }
        .carousel-indicators{
            right: getrem(16);
            left: auto;
            bottom: auto;
            height: auto;
            width: auto;
            top: 50%;
            transform: translateY(-50%);
            li{
                display: list-item;
                width: getrem(8);
                height: getrem(8);
                margin: 0;
                &.active{
                    width: getrem(8);
                    height: getrem(8);
                }
                &+li{
                    margin: getrem(8) 0 0 0;
                }
            }
        }
    }
    .left-panel{
        border-right: solid getrem(1) $medium-gray;
        >.row{
            height: 3.88rem;
            display: flex;
            align-items: center;
            &+.row{
                border-top: solid getrem(1) $medium-gray;
            }
             &.row:hover{
                 background-color: $light-gray;
                 cursor: pointer;
            }
            &.active .title-3{
                color: $light-blue;
            }
            >*{
                &:first-child{
                    padding: 0 0 0 getrem(24);
                }
                &:last-child{
                    padding: 0 getrem(24) 0 0;
                }
            }
        }
        .rowt{
            height: 2.88rem;
            display: flex;
            align-items: center;
            &+.rowt{
                border-top: solid getrem(1) $medium-gray;
            }
             &.rowt:hover{
                 background-color: $light-gray;
                 cursor: pointer;
            }
            &.active .title-3{
                color: $light-blue;
            }
            >*{
                &:first-child{
                    padding: 0 0 0 getrem(24);
                }
                &:last-child{
                    padding: 0 getrem(24) 0 0;
                }
            }
        }
        .sub{
            font-size: getrem(13);
            color: $half-black-fallback;
            color: $half-black;
        }
        .title-3{
            text-align: right;
        }
    }
    .right-panel{
        >*:not(.active){
            display: none;
        }
    }
}
.spread{
    margin-right:1.0rem;
}
.card-headline{
    border-bottom: solid getrem(1) $light-blue;
}
.headline-panel-super-short{
    border: solid getrem(1) $light-blue;
    height: 2rem;
    &.carousel{
        padding:0;
        .carousel-inner{
            height: 100%;
            .item{
                background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('http://placehold.it/350x150');
                height: 100%;
                padding: getrem(24) getrem(24) 0;
                color: white;
                >*:not(:first-child){
                    margin-top: getrem(24);
                }
            }
        }
        .carousel-indicators{
            right: getrem(16);
            left: auto;
            bottom: auto;
            height: auto;
            width: auto;
            top: 50%;
            transform: translateY(-50%);
            li{
                display: list-item;
                width: getrem(8);
                height: getrem(8);
                margin: 0;
                &.active{
                    width: getrem(8);
                    height: getrem(8);
                }
                &+li{
                    margin: getrem(8) 0 0 0;
                }
            }
        }
    }
    .left-panel{
        border-right: solid getrem(1) $medium-gray;
        >.row{
            height: 3.88rem;
            display: flex;
            align-items: center;
            &+.row{
                border-top: solid getrem(1) $medium-gray;
            }
             &.row:hover{
                 background-color: $light-gray;
                 cursor: pointer;
            }
            &.active .title-3{
                color: $light-blue;
            }
            >*{
                &:first-child{
                    padding: 0 0 0 getrem(24);
                }
                &:last-child{
                    padding: 0 getrem(24) 0 0;
                }
            }
        }
        .rowt{
            height: 2.88rem;
            display: flex;
            align-items: center;
            &+.rowt{
                border-top: solid getrem(1) $medium-gray;
            }
             &.rowt:hover{
                 background-color: $light-gray;
                 cursor: pointer;
            }
            &.active .title-3{
                color: $light-blue;
            }
            >*{
                &:first-child{
                    padding: 0 0 0 getrem(24);
                }
                &:last-child{
                    padding: 0 getrem(24) 0 0;
                }
            }
        }
        .sub{
            font-size: getrem(13);
            color: $half-black-fallback;
            color: $half-black;
        }
        .title-3{
            text-align: right;
        }
    }
    .right-panel{
        >*:not(.active){
            display: none;
        }
    }
}
.headline-panel-short{
    border: solid getrem(1) $light-blue;
    height: 9rem;
    &.carousel{
        padding:0;
        .carousel-inner{
            height: 100%;
            .item{
                background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('http://placehold.it/350x150');
                height: 100%;
                padding: getrem(24) getrem(24) 0;
                color: white;
                >*:not(:first-child){
                    margin-top: getrem(24);
                }
            }
        }
        .carousel-indicators{
            right: getrem(16);
            left: auto;
            bottom: auto;
            height: auto;
            width: auto;
            top: 50%;
            transform: translateY(-50%);
            li{
                display: list-item;
                width: getrem(8);
                height: getrem(8);
                margin: 0;
                &.active{
                    width: getrem(8);
                    height: getrem(8);
                }
                &+li{
                    margin: getrem(8) 0 0 0;
                }
            }
        }
    }
    .left-panel{
        border-right: solid getrem(1) $medium-gray;
        >.row{
            height: 3.88rem;
            display: flex;
            align-items: center;
            &+.row{
                border-top: solid getrem(1) $medium-gray;
            }
             &.row:hover{
                 background-color: $light-gray;
                 cursor: pointer;
            }
            &.active .title-3{
                color: $light-blue;
            }
            >*{
                &:first-child{
                    padding: 0 0 0 getrem(24);
                }
                &:last-child{
                    padding: 0 getrem(24) 0 0;
                }
            }
        }
        .rowt{
            height: 2.88rem;
            display: flex;
            align-items: center;
            &+.rowt{
                border-top: solid getrem(1) $medium-gray;
            }
             &.rowt:hover{
                 background-color: $light-gray;
                 cursor: pointer;
            }
            &.active .title-3{
                color: $light-blue;
            }
            >*{
                &:first-child{
                    padding: 0 0 0 getrem(24);
                }
                &:last-child{
                    padding: 0 getrem(24) 0 0;
                }
            }
        }
        .sub{
            font-size: getrem(13);
            color: $half-black-fallback;
            color: $half-black;
        }
        .title-3{
            text-align: right;
        }
    }
    .right-panel{
        >*:not(.active){
            display: none;
        }
    }
}
.headline-panel-tall{
    overflow: hidden;
    border: solid getrem(1) $light-blue;
    height: 18rem;
    &.carousel{
        padding:0;
        .carousel-inner{
            height: 100%;
            .item{
                background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('http://placehold.it/350x150');
                height: 100%;
                padding: getrem(24) getrem(24) 0;
                color: white;
                >*:not(:first-child){
                    margin-top: getrem(24);
                }
            }
        }
        .carousel-indicators{
            right: getrem(16);
            left: auto;
            bottom: auto;
            height: auto;
            width: auto;
            top: 50%;
            transform: translateY(-50%);
            li{
                display: list-item;
                width: getrem(8);
                height: getrem(8);
                margin: 0;
                &.active{
                    width: getrem(8);
                    height: getrem(8);
                }
                &+li{
                    margin: getrem(8) 0 0 0;
                }
            }
        }
    }
    .left-panel{
        border-right: solid getrem(1) $medium-gray;
        >.row{
            height: 3.88rem;
            display: flex;
            align-items: center;
            &+.row{
                border-top: solid getrem(1) $medium-gray;
            }
             &.row:hover{
                 background-color: $light-gray;
                 cursor: pointer;
            }
            &.active .title-3{
                color: $light-blue;
            }
            >*{
                &:first-child{
                    padding: 0 0 0 getrem(24);
                }
                &:last-child{
                    padding: 0 getrem(24) 0 0;
                }
            }
        }
        .rowt{
            height: 2.88rem;
            display: flex;
            align-items: center;
            &+.rowt{
                border-top: solid getrem(1) $medium-gray;
            }
             &.rowt:hover{
                 background-color: $light-gray;
                 cursor: pointer;
            }
            &.active .title-3{
                color: $light-blue;
            }
            >*{
                &:first-child{
                    padding: 0 0 0 getrem(24);
                }
                &:last-child{
                    padding: 0 getrem(24) 0 0;
                }
            }
        }
        .sub{
            font-size: getrem(13);
            color: $half-black-fallback;
            color: $half-black;
        }
        .title-3{
            text-align: right;
        }
    }
    .right-panel{
        >*:not(.active){
            display: none;
        }
    }
}
#freight-spend-chart, 
#audit-savings-chart, 
#optimization-savings-chart{
    padding-top: 18px;
}
.panel{
    margin:0px 0 0px 0;
}
.panel-spread-right{
    margin-left:  auto; 
    margin-right: auto; 
    width:150px;
    overflow: hidden
}
.panel-spread-middle{
    margin-left:  auto; 
    margin-right: auto; 
    width:150px;
    overflow: hidden
}
.panel-spread-middle-right{
    margin-left:  auto; 
    margin-right: auto; 
    width:150px;
    overflow: hidden
}
.panel-spread-left{
    margin-left:  auto; 
    margin-right: auto; 
    width:150px;
    overflow: hidden
}
.panel-padding{
    padding:14px!important;
}
.panel-no-padding{
    padding: 0!important;
}