#toast-notification{
  height: 2.5rem;
  position: fixed;
  bottom: 2.5rem;
  right: 0;
  display: none;
  background-color: $bg-toast;
  padding: 1rem;
  align-items: center;
  width: calc(100% - 13rem);
  margin-left: -1rem;
  color: white;
  &.show{
    display: flex!important;
  }
  .message{
    width: 100%;
  }
  .button{
    color: white;
    font-weight: bold;
  }
}