//.u- prefixed class name for single purpose utility classes like .u-underline, .u-capitalize, etc.
.flex{
  display: flex;
}
.flex-spacer{
  @media screen and (min-width: 1920px){
    flex: 0.70;
  }
  @media screen and (min-width: 1600px) and (max-width: 1919px){
    flex: 0.6;
  }
  @media screen and (min-width: 1366px) and (max-width: 1599px){
    flex: 0.5;
  }
  @media screen and (max-width: 1365px){
    flex:0.4;
  }
  &.results-table, &.package-details{
    flex:1;
  }
}
.flex-center{
  align-items: center;
  justify-content: center;
         flex: 1;
       justify-content: flex-end;
}
.deleted-flex{
  flex: none!important;
}
.bg-gray{
  background-color: $bg-grey2;
}
.font-bold{
  font-weight: bold;
}
.color-light-blue{
  color: $light-blue;
}
.color-dark-blue{
  color: $dark-blue;
  width: auto;
  margin: auto;
  overflow: hidden;
  word-wrap: break-word;
  //test
}
.color-light-blue{
  color: black;
  width: auto;
  margin: auto;
  overflow: hidden;
  word-wrap: break-word;
}
.color-dark-blue-large{
  color: $dark-blue;
  width: 140%;
  font-size: .8vw;
  margin: auto;
  overflow: hidden;
  word-wrap: break-word;
}
.color-dark-blue2{
  color: $dark-blue;
  width: 75%;
  margin: auto;
  overflow: hidden;
  word-wrap: break-word;
}
.color-dark-blue3{
  color: $dark-blue;
  width: auto;
  margin: auto;
  white-space: nowrap;
  overflow: hidden;
  word-wrap: break-word;
  text-align: center;
}
.font-size-large{
  font-size: 1.4vw;
}
.color-dark-blue4{
  color: $dark-blue;
  width: auto;
  margin: auto;
  white-space: nowrap;
  overflow: hidden;
  word-wrap: break-word;
  font-size: 1.2vw;
}
.needsPointer{
  cursor: pointer;
}
.color-light-blue2{
  color: $light-blue;
  width: 75%;
  margin: auto;
  white-space: nowrap;
  overflow: hidden;
  word-wrap: break-word;
}
.color-light-blue3{
  border-radius: 50px;
  background: $light-blue;
  color: white;
}

@keyframes fadeIn {
  from {opacity: 0;}
  to {opacity:1 ;}
}
.text-wrapper-ad{
  color: $dark-blue;
}
.color-half-black{
  color: $half-black-fallback;
  color: $half-black;
}
.w100{
  width: 100%;
}
.can-be-pressed{
  cursor: pointer;
}
.btn.active, .btn:active{
  box-shadow: none;
}
.invisible{
  visibility: hidden;
}
.spacer{
  height: 24px;
  width:24px;
}
@media print{
  .color-light-blue{
    color: $light-blue!important;
  }
  .color-dark-blue{
    color: $dark-blue!important;
  }
}
.temp-hidden {
  visibility: hidden;
}
.temp-hidden2 {
  visibility: hidden;
  display: none;
}
.no-padding{
  padding: 0!important;
}
.date-range .tabs-vertical{
  @media screen and (min-width: 1920px){
    left: -350px!important;
  }
  @media screen and (min-width: 1600px) and (max-width: 1919px){
    left: -380px!important;
  }
  @media screen and (min-width: 1366px) and (max-width: 1599px){
    left: -400px!important;
  }
  @media screen and (max-width: 1365px){
    left: -500px!important;
  }
}
    
