 
 
 #userDetailsForm .card{
   width: 50%;
   margin-top: 1.3333rem;
 }
 
 #userDetailsForm .card-permissions{
    width: 100%!important;
  }
 
 #userDetailsForm .settings-field-label{
     width: 25%;
 }
 
 #userDetailsForm .settings-field-value{
     width: 72%;
     display: inline-table;
 }
 
 #userDetailsForm .settings-field-value select{
     width: 95%;
     height:80%;
 }
 
 
 
 .fileform { 
     background-color: #FFFFFF;
    // border-radius: 2px;
     cursor: pointer;
     height: fit-content;
     overflow: hidden;
     padding: 2px 0px;
     position: relative;
     text-align: left;
     vertical-align: middle;
     width: 95%;
 }

 .fileform.disabled,
  input.disabled{
    cursor: default!important;
 }
 
 .fileform .selectbutton { 
     background-color: #20a1db;
     border: 1px solid #939494;
     color: #FFFFFF;
     float: right;
     font-size: 12px;
     height: fit-content;
     line-height: 20px;
     overflow: hidden;
     padding: 2px 4px;
     text-align: center;
     vertical-align: middle;
     width: 33%;
     display: inline-block;
 }
 
 .fileform .selectbutton.success{ 
     background-color: #fafafa;
     border: none;
     color: lightgrey;
 }    

 .fileform .disable{
     background-color: #ccc!important;
 }
  
 .fileform #csv-file-upload{
     position:absolute; 
     top:0; 
     left:-110px; // need to hide choose file button 
     bottom: 0;
     width: calc(100% + 110px); 
     -moz-opacity: 0; 
     filter: alpha(opacity=0); 
     opacity: 0;
     z-index:20;     
     cursor: pointer;        
     height: 100%; 
 }


 
 .fileform .fileformlabel {
     background-color: #FFFFFF;
     border: 1px solid #CCCCCC;
     float: left;
     margin-bottom: 4px;
     height: 30px;
     line-height: 22px;
     overflow: hidden;
     text-align: left;
     vertical-align: middle;
     width: 100%;
     display: flex;
     padding: 0px 0px 0px 2px;
 }
 
 .file-label-message {
     color: lightgray;
     font-size: .7778rem;
     display: inline-block;
     float: left;
 }
 
 .upload-messages{
     font-size: .6667rem;
     width: 100%;
     line-height: 40px;
     text-align: left;
     line-height: 0.8889rem;
     padding: 1rem;
 }
 
 .progress{
     border-radius: 0px;
     height: 100%;
 }
 
 #close{
     border: 1px solid #20a1db;
 }
 
 .upload-messages.upload-success {
    color: #66AF10;
    background-color: #E8F3DB;
    border: 1px solid #66AF10;
 }

 .upload-messages.upload-failed {   
    color: #D91A1A;
    background-color: #FADCDC;
    border: 1px solid #D91A1A;
    text-align: left;
 }
 .disabled.upload-success{
     color: green;
     background-color: greenyellow;
     border: 1px solid green;
 }
 
 .bootstrap-select:not([class*=col-]):not([class*=form-control]):not(.input-group-btn) {
      width: 75%!important;
 }
 
 
 #userDetailsForm .dropdown-menu>li>a {
     padding: 3px 3px 3px 7px!important;
   
 }
 
 #userDetailsForm .bootstrap-select.btn-group .dropdown-menu {
 max-width: 100%!important;
 max-height: 295px!important;
 overflow-x: hidden;
 }
 
 .btn{
     border-radius: 0px!important;
 }
.table { 
  >thead, >tbody{
    >tr{     
      >th, >td{        
        cursor: default!important;
        &:first-child{            
            cursor: default!important;
            
        }
        &:first-child:focus{           
            outline: -webkit-focus-ring-color none;
        }
      }      
    }
  }
}
.coresults-upload-failed span{           
            color:#E36464;
        }
 .coresults-upload-in-process span{           
            color:#FFA700;
        }
.coresults-upload-complete span{           
            color:#1FA000;
    
        }  