
/*
//	***********************
//	***********************
//	Reset Password page CSS
//	***********************
//	***********************
*/

.info-icon{
    color: $medium-gray;
    pointer-events: initial;
    cursor: pointer;
}
#reset-password-form{
    display: flex;
    flex-direction: column;
    align-items: center;
    * {
        width: 100%;
    }
    .button {
        margin-top: 8px;
    }
}