.card{
  border: solid 0.01625rem $outline-grey;
  padding: 0;
  width: 49%;
  .card-header{
    padding: 0 1rem;
    background-color: $bg-grey3;
    color: $text-default;
    height: getrem(60);
    display: flex;
    align-items: center;
    font-size: getrem(16);
  }
  .card-body{
    color: $text-default-fallback;
    color: $text-default;
    border-top: solid 0.01625rem $outline-grey;
    padding: getrem(24);
    background-color: white;
  }
    .card-body-left{
    color: $text-default-fallback;
    color: $text-default;
    border-top: solid 0.01625rem $outline-grey;
    padding: 0;
    background-color: white;
  }
}