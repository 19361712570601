#page-wrapper.help-page{
  margin-top: 1rem;
  .card{
    padding-top: 10rem;
    float: left;
    border: none;
  }
  .menu{
    width: 17.15rem;
    padding-top: 3.9rem;
    border: none;
    >.card-body{
      padding: 0;
      div{
        &.font-bold{
          padding: 0 1rem;
        }
        padding: 0 0.1rem 0 1.5rem;
        border: solid $outline-grey 0.01625rem;
        color: $text-default-fallback;
        color: $text-default;
        height: getrem(40);
        display: flex;
        align-items: center;
        &:hover{
          background-color: $light-blue;
          color: white;
        }
        &.active{
          background-color: $light-blue;
          color: white;
          font-weight: bold;
        }
      }
    }
  }
  .topic-contents {
    padding-top: 3.9rem;
  }
  .display{
    width: calc(100% - 20rem);
    margin-left: 1rem;
  }

  .services-description{
    padding:0 0 1.75em 0;
    >.service-table{
      line-height: 1.75em;
      font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
      width:100%;
      >thead{
        >tr{
        font-weight: bold; 
        font-size:14px; 
        height:2.2222rem; 
        background-color: #E0E0E0;
          >th:first-child{
            padding-left:24px;
          }
        }
      }
      >tbody{
        font-size: 12px;
        >tr{
          border: solid #E0E0E0 .01625rem;
          >td:first-child{
            font-weight:bold;
            padding-left:24px;
          }
        }
      }
    }
  }

}

