$pagerHeight: getrem(54);
.pager-container{
  .button {
    display: inline-block;
  }

  position:fixed;
  bottom:0;
  right:0;
  height: $pagerHeight;
  width:calc(100% - 13rem);
  background: #FAFAFA;
  margin-left: -1rem;
  padding-left: 1rem;
  padding-right: getrem(24);
  border: solid 0.125rem #E6E6E6;
  display: flex;
  align-items: center;
  .pager-status{
    margin-left: 2rem;
  }
  .pager-controls{
    display:inline-flex;
    align-items: center;
    height: 100%;
    position: absolute;
    right: 1rem;
    top:0;
    .material-icons,.previous-pagination-btn,.next-pagination-btn{      
      vertical-align: middle;
      color: $light-blue;
      &.disabled{
        color: $medium-gray;
        pointer-events: none;
      }
    }

    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      .pager-controls .material-icons{
          max-height: 17px;
          }   
   }
    .page-list{
      a{
        padding: 0.5rem;
        &.active{
          color: #7f7f7f;
          color: rgba(black, 0.5);
          pointer-events: none;
          cursor: default;          
        }
      }
    }
  }
}
.pager-spacer{
  height: $pagerHeight;
}