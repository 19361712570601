#date-range-filter {
.tabs-vertical{
    margin-top: 14px;
    position:absolute; 
    width:680px; 
    padding:0!important; 
    height:430px!important; 
    .tabs-nav {
        float:left; 
        width:15%; 
        margin-left:0;
        padding-left:0; 
        height:280px; 
        min-height: 240px;
        li { 
            clear: left; 
            width: 100%; 
            list-style-type: none;  
            height:40px; 
            a {
                padding-left: 14px; 
                height:40px; 
                line-height: 40px; 
                display:block; 
                color:black; 
                font-weight: 700;
            } 
        &.tabs-active {
            background-color: #20a1db;
            a{
                color:white;
            }
        }
    }

    }
    .tabs-panel {
        float: right; 
        width: 85%;  
        height:100%; 
        background: rgb(245, 245, 245); 
        border-left: 1px #dfdfdf solid;
        div.tab {            
            height: 85%;
            &.tab-hidden{
                display:none;
            }
        }
        .tab-header { 
            width: 100%; 
            height: 40px; 
            line-height: 40px; 
            padding-left: 2%; 
            color: #006290; 
            font-weight: 700; 
            font-size: 12px;
        }
        .tab-content {
             width: 100%; 
             input { 
                 width: 260px; 
                 height: 40px; 
                 padding-left: 3%;
                 margin-left: 14px;
            }
        }
        .tabs-footer {
            text-align: right; 
            position: absolute; 
            right: 14px; 
            bottom: 5%; 
            width: 50%; 
            button { 
                margin-right: 2%; 
                font-weight: 700; 
                margin-top: 5%;
                &.disabled { color: white; outline: none;}
            }
        }
        .tab-content-child { 
            width: 100%; 
            height: 40px; 
            line-height: 40px; 
            padding-left: 14px; 
            padding-right: 14px; 
            margin-bottom: 2%; 
            color: #20a1db; 
            font-weight: 700; 
            button.month-button,button {
                 width:120px; 
                 background-color: white;
                 margin-right:7px;
                 justify-content: left;
            }
            button.selected {
                background-color: #20a1db!important; 
                color:white; 
            }
            button.quarter-button { 
                width:264px; 
                background-color: white; 
                margin-right:7px; 
            }
        }
    }
    .ui-datepicker { 
        width:60%; 
        margin: 0 auto;
        box-shadow: none!important; 
        border: none!important;
        .ui-datepicker-header { height: 40px; }
        .ui-datepicker-title {line-height: 35px;}


    }    
    #Custom .ui-datepicker {width: 100%!important;}
    .ui-datepicker .ui-datepicker-calendar .ui-state-active,
    .datepicker table tr td.active:active, 
    .datepicker table tr td.active.highlighted:active, 
    .datepicker table tr td.active.active, 
    .datepicker table tr td.active.highlighted.active {
            background-color: #20a1db!important;
            color:white!important;
            border-radius: 0!important;
    }
 }
}
#accounts-filter-id, #accountsTablePartial {
    .custom-dropdown-submenu, .tabs-vertical .tabs-panel .tab-content .custom-dropdown-submenu {
        li label {
            padding-left: 100px;
            font-weight: 400;
            input {
                margin-left: -17px !important;
                width: 13px !important;
            }
        }
    }
}
#accounts-filter-id {
    #accounts-filter-dashboard-content-holder {
        max-height: none;
        width: 737px;
        overflow: hidden;
    }
    .dashboard-filter-flexbox-container {
        display: flex;
        height: 373px;
        .dashboard-filter-flexbox-item-rhs {
            flex-grow: 1;
        }
        .dashboard-filter-company-list-holder {
            overflow-y: hidden;
        }
        .tabs-vertical .tabs-panel div#Filters.tab {
            height: 100%;
            overflow-y: hidden;
        }
    }
    .dashboardFooterTableFilter {
        margin: 0;
    }

    .button-container {
        float:right;
    }
    .tabs-vertical {
        .tabs-nav.dashboard-filter-company-list-holder {
            height:100%;
        }
            margin-top: 14px;
            position:absolute; 
            width:680px; 
            padding:0!important; 
            height:445px!important; 
            .tabs-nav {
                float:left; 
                width:250px; 
                margin-left:0;
                padding:0;
                height:280px; 
                min-height: 240px;
                label {
                    padding: 5px 10px 0 14px;
                    font-weight: 400;
                    width: 224px;
                    white-space: pre-line;
                    line-height: 20px;
                    vertical-align: middle;
                }
                label:active {
                    color: #fff;
                }
                .count{
                    margin-left:-10px;
                }
                li { 
                    clear: left; 
                    width: 250px; 
                    list-style-type: none;  
                    margin-left: -4px;
                    a {
                        padding-left: 14px; 
                        line-height: 40px; 
                        display:block; 
                        color:inherit; 
                        font-weight: 300;
                    } 
                &.tabs-active {
                    background-color: #20a1db;
                    a{
                        color:white;
                    }
                }
            }
        
            }
            .tabs-panel {
                float: right; 
                width: 485px;  
                height:100%; 
                background: rgb(245, 245, 245); 
                border-left: 1px #dfdfdf solid;
                div.tab {            
                    height: 85%;
                    &.tab-hidden{
                        display:none;
                    }
                }
                .tab-header { 
                    width: 100%; 
                    height: 50px; 
                    line-height: 40px; 
                    color: rgba(0,0,0,.87); 
                    font-size: 14px;
                    padding: 5px 0 0 14px;
                    span {
                        font-weight: 900;
                    }
                }
                .tab-content {
                     width: 100%; 
                     input { 
                         width: 260px; 
                         height: 40px; 
                         padding-left: 3%;
                         margin-left: 14px;
                    }
                }
                .tabs-footer {
                    text-align: right; 
                    position: absolute; 
                    right: 14px; 
                    bottom: 5%; 
                    width: 50%; 
                    button { 
                        margin-right: 2%; 
                        font-weight: 700; 
                        margin-top: 5%;
                        &.disabled { color: white; outline: none;}
                    }
                }
                .tab-content-child { 
                    width: 100%; 
                    height: 40px; 
                    line-height: 40px; 
                    padding-left: 14px; 
                    padding-right: 14px; 
                    margin-bottom: 2%; 
                    color: #20a1db; 
                    font-weight: 700; 
                    button.month-button,button {
                         width:127px; 
                         background-color: white;
                         margin-right:7px;
                         justify-content: left;
                    }
                    button.selected {
                        background-color: #20a1db!important; 
                        color:white; 
                    }
                    button.quarter-button { 
                        width:264px; 
                        background-color: white; 
                        margin-right:7px; 
                    }
                }
            }
            .ui-datepicker { 
                width:60%; 
                margin: 0 auto;
                box-shadow: none!important; 
                border: none!important;
                .ui-datepicker-header { height: 40px; }
                .ui-datepicker-title {line-height: 35px;}
        
        
            }    
            #Custom .ui-datepicker {width: 100%!important;}
            .ui-datepicker .ui-datepicker-calendar .ui-state-active,
            .datepicker table tr td.active:active, 
            .datepicker table tr td.active.highlighted:active, 
            .datepicker table tr td.active.active, 
            .datepicker table tr td.active.highlighted.active {
                    background-color: #20a1db!important;
                    color:white!important;
                    border-radius: 0!important;
            }
         }

    .company-dropdown-divs {
        display: inline-block;
        .dashboard-num-companies-label {
            color: $light-blue;
            cursor: pointer;
        }
    }
    .tabs-vertical {
        .tabs-panel {
            .tab-content {
                input {
                    width: auto;
                    height: auto;
                    padding: 0 !important;
                    margin: 0 !important;
                }
/*                input[type="checkbox"]{
                    width: 32px !important;
                }*/
            }
        }
        .tabs-nav {
            .accounts-filter-dashboard-company-list {
                padding: 0 0 0 4px;
                height: 302px;
                overflow-y: auto;
                overflow-x: hidden;
                li {
                    padding: 0;
                    .dashboard-filter-company-list-checkbox {
                        display: inline-table;
                    }
                    .dashboard-filter-company-list-checkbox.checkbox input[type="checkbox"]:checked  label::after {
                        margin-top: -11px;
                    }
                }
            }
        }
    }

    .dropdown-submenu {
        position: relative;
    }

    .dropdown-submenu .dropdown-menu {
        top: 100%;
        left: 34px;
        margin-top: -1px;
        transition: none;
        visibility: visible;
        opacity: 1;
    }
    .dropdown-submenu:hover {
        .dropdown-menu {
            transition: none;
            visibility: visible;
            opacity: 1;
        }
    }

    .filter-column-width{
        width: 148px !important;
        .custom-dropdown-submenu {
            width: 148px;
        }
    }
    .filter-column-label{
        line-height: 31px;
        color:#20a1db!important;
        font-size:12px;
    }

    th.dt-checkboxes-select-all.sorting_disabled {
        padding-left:12px;
        width: 20px!important;
        input[type="checkbox"] {
            margin: 0 10px 0 0 !important;
            padding:0;
        }
    }

    td.row-text-left {
        padding-left: 2px;
        padding-right: 0;
    }
}

.custom-dropdown-submenu {
    li {
        label {
            padding-left: 5px;
        }
    }
}

.accounts-filter-dashboard-openclose-button-container {
    position: fixed;
    top: 15px;
    left: 400px;
    z-index: 10000;
}

#date-range-filter .tabs-vertical .ui-datepicker>.ui-datepicker-header {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    background-color: #EBEBEB;
    border: none;
    color: #006290;
    font-weight: 400;
}
#date-range-filter .tabs-vertical .ui-datepicker>.ui-datepicker-header .ui-datepicker-next span, .ui-datepicker>.ui-datepicker-header .ui-datepicker-prev span
{
    color: #6B6B6B;
}
#week-picker {
    background-color: #fff;
}
#date-range-filter .tabs-vertical #week-picker .ui-datepicker>.ui-datepicker-header{
    width: 575px;
    margin-left: -115px;
}
#date-range-filter .tabs-vertical #week-picker .ui-datepicker>.ui-datepicker-header .ui-datepicker-prev{
    left:135px;
}
#date-range-filter .tabs-vertical #week-picker .ui-datepicker>.ui-datepicker-header .ui-datepicker-next{
    right:135px;
}
.date-range{
    right: 170px;
    top:14px;
}
.disappears-on-drilldown {
    margin-right: 95px;   
    }
@media (max-width:1440px) {
    .date-range{
margin-right: 10px;
    }
}
.dr-charts {
    position: absolute;
}
.dataTables_scrollBody{
        position: relative;
        overflow-x: hidden!important;
        overflow-y: scroll!important;
        width: 100%;
        max-height: 241px!important;
    }
    .filter-buttons-bottom{
        float: right;
        padding: 18px;
    }
    .bottom-margin{
        margin-right: 14px;
    }
    .filter-column-width{
        width: 130px!important;
        text-align: left!important;
    }
    .filter-column-button{
        width: 120px!important;
        text-align: left;
        color:#999;
    }
    .column-no-padding{
        padding-right: 0!important;
    }
    .filter-head-table{
        background-color: rgb(245, 245, 245);
    }
    .column-checkbox {
        width: 20px!important;
        padding-right:10px!important; 
    }
    .filter-last-column{
        padding-right: 14px!important;
    }
    .filter-head-table-hidden{
        display: table-footer-group;
    }
    .dataTables_scrollHead{
        padding-bottom: 6px;
    }
    table#dashboard-accounts-filter-table>tbody>tr{ 
        height: 40px;
        font-size: 14px;
    }
    table#dashboard-accounts-filter-table{ 
        background: transparent!important;
    }
    input#columnAccountDashboardFilter{
        height: 34px!important;
        width: 110px!important;
    }
    table#dashboard-accounts-filter-table>tbody>tr>td:first-child {
        width: 20px!important;
    }
    .row-text-left{
        text-align: left!important;
    }
    .filter-arrow-right{
        position: absolute;
        right: 40px;
        top: 45px;
    }
    .filter-submenu{
        width: 200px;
        padding: 14px!important;
    }

#buDashboardOptions.filter-submenu {
    overflow: auto!important;
    max-height: 475%!important;
}
#carrierDashboardOptions.filter-submenu {
    overflow: auto!important;
    max-height: 475%!important;
}

/*


<style>  
    
.tabs-vertical{position:absolute; width:55em; padding:0!important; height:380px!important; max-height: 380px!important; }
.tabs-vertical .tabs-nav {float:left; width:15%; margin-left:0;padding-left:0; height:280px; min-height: 240px;}
.tabs-vertical .tabs-nav li { clear: left; width: 100%; list-style-type: none;  height:40px;  }
.tabs-vertical .tabs-nav li a {padding-left: 5%; height:40px; line-height: 40px; display:block; color:black; font-weight: 700; }
.tabs-vertical .tabs-nav li.tabs-active {background-color: #20a1db;}
.tabs-vertical .tabs-nav li.tabs-active a{color:white;}
.tabs-vertical .tabs-panel {float: right; width: 85%;  height:100%; background: rgb(235, 235, 228); border-left: 1px #dfdfdf solid;}
.tabs-vertical .tabs-panel div.tab {height: 100%;}
.tabs-vertical .tabs-panel .tab-header { width: 100%; height: 40px; line-height: 40px; padding-left: 2%; color: #20a1db; font-weight: 700; }
.tabs-vertical .tabs-panel .tab-content { width: 100%; padding-left:2%; }
.tabs-vertical .tabs-panel .tab-content input { width: 47%; height: 40px; margin-right: 2%; padding-left: 3%;}
.tabs-vertical .tabs-panel .tabs-footer { text-align: right; position: absolute; right: 5%; bottom: 5%; width: 50%; }
.tabs-vertical .tabs-panel .tabs-footer button { margin-right: 2%; font-weight: 700; margin-top: 5%;}
.tabs-vertical .tabs-panel .tabs-footer button.disabled { color: white; outline: none;}
.tabs-vertical .tabs-panel .tab-content-child { width: 100%; height: 40px; line-height: 40px; padding-left: 5%; padding-right: 5%; margin-bottom: 2%; color: #20a1db; font-weight: 700; }
.tabs-vertical .tabs-panel .tab-content-child button.month-button,
.tabs-vertical .tabs-panel .tab-content-child button { width:22%; background-color: white; margin-right:2%; }
.tabs-vertical .tabs-panel .tab-content-child button.selected {background-color: #20a1db!important; color:white; }
.tabs-vertical .tabs-panel .tab-content-child button.quarter-button { width:46%; background-color: white; margin-right:2%; }
/*datepicker*/
/*
.ui-datepicker { width:60%; box-shadow: none!important; border: none!important;}
#Custom .ui-datepicker {width: 100%!important; }
.ui-datepicker .ui-datepicker-header { height: 40px; }
.ui-datepicker .ui-datepicker-title {line-height: 35px;}
.ui-datepicker .ui-datepicker-calendar .ui-state-active,
.datepicker table tr td.active:active, 
.datepicker table tr td.active.highlighted:active, 
.datepicker table tr td.active.active, 
.datepicker table tr td.active.highlighted.active {
  background-color: #20a1db!important;
  color:white!important;
  border-radius: 0!important;
}
</style>
*/