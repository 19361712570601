/*
//	*************
//	*************
//	include all partials to be compiled
//	*************
//	*************
*/
//node module files
@import '/node_modules/bootstrap-slider/dist/css/bootstrap-slider.css';

//css files
@import "/src/css/jquery-ui.min.css";
@import "/src/css/metisMenu.css";
@import "/src/css/sb-admin-2.css";

//mixins + other utility partials
@import "vars";
@import 'mixins';
@import 'colors';
@import 'z-index';
@import 'fonts';
@import 'utility';
@import 'buttons';
@import 'form';
@import 'layout';
@import "dialog";
@import "toast";
@import "charts";
@import 'breadcrumbs';
@import 'messages';
@import 'pinning';

// other scss files
@import 'calculators';
@import 'coResultDetails';
@import 'COToolDetails';
@import 'details';
@import 'navbar';
@import "subheader";
@import 'chart-list';
@import 'pager';
@import 'off-canvas-sidebar';
@import 'panels';
@import 'package-search-btn';
@import 'login';
@import 'reset-password';
@import 'tables';
@import 'invoices';
@import 'datepicker';
@import 'search';
@import 'settings';
@import 'dataTables';
@import 'help-page';
@import "onboarding";
@import 'contract-details';
@import 'calculators';
@import 'user-company-details';
@import 'suggestion-modal';
@import 'date-range';

.color-half-white{
  color: white;
  color: $half-white;
}
.line {

	color:black;
	height:1px;
	width:100%;
	background-color:black;
}




.pagination>li>a {
	color:grey;
}

// overwrite boostrap styles
.pagination>.active>a, .pagination>.active>a:focus, .pagination>.active>a:hover, .pagination>.active>span,
.pagination>.active>span:focus, .pagination>.active>span:hover {
    z-index: 2;
    color: #fff;
    cursor: default;
    background-color: $midnight;
    border-color: $midnight;
}

.btn-default.active.focus, .btn-default.active:focus, .btn-default.active:hover, .btn-default:active.focus, .btn-default:active:focus, .btn-default:active:hover {
    color: #fff;
    background-color: #127bab;
    border-color: #8c8c8c;
}
.bootstrap-select .dropdown-toggle:focus {
  background-color: #f5f5f5;
}

.dropdown-backdrop {
  z-index: 0 !important;
  display: none;
}


/*
//	*************
//	*************
//	BEGIN SCSS
//	*************
//	*************
*/

.modal-lg {
	width:100%;
}

@media (min-width:768px){

	.modal-lg {
		width:80%;
	}
}
.search-box-header {
	height:2.34375rem;
	background-color:black;
	width:100%;
	padding:0;
	border-radius:0.75rem;
}

@media (min-width:1500px){
  .headline-box {
    overflow: hidden;
    border-radius:0.75rem;
    margin-bottom:1.0rem;
    margin-top:1.0rem;
  }

}

.invoice-table-button {

	border: 0.01625rem solid black;
	color: black;
	border-radius:0.5rem;
	opacity:0.3;
	padding:0.3rem;
}

.invoice-table-button:hover {
	opacity:0.6;
	text-decoration: none;
	color:black;
}

.invoice-table-button:active {
  opacity:0.5;
  box-shadow: 0 0.14rem 0.01rem 0 rgb(196,196,196);
  outline: 0;
}

.copyToClipboard, .downloadPartialCsvBtn {
	margin-left:0.75rem;
}
#datatables_buttons_info {
	display:none !important;
}

.loader {
	z-index:1000000000000;
	height:4.6875rem;
	width:4.6875rem;
	position:fixed;
	margin-left:auto;
	margin-right:auto;
	text-align:center;
	display:block;
}
.spacer-1 {
	margin-left:1.125rem;
}

.btn-default:active:focus,
.info-button-1:active, .info-button-1:focus,
.info-button-2:active, .info-button-2:focus,
.info-button-3:active, .info-button-3:focus,
.info-button-4:active, .info-button-4:focus,
.info-button-5:active, .info-button-5:focus,
.info-button-6:active, .info-button-6:focus {
	outline: none;
}

.red-border {
	border:0.01625rem solid $red;
}


body {
	font-family: $font_0;
	background-color: whitesmoke;
}

.today-date {
  color: $grey-3-fallback;
  color:$grey-3;
  text-align:center;
  margin-top:2.5rem;
  margin-left:1.2rem;
  font-size:$type-smaller;
}

.today-date:hover {
  color:$grey-2-fallback;
  color:$grey-2;
}

.countDown, .countUp, .week-ending {
  color:$grey-1;
  background-color:#F7F7F7;
  padding:0.75rem;
  font-size:$type-small;
}

.interval-button {
  margin-left:0.5rem;
  padding:0.375rem 0.75rem;
  background-color:#F7F7F7;
  color:$grey-3-fallback;
  color:$grey-3;
  font-size:$type-small;

}

.interval-button:focus,.countDown:focus, .countUp:focus, .week-ending:focus, .interval-button:active,.countDown:active, .countUp:active, .week-ending:active  {
  outline:none !important;
  box-shadow: 0 0.14rem 0.25rem 0 rgb(173,173,173);
}

.interval-active {
  background-color:$blue-6-fallback;
  background-color:$blue-6;
  color:#F7F7F7;
}

.interval-active:hover,.interval-active:active,.interval-active:focus {
	color:white;
	outline:0;
}
.chart-title-preview{
	white-space: normal;
	display: block;
} 


.sidebar li.active>a,
.sidebar ul li a.active:hover,
.sidebar ul li a:hover {
  background-color: rgb(225, 225, 225);
  color: $text-default-fallback;
  color: $text-default;
}

.nav>li>a:focus, .nav>li>a:hover {
  background-color: whitesmoke;
}
.nav {
  background-color: whitesmoke;
}

.sidebar ul li {
  border-bottom: none;
}
.sidebar ul li a {
  color: $text-default-fallback;
  color: $text-default;
  font-family: 'Roboto Regular', sans-serif;
  font-size: 1rem;
  font-weight: bold;
}

.custom-search-form {
  display:none;
  width: 100%;
  position:absolute;
  top:3rem;
  padding: 0;
  border: none;
}
.zero-height-element {
  height:0;
  min-height: 0;
  border: 0;
  padding: 0;
  margin: 0;
}
#Union_1 {
  fill: currentColor;
}
.blue-circle-white-text {
  position: absolute;
  display: block;
  height: 1.5rem;
  width: 1.5rem;
  line-height: 1.5rem;
  border-radius: 0.75rem;
  background-color: #20a1db;
  color: #fff;
  text-align: center;
  top: 0.6rem;
}

.content-full-width {
  box-shadow: 0 0.01625rem 0.25rem rgba(128,128,128,.59);
  padding: 0;
  margin-right: getrem(-24);
  margin-left: getrem(-24);
}


.centered-vertically-relative {
  @include vertical-align();
}

.option-current {
  border-radius: 0;
}
#page-wrapper{

  display: table;
  width: calc(100% - 13rem);
  margin: 3.5555555555555554rem 0 0 13rem;
  padding: 0 24px;
  height: calc(100vh - 3.5555555555555554rem);
}
.result-container{
  border: solid 0.01625rem $outline-grey;
  margin: 0 -15px;
  margin-top: 3.6rem;
}
/*
//	*************
//	*************
//	MEDIA QUERIES
//	*************
//	*************
*/


@media (min-width:768px) {
  .sidebar {
    background-color: $bg-grey3;
    width: 13rem;
  }
  .nav {
    background-color: $bg-grey3;
  }
  .custom-search-form {
    float: right;
    display: inline-flex;
    width: 14rem;
    margin-right: 2.35rem;
    margin-top: 0.6rem;
    position:relative;
    top:0;
  }
  .navbar-fixed-large {
    position: fixed;
    top: 0;
  }
  .nav-menu-bottom {
    position: fixed;
    width:12.4444rem;
    margin-bottom: 1rem;
  }
  .nav-menu-top {
    position: fixed;
    width:13rem;
    margin-top: 2rem;
    display: block;
  }
  .sidebar-search-submit-btn {
    display: inherit;
  }
}

@media (min-width:992px) {
	.huge {
		font-size: $type-base;
	}
}
@media (min-width:1300px) {
  .huge {
    font-size: $type-larger;
  }
}

.testmeasure {
	@include font-size($type-large);
}

.pin-modal {
  z-index: 1150;
}

// Sidebar Scroll Max Width 1440px

@media (max-width:1440px) {
  .nav-menu-bottom {
    position: relative;
    margin-top: 40px;
  }

}

// Columns margin Max Width 1440px

/*@media (max-width:1440px) {
  table.table>thead>tr>th, table.table>tbody>tr>td, table.table>tfoot>tr>td {
    padding-right: 0.5rem!important;
  }
  table.table>thead>tr>th:first-child, table.table>tbody>tr>td:first-child, table.table>tfoot>tr>td:first-child {
    padding-left: 1.5rem!important;
  }
  table.table>thead>tr>th:last-child, table.table>tbody>tr>td:last-child, table.table>tfoot>tr>td:last-child {
    padding-right: 1.5rem!important;
  }
  .textcolumn-center, table.table>tbody>tr>td, table.table>tfoot>tr>td {
    font-size: 14px!important;
  }
  }
*/
  // Scrollbar custom

/* width */
::-webkit-scrollbar {
  width: 1vw;
}

/* Track */
::-webkit-scrollbar-track {
  background: #fff;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #1894aa; 
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #B9B9B9; 
}
table.dataTable thead>tr>th.sorting, table.dataTable thead>tr>th.sorting_asc, table.dataTable thead>tr>th.sorting_desc{
  outline: none;
}

.package-data table.tbl-chart-packages{
  margin-bottom: 60px;
}

input[type="search"] {
	border: 1px solid gray;
	padding: .2em .4em;
	border-radius: .2em;
	color:black;
  }
  
  input[type="search"]::-webkit-search-cancel-button {
    -webkit-appearance: none;
    display: inline-block;
    width: 12px;
    height: 12px;
    margin-left: 10px;
    background:
      linear-gradient(45deg, rgba(0,0,0,0) 0%,rgba(0,0,0,0) 43%,#000 45%,#000 55%,rgba(0,0,0,0) 57%,rgba(0,0,0,0) 100%),
      linear-gradient(135deg, transparent 0%,transparent 43%,#000 45%,#000 55%,transparent 57%,transparent 100%);
  }