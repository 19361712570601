/*
//  **************
//  **************
//  Package Search Interface
//  (Input box below left sidebar)
//  **************
*/


.package-detail-table-title {

  font-size:3rem;
  margin-top:15px;
  margin-bottom:15px;

}

.delivery-details, .address-details, .delivery-confirmation {
  border:1px solid black;
  border-radius: 15px;
  height:140px;
  padding:10px;
}

.delivery-confirmation {
  height:100px;
}

.left-side {
  width:50%;
  text-align:left;
}

.right-side {
  width:50%;
  text-align: right;
}

.package-search-button {
outline:none !important;


}

.package-search-input {


}

.package-search-button:hover, .package-search-button:active {

		color:white;
	background-color:$midnight;
}

.package-search-button:focus {
		  border-color: rgba(82, 168, 236, 0.8);
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(82, 168, 236, 0.6);
  -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(82, 168, 236, 0.6);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(82, 168, 236, 0.6);
  outline: 1;
  outline: thin dotted \9;

}

.package-search-active {
	  border-color: rgba(82, 168, 236, 0.8);
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(82, 168, 236, 0.6);
  -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(82, 168, 236, 0.6);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(82, 168, 236, 0.6);
  outline: 0;
  outline: thin dotted \9;
	color:white;
	background-color:$midnight;
}