input+.error-messages, textarea+.error-messages{
  color:$red;
  font-size: 0.6rem;
  margin-top: getrem(4);
}
input+.success-messages{
  color: $green;
  font-size: 0.5rem;
  margin-top: getrem(4);
}
.settings-box{
  input+.error-messages, input+.success-messages {
    position: absolute;
    top: 2.666rem;
    right: 3.333rem;
  }
  .form-control-feedback{
    top: 0.75rem;
    right: 1rem;
  }
}
.message-box{
  border: solid getrem(1);
  font-size: getrem(12);
  padding: getrem(16);
  display:none;
  margin-bottom: getrem(16);
  &.error-messages{
    color: $red;
    border-color: $red;
    background-color: $red-light-fallback;
    background-color: $red-light;
  }
  &.success-messages{
    color: $green;
    border-color: $green;
    background-color: $green-light-fallback;
    background-color: $green-light;
  }
}