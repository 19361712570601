
.userEditAddForm .card{
  width: 45%!important;
  margin-top: 1.3333rem;
}
  
//.userEditAddForm .box-for-user-role{
//  height:100px;
//  align-items: start;
//}

.userEditAddForm .settings-field-label{
  width: 25%!important;
}
  
.userEditAddForm .settings-field-value,
.companyEditAddForm .settings-field-value{
  height: 2rem!important;
}
  
.radio{
  margin-top: 8px;
}

.userEditAddForm .bootstrap-select:not([class*=col-]):not([class*=form-control]):not(.input-group-btn) {
    width: 100%!important;
}
#select_company{
    padding:0px;
}

.user-radio {
  display: none;
  &:checked + .user-radio-label {
    &:before {
      background-color: #20a1db;
    }
  }
}
#user-companyid:focus{
  box-shadow: none!important;
} 
.user-radio-label {
    display: flex;
    align-items: center;
    &:before {
      content: '';
      display: inline-block;
      width: 16px;
      height: 16px;
      margin-right: 10px;
      background-color: #fff;
      border-radius: 50%;
      border: 3.5px solid #fff;
      box-shadow: 0px 0px 0px 1.5px rgba(149,152,154,1);
    }
  }

.checkbox{
  padding-left: 20px;
  width:100%;
}
.checkbox label {
  display: inline-block;
  position: relative;
  padding-left: 15px; 
  font-size: 14px;
  font-weight: 400;
}
/*.checkbox label::before {
  content: "";
  display: inline-block;
  position: absolute;
  width: 17px;
  height: 17px;
  left: 0;
  margin-left: -20px;
  border: 2px solid #757575;
  border-radius: 4px;
  background-color: #ffffff;
  -webkit-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
  -o-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
  transition: border 0.15s ease-in-out, color 0.15s ease-in-out; 
}

.checkbox label::after {
  display: inline-block;
  position: absolute;
  width: 16px;
  height: 16px;
  left: 0;
  top: 0;
  margin-left: -20px;
  padding-left: 3px;
  padding-top: 1px;
  font-size: 11px;
  color: #ffffff;
}
.checkbox input[type="checkbox"] {
  opacity: 0;
}

.checkbox input[type="checkbox"]:focus + label::before {
  outline: thin dotted;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}

.checkbox input[type="checkbox"]:checked + label::after {
  font-family: 'FontAwesome';
    content: "\f14a";
    color: #20a1db;
    font-size: 20px;
    padding: 0;
    margin-top: -5px;
}
*/
.dashboard-filter-company-list-checkbox.checkbox input[type="checkbox"]:checked + label::after {
  font-family: 'FontAwesome';
    content: "\f14a";
    /* background-color: #20a1db; */
    color: #20a1db;
    font-size: 20px;
    padding: 0;
    margin-top: 3px;
}

.checkbox input[type="checkbox"]:disabled + label {
  opacity: 0.65;
}

.checkbox input[type="checkbox"]:disabled + label::before {
  background-color: #eeeeee;
  cursor: not-allowed;
}

#cardMargin {
  position: relative;
  left: 20px;
}

.letra{
  font-weight: bold;
  padding-top: 10px;
}
//-------END Panel Divider
/* .ui-select-choices {
  position: fixed;
  top: auto;
  left: auto;
  width: inherit;
}
 */
.dropdown-menu .open {
  overflow-x: auto;
}

#userDetailsForm .bootstrap-select.btn-group .dropdown-menu {
  max-width: 100%!important;
  max-height: 295px!important;
  overflow: hidden;
}

//Serach input on top
tfoot {
  display: table-header-group;
}

//DropDOWN for table
.dropdown-menu > li > .dropdown-menu-item {
  clear: both;
   color: #333;
   display: block;
   padding: 3px 20px;
   white-space: nowrap;
   margin: 5px 0;
   width: 100%;
   text-align: left;
   text-decoration: none;
   outline: none;
   cursor: pointer;
   -moz-user-select: none;
   user-select: none;
}
.dropdown-menu > li:hover .dropdown-menu-item,
.dropdown-menu > li:focus .dropdown-menu-item{
   background-color: #f5f5f5;
   color: #262625;
}
.dropdown-menu > li > .dropdown-menu-item.checkbox {
   margin: 0;
   font-weight: normal;
}
.dropdown-menu > li > .dropdown-menu-item.checkbox input {
   display: none;
}
.table-permissions {
  margin-bottom: 0;
  .head{
  height: 0.16667rem!important;
}
}
.head-text{
  color:rgba(0,0,0,.87)!important;
  font-weight: 700;
  text-align: left!important;
  font-size: 14px;
}
.head-text-center{
  color:rgba(0,0,0,.87)!important;
  font-weight: 700;
  text-align: center!important;
}
.row-text{
  text-align: left!important;
  padding-left: 28px!important;
}
.row-text-center{
  text-align: center!important;
}
.dropdown-menu inner{
  max-height: 235px!important;
}
.dynamicCompanyTable.table>tbody>tr:hover {
  background: #f5f5f5!important;
  color: rgba(0,0,0,.87)!important;
}
#user-permissions-section{
  display: flex;
  justify-content: center;
}
.permissions-cat{
  height:auto; 
  flex-wrap: wrap; 
  flex-basis: 200px;
  font-size:14px;
  min-width: 180px;
  max-width:300px;
  align-content: flex-start;
  .title{
    margin-bottom: 20px;
    width:100%; 
    color: #006290; 
    font-size:14px; 
    text-transform: capitalize;
    font-weight: 400;
  }
}
.checkbox{
  margin:0 0 5px 0;
  font-size: 16px;
}
.button-bottom{
  margin: 20px 0;
}
.token-input{
  font-size:14px;
}
.tokenfield .token{
  font-size: 14px;
  margin: 4px 5px 0 0!important;
}
.tokenfield {
  padding-left: 5px!important;
}
.fa-remove:before, .fa-close:before, .fa-times:before {
  content: "\f057";
  font-size: 16px;
  color:#c9c9c9;
}
.company-email-input{
  background-image: none!important;
  font-size:14px!important;
//  width: 75%!important;
}
.accounts-th{
 height: 90px!important;
}
.accounts-row{
  padding-left: 24px!important;
}
.dt-checkboxes-select-all{
  text-align: left!important;
}
.dt-checkboxes-cell{
  text-align: left!important;
  padding-left: 14px!important;
}
.btn-group{
width: 250px;
}
.btn-select {
  width: 250px;
  text-align: left;
}
#listOfUniqueBU.dropdown-menu {
  width: 250px;
  margin-top: -1px;
  padding: 14px;
}
#listOfUniqueCarriers.dropdown-menu {
  width: 250px;
  margin-top: -1px;
  padding: 14px;
}
#columnAccountFilter{
  width: 250px;
}
.no-margin-bottom{
  margin-bottom: 0!important;
}
.accounts-select-arrow {
  margin-top: 10px;
  color:#999;
  .caret{
  position: absolute;
  right:10px;
  top:15px;
  
}
}
#save-top-button.disabled {
  color: #ccc;
  pointer-events: none;
  font-size: 18px;
  text-transform: uppercase;
  position: absolute;
  right: 8px;
  top: 5px;
}


/*.dropdown-menu {
  width: 472px !important;
  height: 400px !important;
}*/

/*.dropdown-menu.accounts-filter-dropdown-content {
  width: 1100px !important;
  height: 1000px !important;
  .row.content-full-width {
    margin: 0;
    .col-xs-12.col-sm-12 {
      padding: 0;
    }
    .result-container {
      margin: 0;
    }
  }
}*/

.dataTables_scrollBody thead {visibility: hidden;}

.dataTables_scrollHead{
  overflow: visible !important;
}
//END TO DISABLE DOBLE SORTING ARROWS

.open>.dropdown-toggle.btn-default:focus, .open>.dropdown-toggle.btn-default:hover {
  background-color: #fff!important;
  border-color: #8c8c8c;

}
// User companies Tooltip - Do not comment
.tooltip {
  box-shadow: 0px 4px 17px 0px  rgba(0, 0, 0, 0.3);
  background-color: #fff;
  border-radius: 8px;
  z-index: 1010;
  padding-right: 24px!important;

}
.tooltip-inner{
  max-width:350px; 
}
#userDetailsForm{
  @media screen and (max-width: 1400px) {
    max-width: 1050px;
  }
  
  .tooltip {
    box-shadow: 0px 4px 17px 0px  rgba(0, 0, 0, 0.3);
    background-color: #fff;
    border-radius: 8px;
    z-index: 1010;
    margin-top:-5px!important;
    margin-left:0px!important;
  }
  .tooltip-inner{
    max-width:350px;  
    max-height:150px!important;
    overflow-y:scroll;
    padding-right:24px;
  }
}
//DROPDOWN MENU COLUMN DIVIDE FOR COMPANIES FILTER TOOLTIP
#companiesSummaryTooltip {
  
  .dropdown-menu {
	  min-width: 200px;
  }
  .dropdown-menu.columns-2 {
  	min-width: 400px;
  }
  .dropdown-menu.columns-3 {
  	min-width: 600px;
  }
  .dropdown-menu li a {
    padding: 5px 15px;
    font-weight: 300;
  }
  .multi-column-dropdown {
    list-style: none;
    margin: 0px;
    padding: 0px;
  }
  .multi-column-dropdown li a {
    display: block;
    clear: both;
    line-height: 1.428571429;
    color: #333;
    white-space: normal;
  }
  .multi-column-dropdown li a:hover {
    text-decoration: none;
    color: #262626;
    background-color: #999;
  }
  
  @media (max-width: 767px) {
    .dropdown-menu.multi-column {
      min-width: 240px !important;
      overflow-x: hidden;
    }
  }

  .border-right-dropdown {
    border-right: 1px solid #e5e5e5;
  }

}

//FIN DROPDOWN MENU COLUMN DIVIDE

//tooltip with scrolldown
/*#tooltipWithScrollDown {
  
  
  .tooltip {
    transform: none;
    margin: 50px;    
  }
  
  .tooltip:hover > .tooltip-text, .tooltip:hover > .wrapper {
    pointer-events: auto;
    opacity: 1.0;
  }
  
  .tooltip > .tooltip-text, .tooltip >.wrapper {
    display: block;
    position: absolute;
    z-index: 6000;
    overflow: visible;
    padding: 5px 8px;
    margin-top: 10px;
    line-height: 16px;
    border-radius: 4px;
    text-align: left;
    color: #fff;
    background: #000;
    pointer-events: none;
    opacity: 0.0;
    -o-transition: all 0.3s ease-out;
    -ms-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -webkit-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
  }
  
  
  .tooltip > .tooltip-text:before, .tooltip > .wrapper:before  {
    display: inline;
    top: -5px;
    content: "";
    position: absolute;
    border: solid;
    border-color: rgba(0, 0, 0, 1) transparent;
    border-width: 0 .5em .5em .5em;
    z-index: 6000;
    left: 20px;
  }
  
  
  .tooltip > .tooltip-text:after, .tooltip > .wrapper:after  {
    top: -20px;
    content: " ";
    display: block;
    height: 20px;
    position: absolute;
    width: 60px;
    left: 20px;
  }
  
  .wrapper > .tooltip-text {
    overflow-y: auto;
    max-height: 40px;
    display: block;
  }
}*/
//

.company-filters{
  max-height: fit-content; 
  width: 737px!important;
}
.filter-column-left-title{
  margin:14px 0 36px 0;
  span {
    padding-left: 14px;
    font-weight: 900;
  }
  input {
    width: 50%;
  }
}
.filter-title-color{
  color:#20a1db;
}
.filter-title-arrow{
  color:#20a1db;
  padding: 0 7px;
}
.filter-title-regular{
  font-weight: 400!important;
}
.popover{
  width: 380px;
  border: none;
  background-color: #fff!important;
  box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.2);
  border-radius:4px;
  z-index:1001!important;
}
.popover-content {
  overflow: hidden;
  color: rgba(0, 0, 0, 0.87);
 }
 .popover.bottom>.arrow{
   opacity: 0;
 }
 .popover-column-left{
  border-right: 1px solid #E8E8E8;
  border-bottom: 1px solid #E8E8E8;
  padding: 14px 14px 14px 24px;
  min-height: 150px;
  label{
    color:rgba(0, 0, 0, 0.87);
    width: 140px;
  }
 }
 .popover-column-left-title{
  border-right: 1px solid #E8E8E8;
  padding: 11px 0 0 24px;
  height: 45px;
  ul{
    list-style: none;
    padding-left: 14px;
  }
  label{
    color:rgba(0, 0, 0, 0.54);
    font-size: 12px;
  }
 }
 .popover-column-right{
  padding: 14px 5px 14px 14px;
  border-bottom: 1px solid #E8E8E8;
  background-color: #f5f5f5;
  width: 170px;
 }
 .popover-column-right-title{
  background-color: #f5f5f5;
  padding: 11px 0 0 14px;
  ul{
  list-style: none;
  padding-left: 0;
  }
  label{
  color:rgba(0, 0, 0, 0.54);
  font-size: 12px;
}
 }
 .popover.bottom {
  margin-top: 20px!important;
}
.popover-row{
  border-bottom: 1px solid #E8E8E8;
}
.popover-label-company{
  color:#006290!important;
  margin-bottom: 0;
}
.popover-label-units{
  color:#888888!important;
  font-size:12px;
  font-weight: 400;
}
//fixing overflow select component
#userDetailsForm .bootstrap-select.btn-group .dropdown-menu {
  overflow: visible;
}
.popover-subcontent {
  margin-top:-23px;
  margin-bottom:-57px;
  min-height:150px; 
  height: 100%;
  max-height:496px;
  overflow-y:auto;
  overflow-x: hidden;
  margin: -21px;
 }
 .popover-units-list{
  font-size: 12px;
  height:121px; 
  overflow:auto;
  label {
    font-weight: 500!important;
  }
 }
.scroll-padding-right{
  padding-right: 14px;
}
/*#userDetailsForm .error-messages {
  overflow: visible;
}*/

/*#userDetailsForm {
  input+.error-messages, textarea+.error-messages{
    color:$red;
    font-size: 0.6rem;
    margin-top: getrem(4);
  }
}*/

.hline { 
  width:100%; 
  height:1px; 
  background: rgb(255, 255, 255) 
}
.table-striped>tbody>tr:nth-of-type(odd), .table-striped>tbody>tr:nth-of-type(even){
  background: #f5f5f5;
}

td.dataTables_empty {
  color: red;
}

#requiredAsterisk {
  .head-text:after {
    content: "*";
    color: #d91a1a;
  }
}

#emailErrorMessage {
  color: #d91a1a;
  font-size: .6rem;
  margin-top: .22222rem;
}

/*#footerTableFilter {
  .row {
    margin-right: 0px!important;
    margin-left: 0px!important;
  }
}*/
.footerTableFilter {
    background-color: #fff; 
    float: right; 
    width:100%; 
    padding:17px;
    border-top: 1px solid #e8e8e8;
    height: 70px;
    margin-top: -28px;
    text-align: right;
}
.color-blue{
  color:#20a1db!important;
}

.tooltip-business-units-hover {
  tr td>div.user-company-count {
    color: #20a1db;
  }
  
  tr:hover td>div.user-company-count{
      color: #fff !important;
  }

  .tooltip.in {
    opacity: 1 !important;
    filter: alpha(opacity=100);
  }

  .tooltip-inner {
    min-width: 150px;
    //max-width: fit-content!important;
    background-color: #fff !important;
    color: #000;
    font-size: 14px!important;
    max-width: 1000px;
    
    div{
      margin-top:10px;
      margin-bottom: 10px;    
    }
  }

  .tooltip.bottom .tooltip-arrow {
    border-bottom-color: #fff;
  }
}
.dt-checkboxes checkbox_checked {
  width: auto;
  height: auto;
}


#busi-tool-try {
  color: #20a1db;
}
#busi-tool-try:hover {
  color: #fff !important;
}

table.table > tbody > tr:hover #busi-tool-try{
  color: #fff !important;
}

#busi-tool-try-numerator {
  color: #20a1db;
}
#busi-tool-try-numerator:hover {
  color: #fff !important;
}

table.table > tbody > tr:hover #busi-tool-try-numerator{
  color: #fff !important;
}

#divider-span{
  color: #20a1db;
}
table.table > tbody > tr:hover #divider-span{
  color: #fff !important;
}