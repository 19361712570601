div.header-div {
  position: fixed;
  height: 3.6111rem;
  display: flex;
  align-items: center;
  background-color: $bg-grey3;
  padding: 0 getrem(24);
  z-index: 2;
  width: 100%;
  span{
    white-space: nowrap;
  }
  .empty{
    width: 100%;
  } 
  .material-icons{
    vertical-align: middle;
  }
}

.header-div-chart-details{
  width: auto!important;
}
