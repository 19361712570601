.chart-details-wrapper {
  overflow: hidden;
  .button:not(:last-child) {
    margin-right: getrem(8);
  }
  .pin-button {
    color: $half-black-fallback;
    color: #ccc;
    margin-right: getrem(16);
    font-size: 1rem;
  }
}
.chart-download-btn {
  cursor: pointer;
}
.charts-row{
    margin-top: 4rem;
}
#home-charts-holder{
  .charts-list-row{
    margin-top: 0.5rem;
  }
}
#locker{
  height: 80%;
  width: 100%;
  }

.charts-list-row{
  margin-top: 3.4rem;
}
.chart-details-container{
  margin: 3.5rem -1.4rem 47px;
  .toggle-details{
    display: flex;
    justify-content: center;
    padding-top: 20px;
    background-color: #fff;
    button{
      background-color: #20a1db;
      color: #ffffff;
      border: none;
      width: 105px;
      font-size: 1rem;

      &:hover{
        background-color: #1c8cbf;
      }
      &.--outline{
        background-color: #ffffff;
        color: #20a1db;
        border: 2px solid #20a1db;

        &:hover{
          color: #1c8cbf;
        border: 2px solid #1c8cbf;
        }
      }
    }
    
  }
  .panel{
    margin:0;
    >.panel-body{
      height: calc(100vh - 237px);
      &.with-toggle-buttons{
        height: calc(100vh - 9.87619rem - 62px);
      }
      .chart-container{
        height: calc(100vh - 300px);
        .highcharts-container{
          overflow: hidden;
          svg{
            text{
              tspan{
                font-size: 13px!important;
              }
              tspan.highcharts-text-outline{
                fill: transparent;
                stroke: transparent;
                stroke-width: 0px;
                stroke-linejoin: round;
              }
              tspan:nth-child(2){
                font: 9pt Open Sans, Verdana, sans-serif; 
                font-weight: bold;
              }
            }
            .highcharts-xaxis-labels{
              text{
                tspan:nth-child(2){
                  font: 13px Lucida Grande, Lucida Sans Unicode, Arial, Helvetica, sans-serif; 
                  font-weight: 400;
                }
              }
            } 
          }
        }
      }
    }
  }
}

.chart-container{
  .panel{
    >.panel-body{
      .chart-container{
        .highcharts-container{
          overflow: hidden;
          svg{
            text{
              tspan.highcharts-text-outline{
                fill: transparent;
                stroke: transparent;
                stroke-width: 0px;
                stroke-linejoin: round;
              }
              tspan:nth-child(2){
                font: 11px Open Sans, Verdana, sans-serif; 
                font-weight: 700;
              }
            }
          }
        }
      }
    }
  }
}

.chart-container, .chart-details-container, .chart-details-header-container{
  a{
    &:focus{
      color: inherit;
      &:hover {
        color: $light-blue-hover;
      }
    }
  }
  .menu-button{
    color: white;
  }
  .btn.menu-button:hover{
    background-color: #173f6c;
  }
  .button {
    justify-content: flex-end;
  }
  .panel-heading{
    padding: 0;
    .dropdown-item{
      position: relative;
    }
    .button {
       display: flex;
        color:#20a1db;   
    }
    .dropright{
      top: 50%;
      transform: translateY(-50%);
      position: absolute;
      right: getrem(24);
      border: solid transparent 4px;
      border-left: solid $text-default 4px;
    }
    .dropdown .btn.dropdown-toggle, .dropup .btn.dropdown-toggle{
      text-transform: none;
      font-size: getrem(21);
      padding: 0;
    }
    .dropdown.open .menu-button{
      background: rgba(32,161,219,.12);
    }
    .dropdown.open .btn.menu-button{
      background-color: #173f6c;
    }
  }
  .panel-body{
    padding: getrem(24);
    padding-bottom: 0;
    height: getrem(448);
  }
  .below-chart-caption {
    height: getrem(24);
    text-align: center;
    font-size: x-small;
  }
  .below-chart-caption-detail {
    margin-top: getrem(-24);
    z-index: 1;
    position: relative;
  }
  .panel-footer{
    display: flex;
    align-items: center;
    height: getrem(48);
    padding: 0 getrem(8);
    >.dropdown{
      margin-right: getrem(8);
    }
  }
}
.chart-details-container{
  .panel-footer{
    position: fixed;
    width: 88.125%;
    bottom: 0;
  }
}
.chart-title-container{
  padding-left: getrem(24);
  background-color: $light-blue;
  color: white;
  text-transform: capitalize;
  height: getrem(48);
  display: flex;
  align-items: center;
  .material-icons{
    margin-right: getrem(8);
  }
 
}  
 
.chart-title-container:after{
  content: "";
  height: 100%;
  display: inline-block;
  vertical-align: middle;
  overflow: hidden;
}
.chart-title-container span{
  margin-right: getrem(8);
  display: inline-block;
  display: -webkit-inline-box;
  max-height: 100%;
  vertical-align: middle;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}


.chart-title{
  flex: 1;
}
.pin-button{
  color: #1891C8;
  width: getrem(24);
  &.active{
    color: #FFCE00;
  }
}
.chart-filter-container{
  min-width: getrem(101);
  z-index: 1;
  .button.disabled {
    color: #f9f9f9 !important;
    background-color: #ccc;
    cursor: not-allowed;
  }
  .button.disabled.flat:focus {
    outline: none;
  }
}
.toggle-options{
  >*{
    color: $text-default-fallback;
    color: $text-default;
  }
  .chart-container{
    height:400px;
  }
  small{
    min-width: getrem(60);
    text-align: center;
  }
  .disabled {
    color: $medium-gray !important;
    cursor: not-allowed;
  }
  .invisible {
    display: none;
  }
}
.dropdown-submenu{
// uncomment when you show chart-description label
 // border-top: solid getrem(1) $medium-gray; 
 width: 150px;
 position:relative;
}
.dropdown-submenu>.dropdown-menu{
  top:0;
  left:100%;
  display:none;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s linear 0s,opacity 0s linear;
  transition-delay: 0.3s;
}
.dropdown-submenu:hover>.dropdown-menu{
  visibility: visible;
  opacity: 1;
  transition-delay: 100s;
}
.dropdown-submenu>a:after{
  display:block;
  content:" ";
  float:right;
  width:0;
  height:0;
  margin-top:5px;
  margin-right:-10px;
}
.force-show-submenu-element {
  display: block;
}
.no-border-top {
  border-top: none;
}
text.highcharts-subtitle{
  font-size: 0.7778rem!important;
}
.iconcenter{
  width: 100%;
  text-align: center;
  margin-top: 110px;
}
.iconcentertop{
  width: 100%;
  text-align: center;
  margin-top: 1px;
}
.flex-space-between {
  display: flex;
  justify-content: space-between;
}
.filters-container {
  display: flex;
}
.chart-container-column, .single-chart-container{
  min-width:435px;
}
.demargin{
  cursor: pointer;
  width: 100%;
  height: 544px
}
.addpix{
  margin:2px 0 1px 0;
}
.caret.export-menu.disabled  {
  visibility: hidden;
}

.chart-loading-time-caption {
  color: black;
  font-size: 14px;
  margin-right: 25px;
}

.chart-title-container {
  .chart-title-loading-time-caption-holder {
    text-transform: none;
    margin-bottom: -1px;
  }
  span {
    .chart-title-loading-time-caption {
      margin-right: 1px;
      margin-bottom: 1px;
    }
  }
}

.full-page-loading-time-caption {
  color: red;
  font-size: 22px;
  margin-top: 10px;
}

@media (max-width:1650px) {
.month-year {
  position: absolute;
  padding-bottom: 100px;
  left:25px;
}  
}
@media all and (max-width:1440px) and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .month-year{
    margin-top:-65px;
  }
}