#onboarding-page{
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .carousel{
    width: getrem(385);
    box-shadow: 0.01625rem 0.01625rem 0.5rem rgba(black, 0.3);
    margin-top: 100px;
    .item{
      background: white;
      .slide-header{
        width: 100%;
      }
      .slide-body{
        height: 245px;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        justify-content: space-around;
        padding: 1rem 3rem;
        >*{
          width: 100%;
        }
        .button {
          display:flex;
        }
      }
    }
  }
 .slide-body h3{
        margin-top: 0px;
        }
  .carousel-control{
    top: 50%;
    transform: translateY(-50%);
    height: 2rem;
    width: 2rem;
    border-radius: 50%;
    opacity: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0.01625rem 0.01625rem 0.5rem rgba(black, 0.3);
    text-shadow: none;
    color: $light-blue;
    &.left{
      background-image: none;
      left: -3rem;
    }
    &.right{
      background-image: none;
      right: -3rem;
    }
    &.disabled{
      background-color: $medium-gray;
      color: $dark-gray;
      box-shadow: none;
      pointer-events: none;
    }
  }
  .carousel-indicators{
    position:relative;
    margin: 1rem;
    padding: 0;
    width: 8%;
    left: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    li{
      height: getrem(12);
      width: getrem(12);
      border-radius: 50%;
      background: $bg-grey4;
      &.active{
        background: $light-blue;
        height: getrem(14);
        width: getrem(14);
      }
    }
  }
}