
.caption-text{
  margin-bottom: getrem(36);
}
.pin-dropdown{
  margin-bottom: getrem(24);
  label{
    font-size: getrem(14);
    font-weight: 500;
  }
  .dropdown-toggle{
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: getrem(265);
    text-align: left;
    background-color: transparent;
    border: none;
    border-bottom: solid getrem(1) $half-black-fallback;
    border-bottom: solid getrem(1) $half-black;
    padding-right: getrem(24);
    padding-left: 0;
    .caret{
      position: absolute;
      top: getrem(14);
      right: 0;
      border-top: getrem(6) dashed $half-black-fallback;
      border-top: getrem(6) dashed $half-black;
      border-bottom: getrem(6) solid transparent;
      border-left: getrem(6) solid transparent;
      border-right: getrem(6) solid transparent;
    }
  }
}
.pin-dropdown-row:last-child{
  margin-bottom: getrem(8);
}
#pin-a-chart{
  border: solid $medium-gray getrem(2);
  //margin: 0 15px;
  margin: 15px 15px;
  padding: 0;
  width: calc(50% - 30px);
  height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: $medium-gray;
}