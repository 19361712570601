.button{
  //@extend .text-small;
  display: inline-flex;
  white-space: nowrap;
  height: getrem(36);
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  border: none;
  border-radius: 0;
  padding: 0 getrem(16);
  line-height: 1;
  &.raised{
    color: white;
    background: $light-blue;
    &:hover{
      background: $light-blue-hover;
    }
  }
   &.raised2{
    color: white;
    background: $dark-blue;
    &:hover{
      background: $dark-blue-hover;
    }
  }
  &.flat{
    color: $light-blue;
    background: transparent;
    cursor: pointer;
    padding: 0 getrem(8);
    &:hover{
      background: #e4e7fa;
      background: rgba($light-blue, 0.12);
    }
    &:active, &:focus{
      background: #e4e7fa;
      background: rgba($light-blue, 0.12);
      outline: solid 1px $light-blue;
    }
  }
  &.ghost{
    color: $light-blue;
    background: #fff;
    cursor: pointer;
    padding: 0 getrem(8);
    border: solid 2px $light-blue;
    height: 32px;
    &:hover{
      background: transparent;
      color: #fff;
      border: solid 2px #fff;
    }
    &:active, &:focus{
      background: transparent;
      color: #fff;
      border: solid 2px #fff;
    }
    &.disabled{
      color: #d1d1d1!important;
      background: transparent;
      border: solid 2px #d1d1d1;
      &:hover{
        color: #d1d1d1;
        background: transparent;
        border: solid 2px #d1d1d1;
      }
    }
  }
  &.secondary{
    color: white;
    outline: solid 1px $light-blue;
    background: $dark-blue;
    &:hover{
      color: white;
      background: #112f51;
    }
  }
  &.disabled{
    color: white!important;
    &#calculators-dropdown-menu {
      color: #ccc!important;
    }
    background: $medium-gray;
    &:hover{
      color: white;
      background: $medium-gray;
    }
  }
  &:focus{
    outline: solid 1px $light-blue;
  }
  &.large{
    font-size: getrem(18);
    height: getrem(48);
    padding: 0 getrem(20);
  }
  &.dashboardArrangeCharts{
    color:$medium-gray!important;
    background: #f5f5f5!important;      
    cursor: default!important;
    outline: none!important;
  }
}

#dropdownMenu1 {
  display: inline-block;
}

.flexbox-button{
  cursor: pointer;
  flex-flow: row wrap;
  text-align: center;
  width:15%;
  display: inline-flex !important;
  align-items: center;
  justify-content: space-around;
}