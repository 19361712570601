/*
//	**************
//	**************
//	Login page CSS
//	**************
//	**************
*/
.panel-heading{
	padding: getrem(14) getrem(24);
}
.panel-body{
	padding: 0 getrem(24) getrem(16);
}

.login-page-body {
	height:100%;
	background: url("/img/login_background.jpg") center no-repeat;
	background-size: cover;
	.left-panel{
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: flex-start;
		height: 100%;
		padding-left:5rem;
		>*{
			margin: 1rem 0;
		}
		h2{
			color:white;
		}
		p{
			color: $medium-gray;
		}
		
		.button { 
			display: flex;
		}
	}
	.right-panel{
		display: flex;
		height: 100%;
		justify-content: center;
		align-items: center;
		.form-group .form-control, .button.large{
			font-size:  0.8889rem;
		}
	}
	.middle-panel{
		display: flex;
		height: 100%;
		justify-content: center;
		align-items: center;
		.form-group .form-control, .button.large{
			font-size:  0.8889rem;
		}
	}
	.legal{
		display: flex;
		width: 100%;
		justify-content: center;
		position:absolute;
		bottom:0;
		color: white;
		margin-bottom: 0.5rem;
		>a{
			color: #B5BFCC;
			padding: 0 0.5rem;
			&:hover{
				color: #ffffff;
			}
		}
	}
}



.upper-side-content {
  width: 80%;
	margin-left:auto;
	margin-right: auto;
}


.side-content {
	margin-left:auto;
	margin-right:auto;
	position: relative;
	top: 50%; 
	transform: translate(0, -50%);
}
.request-demo-btn{
	background-color: $light-blue;
	border: none;
	border-radius: 0;
	color: white;
	margin-top:10%;
	font-size:2.3rem;
}


.forgot-password-div {
	text-align:left;
	display: flex;
	span{
		float: left;
		input{
			float: left;
			margin-right: 0.4rem;
		}
	}
	a{
		float: right;
	}
}

.no-panel-body-shadow {
  box-shadow: none !important;
}

 



.forgot-password-text:hover {
		font-weight:500;
}


.forgot-password-text {
	font-weight:100;
	font-size:1.25rem;
	color:$grey-1;
}

.login-box {
	background-color: white;
	position: relative;
}

.panel-default>.login-panel-heading {
	background-color: white;
	border-color: white;
	text-align: center;
	img{
		margin-top: getrem(26);
      width: getrem(250);
	}
}
.login-panel{margin:0;max-width: 340px;}
.login-button {
	background-color: $shipware-logo-blue;
	border: 0.01625rem solid $shipware-logo-blue;
	margin-top:2.5rem;
}

.login-button:hover {
	opacity:0.8;
	background-color: $shipware-logo-blue;
		border:0.01625 solid $shipware-logo-blue;

}

.login-button:active {
	background-color: $shipware-logo-blue;
	opacity:0.7;
}

.reset-pass-modal{
	text-align: center;
	  padding: 0;
	  z-index: 100011;
	&:before{
		content: '';
		display: inline-block;
		height: 100%;
		vertical-align: middle;
		margin-right: -.25rem;
	}
	.button {
		display: flex;
		text-align: center;
	}
	.modal-header{
		.close{
			transform: translate(0, -.4375rem);
		}
	}
	.modal-dialog{
		display: inline-block;
		text-align: left;
		vertical-align: middle;
		//DESKTOP
		width: 15%;
		margin: auto;
		min-width: 22.5rem;
		min-height: 18.75rem;
		.modal-content{
			border-radius: 0;
		}
	}
	.modal-footer{
		text-align: center;
	}
}

.reset-email-prompt-text {
  font-size: larger;
  padding: .625rem .625rem 1.25rem;
  line-height: 1.1rem;
}

.reset-success{
	border: .01625rem solid $green;
	font-size: getrem(16);
	padding: getrem(24);
	color:  $green;
	background: $green-light-fallback;
	background: $green-light;
	margin-bottom: 1rem;
}
.reset-failure{
	border: .01625rem solid $red;
	font-size: getrem(16);
	padding: getrem(24);
	color: $red;
	background: $red-light-fallback;
	background: $red-light;
	margin-bottom: 1rem;
}

#forgot-message{
	font-size: 0.75rem;
	padding: 0.75rem;
}

.shipware-button{
	width: 100%;
	border: 0;
	border-radius: 0;
	font-size: 1rem;
}
.shipware-button.submit-btn{
	background-color: $dark-blue;
	color: white;	
	&:hover, &:active, &:focus,&:active:focus{
		background-color: $dark-blue-hover;
		color: white;
	}
}
.shipware-button.cancel-btn{
	background-color: transparent;
	color: $light-blue;
	margin-top:8px;
	&:hover, &:active, &:focus,&:active:focus{
		color: $light-blue-hover;
		background: rgba($light-blue, 0.12);
	}
}

.remember_me_checkbox_div {
  position: relative;
  display: inline-flex;
}
.remember_me_checkbox_div label {
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  border: .01625rem solid #ccc;
  margin-bottom: 0;
  width: 1rem;
  height: 1rem;
  margin-top: .125rem;
}

.remember_me_checkbox_div label:hover::after {
  opacity: 0.5;
}
/**
 * Create the checkbox state for the tick
 */
.remember_me_checkbox_div input[type=checkbox]:checked + label {
  background-color: $light-blue;
}

.remember_me_checkbox_div input[type=checkbox]:checked + label:after {
  content: '';
  position: absolute;
  width: .625rem;
  height: .3125rem;
  background: transparent;
  top: .1875rem;
  left: .125rem;
  border: .125rem solid #fff;
  border-top: none;
  border-right: none;
  transform: rotate(-45deg);
  opacity: 1;
}

.remember_me_label {
  width: 6.25rem;
  display: inline-flex;
  margin-left: 1.4444rem;
  margin-right: 2rem;
}

.remember_me_checkbox {
  display: none;
}
#terms-modal{
	.modal-header,
	.modal-footer{
		border-bottom: none;
		border-top: none;
		color: $blue-1-fallback;
		color: $blue-1;
	}
	.modal-footer{
		text-align: right;
	}
	.modal-dialog{
		width: 40%;
	}
	.modal-body{
		overflow-y: auto;
		height: 65vh;
		padding-top: 0;
	}
}
.has-feedback label~.form-control-feedback {
	top: 31px;
  }


#place-holder-gray-email {
	::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
		color: #999!important;
		opacity: 1!important; /* Firefox */
	}
	
	:-ms-input-placeholder { /* Internet Explorer 10-11 */
		color: #999!important;
	}
	
	::-ms-input-placeholder { /* Microsoft Edge */
		color: #999!important;
	}
}

#place-holder-gray-password {
	::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
		color: #999!important;
		opacity: 1!important; /* Firefox */
	}
	
	:-ms-input-placeholder { /* Internet Explorer 10-11 */
		color: #999!important;
	}
	
	::-ms-input-placeholder { /* Microsoft Edge */
		color: #999!important;
	}
}
