$navbarHeight: getrem(64);

.navbar{
  background-color:#173f6c;
    box-shadow: 0 .3125rem .3125rem rgba(128, 128, 128, 0.29);
    border: none;
    display: flex;
    align-items: center;
    height: $navbarHeight;
    width: 100%;
    margin: 0;
    z-index: 1002;
    >.navbar-header{
        float: none;
        margin-left: 1.333rem;
        display: flex;
        align-items: center;
        width: 100%;
        >.navbar-brand{
            margin-right: 1rem;
            height: auto;
            padding: 0;
        }
        >.companyName {
            color: $bg-grey3;
            border-left: 0.01625rem $light-blue solid;
            padding-left: 1rem;
        }
        >.temporary-message {
          border-left: 0.01625rem $light-blue solid;
          margin-left: 15rem;
          text-transform: uppercase;
        }
        
        >.navbar-banner {
            background-color: #20A1DB;
            display: inline-block;
            color: #fff;
            padding: 5px 15px;
            border-radius: 5px;
            margin-left: 10%;
            font-size: 14px;
            cursor: pointer;

            >.navbar-banner-text {
                float: left;
                margin-left: 10px;
                margin-right: 10px;

                >.banner-header {
                    font-weight: bold;
                    font-size: 16px;
                }

        >.banner-info {
                    color: rgb(186, 227, 247);

                }
            }

      >.banner-logo {
                float: left;
                width: 73px;
                height: 43px;
            }

            >.banner-action {
                float: left;
                margin-top: 6px;
            }

            ::after {
                clear: both;
            }
        }
        
    }
    >.custom-search-form{
        margin-bottom: getrem(12);
        margin-right: getrem(24);
        width: 35%;
        >.form-control{
            width: getrem(390);
        }
        >.input-group-btn{
            width: auto;
            min-width: getrem(56);
        }
    }
    >.sidebar{
        top: $navbarHeight;
        margin: 0;
        >.sidebar-nav{
            top: $navbarHeight;
            margin: 0;
        }
        ul li a{
            padding: 0 getrem(20);
            height: getrem(48);
        }
    }
}

.top-nav-btn {
  margin-top: 0.7778rem;
}
.nav-menu-bottom {
  bottom: 0;
  width: 100%;
}
.nav-menu-top {
  top: 0;
  width: 13rem;
}
.flexbox-nav {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: inline-flex !important;
  -webkit-flex-flow: row wrap;
  flex-flow: row wrap;
  text-align: center;
  width:100%;
  height: getrem(48);
  align-items: center;
  cursor: pointer;
}
@media (max-width:1440px) {
  .flexbox-nav {
    height: getrem(40);
    cursor: pointer;
  }
  .nav-menu-bottom{
    margin-top:40px;
  }
}   
.flexbox-nav > * {
  flex: 1 100%;
  cursor: pointer;
}
.flexbox-nav:hover {
  background-color: inherit;
  color: $light-blue-hover;
  cursor: pointer;
  .nav-desc-sides-lhs, .nav-desc-title{
    color: $light-blue-hover;
  }
}
.nav-desc-title {
  flex: 3 0;
  text-align: left;
  order: 2;
  line-height: 1;
  font-size: getrem(16);
  font-weight: 700;
  color: $text-default-fallback;
  color: $text-default;
}
.nav-desc-title-gray {
  flex: 3 0;
  text-align: left;
  order: 2;
  line-height: 1;
  font-size: getrem(16);
  font-weight: 700;
  color: $text-default-fallback;
  color: $text-default;
  opacity: 0.5;
}
.nav-desc-sides {
  flex: 1 auto;
}
.t-image-c{
  position: relative;
  top: -7px;
  left: 43px;
  opacity: 1;
}
.t-image-modal{
  cursor: pointer;
  position: absolute;
  top: -7px;
  left: 225px;
  opacity: 1;
}
.t-image-r{
  position: relative;
  top: -5px;
  left: 12px;
  opacity: 1;
}
.t-image-p{
  position: relative;
  top: -6px;
  left: 22px;
  opacity: 1;
}
.t-image-i{
  position: relative;
  top: -6px;
  left: 33px;
  opacity: 1;
}
.nav-desc-sides-lhs {
  order: 1;
  text-align: left;
  width: 10px;
  height: 22px;
  margin-top: -2px;
  color: #7f7f7f;
  color: rgba(0, 0, 0, .5);
}

.nav-link-active{
  .nav-desc-sides-lhs, .nav-desc-title {
    color: $light-blue;
  }
}
.nav-desc-sides-rhs {
  order: 3;
}
.sidebar-search-input-box {
  color: $text-default-fallback;
  color: $text-default;
  font-family: 'Roboto Regular', sans-serif;
  font-size: getrem(14);
  border-radius: 0;
}
.sidebar-search-submit-btn {
  color: white;
  background-color: $light-blue;
  border-radius: 0;
  border-color: $light-blue;
  display: none;
  width: getrem(56);
}
.sidebar-nav{
  position: fixed;
  left: 0;
  top: 3.5555555555555554rem;
  width: 13rem;
  padding-left: 10px;
  z-index: 1001;
}
.sidebar-nav-background{
  position: fixed;
  left: 0;
  top: 3.5555555555555554rem;
  width: 13rem;
  padding-left: 10px;
  height: 100%;
  z-index: 1000;
  background-color: #f5f5f5;
}
