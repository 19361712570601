
/*
//	***********************
//	***********************
//	Settings page CSS
//	***********************
//	***********************
*/
.card-profile{
  margin-top: 4.5rem;
}
.card-security{
  margin-top: 4.5rem;
}
.settings-box{
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem .5rem 0;
  position: relative;
}
.settings-field-label{
  text-align: right;
  padding: 0.5rem 1rem .5rem 0;
  font-weight: normal;
  font-family: 'Roboto Regular',sans-serif;
  font-size: getrem(14);
  white-space: nowrap;
  width: 9rem;
  &.required:after{
    content: " *";
    color: $red;
  }
}
.settings-field-value{
  padding: 0.5rem 1rem;
  font-weight: normal;
  font-family: 'Roboto Regular',sans-serif;
  font-size: getrem(16);;
  max-width: 550px;
}
#securityForm .settings-field-label{
  width: 16rem;
}
#securityForm .forgot-password-by-email{
  font-size: 0.77778rem;
  padding-left: 0;
  border:none;
  margin-top: 0.5rem;  
}
