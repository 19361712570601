input:-webkit-autofill, textarea:-webkit-autofill, select:-webkit-autofill{
  background: white;
}
input::placeholder{
  color: $placeholder-gray-fallback!important;
  color: $placeholder-gray!important;
}
.form-control{
  height: getrem(36);
}
.settings-field-value{
  &.invalid{
    border: solid 0.01625rem $red;
  }
  &.valid{
    border: solid 0.01625rem $green;
  }
}
.form-group{
  margin-bottom: getrem(16);
  .form-control.tokenfield {
    height: auto;
  }
  .form-control{
    border-radius: 0;
    box-shadow: none;
    color: $text-default-fallback;
    color: $text-default;
    padding: 0 getrem(16);
    font-size: getrem(19);
    height: getrem(36);
    &.invalid{
      border: solid 0.01625rem $red;
    }
    &.valid{
      border: solid 0.01625rem $green;
    }
  }
  input.form-control{
    height: getrem(36);
    font-size: getrem(19);
  }
}
.dropup .button[aria-expanded=true] .caret{
  border-bottom: none;
  border-top: 4px dashed;
}
.dropdown .button[aria-expanded=true] .caret{
  border-top: none;
  border-bottom: 4px dashed;
}
.dropdown, .dropup{
  .btn.dropdown-toggle {
    text-transform: capitalize;
    .caret {
      margin-left: getrem(8);
    }
  }
  .dropdown-menu{
    margin: 0;
    min-width: 7rem;
    padding: getrem(16) 0;
    z-index: 1;
    max-height: getrem(385);
    //overflow: auto;
    &.scrollable{
      overflow-y: scroll;
    }
    &.dropdown-left{
      left: auto;
      right: 0;
    }
    .dropdown-item{
      padding: 0.125rem getrem(24);
      display: flex;
      align-items: center;
      color: $text-default-fallback;
      color: $text-default;
      height: getrem(36);
      cursor: pointer;
      .material-icons{
        color: $light-blue;
        margin-right: 1rem;
        visibility: hidden;
      }
      &.active .material-icons{
        visibility: visible;
      }
    }
  }
}
.dropdown-submenu{
  .dropdown-menu{
    top: 0;
    left: 100%;
    &.dropdown-submenu-left{
      left: 0;
      transform: translateX(-100%);
    }
  }
}

#company-dropdown-container {
  .dropdown-menu {
    overflow: auto;
  }
}


#test{
  width: 100%;
  display:inline-block;
}
.dropdown-menu{
  .dt-button.dropdown-item{
    &:hover{
      background-color: $light-blue;
      color: #fff;
    }
  }
}

//The switch - the box around the slider 
$switchSize: 1rem;
.switch {
  position: relative;
  display: inline-flex;
  width: $switchSize*2;
  height: calc($switchSize*4/3);
  align-items: center;
}

//Hide default HTML checkbox 
.switch input {display:none;}

//The toggler 
.toggler {
  cursor: pointer;
  background-color: $medium-gray;
  transition: .4s;
  height: $switchSize*0.75;
  width: 100%;
}

.toggler:before {
  position: absolute;
  content: "";
  height: $switchSize;
  width: $switchSize;
  left: 0;
  bottom: 4px;
  background-color: $light-gray;
  transition: .4s;
  box-shadow: 1px 1px 5px $dark-gray;
}

input:checked + .toggler {
  background-color: $green-light-fallback;
  background-color: $green-light;
  &:before{
    background-color: $green;
  }
}

input:focus + .toggler {
  box-shadow: 0 0 1px black;
}

input:checked + .toggler:before {
  transform: translateX(100%);
}

//Rounded togglers 
.toggler.round {
  border-radius: $switchSize;
}

.toggler.round:before {
  border-radius: 50%;
}

//The slider 
.slider-handle{
  background: $light-blue;
  box-shadow: none;

}
.slider-selection.tick-slider-selection{
  background: $light-blue;
  box-shadow: none;
}
.slider.slider-horizontal .slider-track{
  height: 6px;
  margin-top: -3px;
  background: transparent;
  box-shadow: none;
}
.slider-track-high{
  background: $medium-gray;
}
.slider-tick{
  display:none;
}
input:focus{
  outline: none;
  box-shadow: 0 0 5px $light-blue;
}
.dropdown-menu li{
  a:not(.no-active):hover {
    background-color: #20a1db;
    color: #fff !important;
  }
}
.dropdown-menu li.active{
  background-color: #20a1db;
  color: #fff !important;
}
#contact-us-modal textarea{
    resize:vertical; 
    min-height: 60px;
    max-height: 150px;
}
.radio label {
  font-size: 14px;
}

//dropdown arrow IE
#company-select-container .open > .dropdown-toggle.btn-default:focus, .open > .dropdown-toggle.btn-default:hover {
  color: black!important;
  border-color: #8c8c8c;

}

#carrier-select-container .open > .dropdown-toggle.btn-default:focus, .open > .dropdown-toggle.btn-default:hover {
  color: black!important;
  border-color: #8c8c8c;
}