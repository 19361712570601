#contractDetailsForm{
  margin: getrem(24) getrem(-12);
  >div{
    width: 50%;
    float: left;
    padding: 0 getrem(12);
    margin-top:3.5rem;
  }
  .card-body{
    label{
      font-weight: normal;
      width: 4rem;
    }
    label.volume-based-discount-caption{
      width: 10rem;
    }
    .radio{
      label{
        width: 50%;
        padding-left: 0;
      }
    }
  }  
  table {
    min-width: getrem(320);
    margin-top: 1rem;
    margin-bottom: 1rem;
    td, th, input{
      width: getrem(64);
      height: getrem(24);
    }
    td+td, th+th{
      padding-left: getrem(16);
    }
    tr+tr > td{
      padding-top: getrem(8);
    }
  }
  .service-status{
    border-radius: 50%;
    border: 1px solid $medium-gray;
    width: getrem(24);
    height: getrem(24);
    display: flex;
    align-items: center;
    justify-content: center;
    //position: absolute;
    margin-left: auto;
  }
  .services-status-title {
    //position: absolute;
    margin-left: auto;
  }
  .service-item{
    height: getrem(54);
    align-items: center;
    border-bottom: 1px solid #e3e3e3;
    padding: 0 getrem(24);
    +.service-item{
      border-top: solid 1px $outline-grey;
    }
  }
  .edit{
    background: $yellow;
    color: white;
    border: none;
  }
  .active{
    background: $green-light-fallback;
    background: $green-light;
    color: $green;
    border: none;
    font-weight: bold;
  }
  .settings-box input+.error-messages, .settings-box input+.success-messages {
    left: 9rem;
  }
  #carrier-prefix{
    position: absolute;
    left: 8.2rem;
    top: 50%;
    transform: translateY(-50%);
  }  
}
.right-column{
      margin-left: 3rem;
      min-width: 6rem;
    }
#contractDetailsForm table input{
     text-indent: 5px;
}
.card.service-settings {
  min-width: 515px;
}
.js-split-table-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  white-space: nowrap;
  overflow: auto;
}

@media screen and (max-width: 1690px) {
  .js-split-table-container {
    display: block;
  }
  .js-split-table-container.js-split-table-2 {
    .js-split-table-item2 {
      margin-top: -34px !important;
      thead {
        visibility: hidden;
      }
    }
  }
}


.custom-radio {
  display: none;
  &:checked + .custom-radio-label {
    &:before {
      background-color: #20a1db;
    }
  }
}
.custom-radio-label {
  display: flex;
  align-items: center;
  &:before {
    content: '';
    display: inline-block;
    width: 16px;
    height: 16px;
    margin-right: 10px;
    background-color: #fff;
    border-radius: 50%;
    border: 3.5px solid #fff;
    box-shadow: 0px 0px 0px 1.5px rgba(149,152,154,1);
  }
}
