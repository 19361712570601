/*!
 * Start Bootstrap - SB Admin 2 Bootstrap Admin Theme (http://startbootstrap.com)
 * Code licensed under the Apache License v2.0.
 * For details, see http://www.apache.org/licenses/LICENSE-2.0.
 */

body {
    background-color: #f8f8f8;
}

#wrapper {
    width: 100%;
}

#page-wrapper {
    padding: 0 15px;
    min-height: 568px;
}

@media(min-width:768px) {
    #page-wrapper {
        position: inherit;
        margin: 0 0 0 200px;
        padding: 0 30px;
        border-left: 1px solid #e7e7e7;
    }
}

.navbar-default {
    border-color:#222D3D;
}

.navbar-top-links {
    margin-right: 0;
}

.navbar-top-links li {
    display: inline-block;
}

.navbar-top-links li:last-child {
    margin-right: 0;

}

@media (min-width:900px){

    .navbar-top-links li:last-child {
    margin-right: 15px;
}


}

@media (min-width:765px) and (max-width:780px){

    .user-dropdown-toggle {
        display:none;
    }
}

.navbar-top-links li a {
    padding: 15px;
    min-height: 50px;
}

.navbar-top-links .dropdown-menu li {
    display: block;
}

.navbar-top-links .dropdown-menu li:last-child {
    margin-right: 0;
}

.navbar-top-links .dropdown-menu li a {
    padding: 3px 20px;
    min-height: 0;
}

.navbar-top-links .dropdown-menu li a div {
    white-space: normal;
}

.navbar-top-links .dropdown-messages,
.navbar-top-links .dropdown-tasks,
.navbar-top-links .dropdown-alerts {
    width: 310px;
    min-width: 0;
}

.user-dropdown-toggle {
    color:white;
}

.user-dropdown-toggle:active, .user-dropdown-toggle:focus {
    color:grey !important;
    background-color: #3C506E;
}

.nav>li>a:focus, .nav>li>a:hover {

    background-color: #222D3D;
    color:grey;
    opacity:0.8;
}

.nav .open>a, .nav .open>a:focus, .nav .open>a:hover {
        color:white !important;
    background-color: #3C506E;

}
/*
.dropdown-toggle:hover {

        background-color:green;
    color:white;
}*/

.navbar-top-links .dropdown-messages {
    margin-left: 5px;
}

.navbar-top-links .dropdown-tasks {
    margin-left: -59px;
}

.navbar-top-links .dropdown-alerts {
    margin-left: -123px;
}

.navbar-top-links .dropdown-user {
    right: 0;
    left: auto;
}

.sidebar .sidebar-nav.navbar-collapse {
    padding-right: 0;
    padding-left: 0;
}

.sidebar .sidebar-search {
    padding: 20px;
}

.sidebar ul li {
    border-bottom: 1px solid #616161;
}

.sidebar ul li a {
    color:#F2F2F2;
}

/*.sidebar ul li a.active {
    background-color: red;
}
*/
/* refers to when you click a sidebar item, the subsequent 'active' state of whatever
button was clicked */
.sidebar li.active>a,

.sidebar ul li a.active:hover,
.sidebar ul li a:hover {
    background-color: #3C506E;
    color:white;
}

.sidebar .arrow {
    float: right;
}



.sidebar .fa.arrow:before {
    content: "\f104";
}

.sidebar .active>a>.fa.arrow:before {
    content: "\f107";
}



.sidebar .nav-second-level li,
.sidebar .nav-third-level li {
    border-bottom: 0!important;
}

.sidebar .nav-second-level li a {
    padding-left: 37px;
    background:white;
    color:gray;

}

.sidebar .nav-second-level {
    /* Rectangle 170: */
box-shadow: inset 0 0 5px 0 rgba(86,86,86,0.2);
    }

.sidebar .nav-second-level li a:hover {

    background:#6181B0;
    color:white;
}

.sidebar .nav-third-level li a {
    padding-left: 52px;
}

.sidebar .nav>li>a {

    padding: 20px;
}

.nav {
    background-color: #222D3D;
}



@media(min-width:768px) {

    .nav {
        background-color: #222D3D;
    }

    .sidebar {
        z-index: 1;
        position: absolute;
        width: 200px;
        margin-top: 50px;
        background-color: #222D3D;
    }

    .navbar-top-links .dropdown-messages,
    .navbar-top-links .dropdown-tasks,
    .navbar-top-links .dropdown-alerts {
        margin-left: auto;
    }
}

.btn-outline {
    color: inherit;
    background-color: transparent;
    transition: all .5s;
}

.btn-primary.btn-outline {
    color: #428bca;
}

.btn-success.btn-outline {
    color: #5cb85c;
}

.btn-info.btn-outline {
    color: #5bc0de;
}

.btn-warning.btn-outline {
    color: #f0ad4e;
}

.btn-danger.btn-outline {
    color: #d9534f;
}

.btn-primary.btn-outline:hover,
.btn-success.btn-outline:hover,
.btn-info.btn-outline:hover,
.btn-warning.btn-outline:hover,
.btn-danger.btn-outline:hover {
    color: #fff;
}

.chat {
    margin: 0;
    padding: 0;
    list-style: none;
}

.chat li {
    margin-bottom: 10px;
    padding-bottom: 5px;
    border-bottom: 1px dotted #999;
}

.chat li.left .chat-body {
    margin-left: 60px;
}

.chat li.right .chat-body {
    margin-right: 60px;
}

.chat li .chat-body p {
    margin: 0;
}

.panel .slidedown .glyphicon,
.chat .glyphicon {
    margin-right: 5px;
}

.chat-panel .panel-body {
    height: 350px;
    overflow-y: scroll;
}

.login-panel {
    margin-top: 30%;
}

.flot-chart {
    display: block;
    height: 400px;
}

.flot-chart-content {
    width: 100%;
    height: 100%;
}

.dataTables_wrapper {
    position: relative;
    clear: both;
}

table.dataTable thead .sorting,
table.dataTable thead .sorting_asc,
table.dataTable thead .sorting_desc,
table.dataTable thead .sorting_asc_disabled,
table.dataTable thead .sorting_desc_disabled {
    background: 0 0;
}

table.dataTable thead .sorting_asc:after {
    content: "\f0de";
    float: right;
    font-family: fontawesome;
}

table.dataTable thead .sorting_desc:after {
    content: "\f0dd";
    float: right;
    font-family: fontawesome;
}

table.dataTable thead .sorting:after {
    content: "\f0dc";
    float: right;
    font-family: fontawesome;
    color: rgba(50,50,50,.5);
}

.btn-circle {
    width: 30px;
    height: 30px;
    padding: 6px 0;
    border-radius: 15px;
    text-align: center;
    font-size: 12px;
    line-height: 1.428571429;
}

.btn-circle.btn-lg {
    width: 50px;
    height: 50px;
    padding: 10px 16px;
    border-radius: 25px;
    font-size: 18px;
    line-height: 1.33;
}

.btn-circle.btn-xl {
    width: 70px;
    height: 70px;
    padding: 10px 16px;
    border-radius: 35px;
    font-size: 24px;
    line-height: 1.33;
}

.show-grid [class^=col-] {
    padding-top: 10px;
    padding-bottom: 10px;
    border: 1px solid #ddd;
    background-color: #eee!important;
}

.show-grid {
    margin: 15px 0;
}

.huge {
    font-size: 40px;
}


.panel-green {
    border-color: #5cb85c;
}

.panel-green .panel-heading {
    border-color: #5cb85c;
    color: #fff;
    background-color: #5cb85c;
}

.panel-green a {
    color: #5cb85c;
}

.panel-green a:hover {
    color: #3d8b3d;
}

.panel-red {
    border-color: #d9534f;
}

.navbar {

    background-color:#222D3D;
}

.panel-red .panel-heading {
    border-color: #d9534f;
    color: #fff;
    background-color: #d9534f;
}

.panel-red a {
    color: #d9534f;
}

.panel-red a:hover {
    color: #b52b27;
}

.panel-yellow {
    border-color: #f0ad4e;
}

.panel-yellow .panel-heading {
    border-color: #f0ad4e;
    color: #fff;
    background-color: #f0ad4e;
}

.headline-panel {
-webkit-box-shadow: 0 3px 3px 0 rgba(179,179,179,1);
-moz-box-shadow: 0 3px 3px 0 rgba(179,179,179,1);
box-shadow: 0 3px 3px 0 rgba(179,179,179,1);

}

.panel-body {
-webkit-box-shadow: 0 3px 3px 0 rgba(199,199,199,0.4);
-moz-box-shadow: 0 3px 3px 0 rgba(199,199,199,0.4);
box-shadow: 0 3px 3px 0 rgba(199,199,199,0.4);
}

.panel-yellow a {
    color: #f0ad4e;
}

.panel-yellow a:hover {
    color: #df8a13;
}