// this is the dataTables css file
// the files that make up this file are below: (in order)
// 1. colReorder.dataTables.min.css
// 2. dataTables.bootstrap.min.css
// 3. responsive.dataTables.min.css

//1

table.DTCR_clonedTable.dataTable {
  position: absolute !important;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 202;
}

div.DTCR_pointer {
  width: 1px;
  background-color: #0259C4;
  z-index: 201;
}

//2

table.dataTable {
  clear: both;
  max-width: none !important;
  border-collapse: separate !important;
  tr.active{
    font-weight: bold;
    >td{
      background-color: transparent;
    }
  }
  td, th {
    box-sizing: content-box;
  }
  td.dataTables_empty, th.dataTables_empty {
    text-align: center;
  }
  &.nowrap {
    th, td {
      white-space: nowrap;
    }
  }
}

div.dataTables_wrapper div {
  &.dataTables_length {
    label {
      font-weight: normal;
      text-align: left;
      white-space: nowrap;
    }
    select {
      width: 75px;
      display: inline-block;
    }
  }
  &.dataTables_filter {
    text-align: right;
    label {
      font-weight: normal;
      white-space: nowrap;
      text-align: left;
    }
    input {
      margin-left: 0.5em;
      display: inline-block;
      width: auto;
    }
  }
  &.dataTables_info {
    padding-top: 8px;
    white-space: nowrap;
  }
  &.dataTables_paginate {
    margin: 0;
    white-space: nowrap;
    text-align: right;
    ul.pagination {
      margin: 2px 0;
      white-space: nowrap;
    }
  }
  &.dataTables_processing {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 200px;
    margin-left: -100px;
    margin-top: -26px;
    text-align: center;
    padding: 1em 0;
  }
}

table.dataTable thead {
  > tr > {

    th {
      &.sorting_asc, &.sorting_desc, &.sorting {
        cursor: pointer!important;
        text-align: right;
      }
    }
    td {
      &.sorting_asc, &.sorting_desc, &.sorting {
        padding-right: 30px;
        cursor: pointer!important;
      }
    }
    th:active, td:active {
      outline: none;
    }
  }
  .sorting, .sorting_asc, .sorting_desc, .sorting_asc_disabled, .sorting_desc_disabled {
    cursor: pointer;
    position: relative;
  }

  .sorting_asc::before {
    content: "\f0de";
    font-family: fontawesome;
    padding-right: 0.5em;
  }
  .sorting_desc::before {
    content: "\f0dd";
    font-family: fontawesome;
    padding-right: 0.5em;
  }
  .sorting::before {
    opacity: 0.2;
    content: "\f0dc";
    font-family: fontawesome;
    padding-right: 0.5em;
  }

  .sorting::after {
    display: none;
  }
  .sorting_asc::after {
    display: none;
  }
  .sorting_desc::after {
    display: none;
  }
}

div {
  &.dataTables_scrollHead table.dataTable {
    margin-bottom: 0 !important;
  }
  &.dataTables_scrollBody table {
    border-top: none;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    thead {
      .sorting:after, .sorting_asc:after, .sorting_desc:after {
        display: none;
      }
    }
    tbody tr:first-child {
      th, td {
        border-top: none;
      }
    }
  }
  &.dataTables_scrollFoot table {
    margin-top: 0 !important;
    border-top: none;
  }
}

@media screen and (max-width: 767px) {
  div.dataTables_wrapper div {
    &.dataTables_length, &.dataTables_filter, &.dataTables_info, &.dataTables_paginate {
      text-align: center;
    }
  }
}

table {
  &.dataTable.table-condensed {
    > thead > tr > th {
      padding-right: 20px;
    }
    .sorting:after, .sorting_asc:after, .sorting_desc:after {
      top: 6px;
      right: 6px;
    }
  }
  &.table-bordered.dataTable {
    th, td {
      border-left-width: 0;
    }
    th:last-child, td:last-child {
      border-right-width: 0;
    }
    tbody {

      th, td {
        border-bottom-width: 0;
      }
    }
  }
}

div {
  &.dataTables_scrollHead table.table-bordered {
    border-bottom-width: 0;
  }
  &.table-responsive > div.dataTables_wrapper > div.row {
    margin: 0;
    > div[class^="col-"] {
      &:first-child {
        padding-left: 0;
      }
      &:last-child {
        padding-right: 0;
      }
    }
  }
}

//3

table.dataTable {
  &.dtr-inline.collapsed {
    > tbody > tr {
      > {
        td.child, th.child {
          cursor: default !important;
        }
        td {
          &.dataTables_empty {
            cursor: default !important;
          }
          &.child:before {
            display: none !important;
          }
        }
        th.child:before {
          display: none !important;
        }
        td {
          &.dataTables_empty:before {
            display: none !important;
          }
          &:first-child {
            position: relative;
            padding-left: 30px;
            cursor: pointer;
          }
        }
        th:first-child {
          position: relative;
          padding-left: 30px;
          cursor: pointer;
        }
        td:first-child:before, th:first-child:before {
          top: 9px;
          left: 4px;
          height: 14px;
          width: 14px;
          display: block;
          position: absolute;
          color: white;
          border: 2px solid white;
          border-radius: 14px;
          box-shadow: 0 0 3px #444;
          box-sizing: content-box;
          text-align: center;
          font-family: 'Courier New', Courier, monospace;
          line-height: 14px;
          content: '+';
          background-color: #31b131;
        }
      }
      &.parent > {
        td:first-child:before, th:first-child:before {
          content: '-';
          background-color: #d33333;
        }
      }
      &.child td:before {
        display: none;
      }
    }
    &.compact > tbody > tr > {
      td:first-child, th:first-child {
        padding-left: 27px;
      }
      td:first-child:before, th:first-child:before {
        top: 5px;
        left: 4px;
        height: 14px;
        width: 14px;
        border-radius: 14px;
        line-height: 14px;
        text-indent: 3px;
      }
    }
  }
  &.dtr-column > tbody > tr {
    > {
      td.control, th.control {
        position: relative;
        cursor: pointer;
      }
      td.control:before, th.control:before {
        top: 50%;
        left: 50%;
        height: 16px;
        width: 16px;
        margin-top: -10px;
        margin-left: -10px;
        display: block;
        position: absolute;
        color: white;
        border: 2px solid white;
        border-radius: 14px;
        box-shadow: 0 0 3px #444;
        box-sizing: content-box;
        text-align: center;
        font-family: 'Courier New', Courier, monospace;
        line-height: 14px;
        content: '+';
        background-color: #31b131;
      }
    }
    &.parent {
      td.control:before, th.control:before {
        content: '-';
        background-color: #d33333;
      }
    }
  }
  > tbody > tr.child {
    padding: 0.5em 1em;
    &:hover {
      background: transparent !important;
    }
    ul {
      display: inline-block;
      list-style-type: none;
      margin: 0;
      padding: 0;
      li {
        border-bottom: 1px solid #efefef;
        padding: 0.5em 0;
        &:first-child {
          padding-top: 0;
        }
        &:last-child {
          border-bottom: none;
        }
      }
    }
    span.dtr-title {
      display: inline-block;
      min-width: 75px;
      font-weight: bold;
    }
  }
}

div.dtr-modal {
  position: fixed;
  box-sizing: border-box;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 100;
  padding: 10em 1em;
  div {
    &.dtr-modal-display {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      width: 50%;
      height: 50%;
      overflow: auto;
      margin: auto;
      z-index: 102;
      overflow: auto;
      background-color: #f5f5f7;
      border: 1px solid black;
      border-radius: 0.5em;
      box-shadow: 0 12px 30px rgba(0, 0, 0, 0.6);
    }
    &.dtr-modal-content {
      position: relative;
      padding: 1em;
    }
    &.dtr-modal-close {
      position: absolute;
      top: 6px;
      right: 6px;
      width: 22px;
      height: 22px;
      border: 1px solid #eaeaea;
      background-color: #f9f9f9;
      text-align: center;
      border-radius: 3px;
      cursor: pointer;
      z-index: 12;
      &:hover {
        background-color: #eaeaea;
      }
    }
    &.dtr-modal-background {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 101;
      background: rgba(0, 0, 0, 0.6);
    }
  }
}

@media screen and (max-width: 767px) {
  div.dtr-modal div.dtr-modal-display {
    width: 95%;
  }
}