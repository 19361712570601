$font_0: 'Roboto', sans-serif;
$font_1: 'Open Sans';
$font_2: 'Source Sans Pro';




$font_weight_light:200;
$font_weight_reg:400;
$font_weight_dark:700;



$font-family-primary: 'Open Sans';
$font-family-secondary: 'Roboto';



$type-micro: 0.8rem;
$type-smallest: 1rem;
$type-smaller: 1.5rem;
$type-small: 2rem;
$type-base: 2.5rem;
$type-large: 3rem;
$type-larger: 3.5rem;
$type-largest: 4rem;
a{
  color: $light-blue;
  &:hover, &:focus{
    color: $light-blue-hover;
    text-decoration: none;
  }
}
html{
  font-size: ($tBase + px);
  font-family: $font_0;
  -ms-overflow-style: scrollbar;
}
body{
  font-size: 1rem;
  color: $text-default-fallback;
  color: $text-default;
}
h1, .title-1{
  font-size: getrem(74);
}
h2, .title-2{
  font-size: getrem(45);
}
h3, .title-3{
  font-size: 1rem;
  display: block;
  margin-left: auto;
  margin-right: auto;
  overflow-wrap: normal;
}
big, .large{
  font-size: getrem(26);
}
.text-regular{
  font-size: 1rem;
}
.text-large{
  font-size: 1.3333rem;
}
.text-xlarge{
  font-size: 6rem;
  color: #ccc;
}
.text-semilarge{
  font-size: 4rem;
  color: #ccc;
}
small, .text-small{
  font-size: getrem(16);
}
.text-tiny {
  font-size: getrem(14);
}
.title {
  margin-bottom: 0.6667rem;
  margin-top: 0.6667rem;
}