#request-contact-client-modal, #suggestion-modal{
    .modal-dialog {min-width: 30%;}
    .modal-header { border-bottom: none;}
    .text-large {color:#20A1DB;}
    .suggestion-buttons { 
        width: 100%; 
        height: 40px; 
        line-height: 40px; 
        text-align: right;
        button {
            float:right;
            &.disabled{
                background: transparent!important; 
                color: #ccc!important;      
                cursor: default!important;      
            }
        }

    }
    #suggestion-form textarea {
        resize: vertical; 
        width: 100%;
        max-height: 200px;
        min-height: 40px;
        border: none;
        border-bottom: 1px rgb(75, 72, 72) solid;
    }
    #report-form textarea {
        resize: vertical; 
        width: 100%;
        max-height: 200px;
        min-height: 40px;
        border: none;
        border-bottom: 1px rgb(75, 72, 72) solid;
    }
}

#thanks-modal{
    .modal-dialog {min-width: 30%;}
    .modal-header { border-bottom: none;}
    .text-large {color:#20A1DB;}
    .thanks-buttons { 
        width: 100%; 
        height: 40px; 
        line-height: 40px; 
        text-align: right;
        button {
            float:right;
            &.disabled{
                background: transparent!important; 
                color: #ccc!important;      
                cursor: default!important;      
            }
        }

    }
    #thanks-form textarea {
        resize: vertical; 
        width: 100%;
        max-height: 200px;
        min-height: 40px;
        border: none;
        border-bottom: 1px rgb(75, 72, 72) solid;
    }

}

