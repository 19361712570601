.details-panel{
  float: left;
  padding: getrem(24) getrem(24) 0;
  min-height: calc(100vh - 7.166666666666667rem);
  margin-top: 3.5rem;
}
.details-left-panel{
  background-color: white;
  margin-left: getrem(-24);
  margin-top: 3.5rem;
  z-index: 1;
}
.details-right-panel{
  width: calc(100% - getrem(360));
}
.details-icon-title {
  text-transform: uppercase;
}
.details-block{
//  white-space: nowrap;
  border-bottom: solid 0.01625rem $outline-grey;
  line-height: 2rem;
  font-size: 0.8889rem;
  .details-field{
    text-transform: uppercase;
  }
  .details-value{
    text-transform: uppercase;
  }
  +.details-block{
    padding-top: 1rem;
  }
  >.details-header{
    text-transform: uppercase;
    font-weight: bold;
    font-size: 1rem;
  }
  >.details-body{
    padding: 1rem 0;
    >.details-row-header{
      font-weight: bold;
      width:100%;
      height: getrem(42);
      >.details-field{
        float:left;
      }
      >.details-value{
        float: right;
      }
    }
    >.details-row{
      width:100%;
      height: getrem(42);
      >.details-field{
        float: left;
      }
      >.details-value{
        float: right;
        >.details-two-row-value{
          padding-left: 10rem;
        }
      }
    }
    >.details-two-row{
      height: getrem(80);
    }
  }
}
.details-icon-container{
  display: flex;
  justify-content: space-around;
  .details-icon{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 1rem;
    .details-icon-image{
      height: 5rem;
    }
    .details-icon-text{
      text-transform: uppercase;
      font-weight: bold;
      max-width: 12.5rem;
      overflow-wrap: normal;
      min-height: 90px;
      text-align: center;
      line-height: 1.333rem;
      white-space: normal;
    }
  }
}

.package-details-right>.details-block>.details-body>.details-row{
  >.details-field{
    width: 10rem;
  }
  >.details-value{
    float: none;
  }
}

.drilldown{
  .disappears-on-drilldown, .pin-button, .sidebar-toggle, .toggle-options, #chart-detail-description-menu-item{
    display: none;
  }
  .disappears-on-drilldown {
    width: 0;
  }
}
.inputservice {
    width: 4rem;
        text-indent: 5px;
  }