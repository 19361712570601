.search-modal-content {

	margin-left:auto;
	margin-right:auto;
	text-align:center;
	padding:2rem;
	height:1400px;
	margin-top:10vh;
}

.account-number-value, .carrier-value, .date-value,
.numberOfPackages-value, .spend-per-package-value, .savings-per-package-value,
.invoice-number-value, .service-type-value, .zone-value, .weight-value,
.weight-per-package-value, .total-weight-value {
	font-weight:900;
	font-size:2rem;
}

@media (min-width:992px){

	.account-number-value, .carrier-value, .date-value,
.numberOfPackages-value, .spend-per-package-value, .savings-per-package-value,
.invoice-number-value, .service-type-value, .zone-value, .weight-value,
.weight-per-package-value, .total-weight-value {
	font-weight:900;
	font-size:1.3rem;
}

.search-modal-content {

	font-size:1.2rem;
}



}

@media (min-width:1400px){

	.account-number-value, .carrier-value, .date-value,
.numberOfPackages-value, .spend-per-package-value, .savings-per-package-value,
.invoice-number-value, .service-type-value, .zone-value, .weight-value,
.weight-per-package-value, .total-weight-value {
	font-weight:900;
	font-size:2rem;
}

.search-modal-content {
	font-size:1.8rem;
}


}

.delivery-confirmation {
	padding-top:25px;
}

.delivery-value {
	font-weight:900;
	font-size:2.2rem;
}

.left-side-invoice-content {

}

.search-submit-btn:hover {
	background-color: $midnight;
	color:white;
}

.package-detail-table-title {
	font-size:2rem;
}

.total-spend-sub-left {
	text-align:left;
	margin-top:15px;

}
.total-spend-sub-right {
	text-align:right;
	margin-top:15px;

}

.total-spend-sub-left span {

	font-size:1.4rem;
	padding-left:70px;
}

.total-spend-sub-right span {

	font-size:1.4rem;
	padding-right:70px;
}

.value-title {
	font-size:1.75rem;
	line-height:0;
	font-weight:900;
}

.total-spend-value, .discount-value, .original-spend-value {
	font-size:1.75rem;
	line-height:0;
	font-weight:900;

}

.search-box {


	margin-top:15vh;
	height:400px;
	border:1px solid grey;
	border-radius:15px;
	background-color:$midnight;
	box-shadow: 0 3px 4px 0 rgba(166,166,166,0.5);
}

.search-box-content {

	padding:7rem 10px 10px;
	text-align:center;
}

.search-box-content h4 {
	color:#DEDEDE;
	margin-bottom:20px;
}

@media (min-width:992px){

	.search-modal-content {
	height:750px;
	}

}




.invoice-id-text-box, .package-id-text-box {


	border:1px solid black;
	border-radius: 15px;

	padding-top:10px;
	padding-bottom:10px;
	width:50%;
	margin-left:auto;
	margin-right:auto;
	margin-bottom:30px;
}

.search-modal-btn {
	opacity:0.25;height:50px;border:1px solid black;border-radius:15px;padding-top:14px;
}

.start-new-search-btn:hover {
	opacity:1;
}

.search-modal-btn:hover span.dl-pdf {
	display:none;

}

.download-pdf-btn:hover:after {

	content:'Coming Soon!';
}

.invoice-id-text, .package-id-text {
	font-size:1.4rem;
	font-weight: 900;
	line-height:0.75;

}

@media (min-width:800px){


	.invoice-id-text, .package-id-text {

		font-size:1.85rem;
	}

}



@media (min-width:1300px){


	.invoice-id-text, .package-id-text {

		font-size:2.8rem;
	}

}




.left-side-invoice-box {


 


}


.right-side-invoice-box {


}


.right-side-invoice-content {
	border:1px solid grey;
	border-radius:15px;
	padding:5px;
	 height:575px;
}


.left-side-invoice-content {
	border:1px solid grey;
	border-radius:15px;
	padding:5px;
	 height:275px;

	 margin-bottom: 25px;

}

.chart-result-icon {
  color: #173F6C;
}

.package-result-icon {
  color: #998465;
}

.invoice-result-icon {
  color: #66AF10;
}

.highlight-when-clicked {
	cursor: pointer !important;
}

.highlight-when-clicked:active {
	background-color: $light-blue;
}

#result-table {
	margin-bottom: 0;
}

.options-column {
	text-align: center;
	.dropdown.open {
		.material-icons {
			cursor: pointer;
			background: $dark-blue;
		}
		span {
			vertical-align: text-top;
			line-height: 1.7;
		}
		.disabled{
			color: $medium-gray;
			pointer-events: none;
		}
	}
	.material-icons {
		cursor: pointer;
		&:active, &:focus{
			background: $dark-blue;
		}
	}
}

.dropdown {
	.always-visible-block {
		display: block;
	}
	.dropdown-menu {
		.disabled {
			color: $medium-gray;
			pointer-events: none;
			.dropdown-item {
				color: $medium-gray;
				pointer-events: none;
			}
		}
	}
	.disabled{
		color: $medium-gray;
		pointer-events: none;
	}
}

.dt-checkboxes-cell {
	padding: 0 !important;
	.dt-checkboxes {
		width: 15px;
	}
}
#search-selected-rows-export-csv {
border-top: 1px solid #dfdfdf;
}
// uncoment , when you show Copy To Clipboard
//#search-selected-rows-export-pdf{
//border-bottom: 1px solid #dfdfdf;
//}

.tooltip-search {
	position: relative;
	display: inline-block;
  }
  .tooltip-search .tooltiptext {
	visibility: hidden;
	width: 120px;
	background-color: #333333;
	color: #fff;
	text-align: center;
	border-radius: 6px;
	padding: 5px 0;
	/* Position the tooltip */
	position: absolute;
	z-index: 1;
	width: 100%;
  }
  .tooltip-search:hover .tooltiptext {
	visibility: visible;
	visibility: visible;
    position: absolute;
    left: -166px;
    width: 100%;
    white-space: normal;
    padding: 5px;
    top: -34px;
    background: #959292;
  }