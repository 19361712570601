.off-canvas-sidebar-container{
  height: calc(100vh - 3rem);
  position: fixed;
  background: $bg-grey;
  right: 0;
  width: auto;
  top: 3rem;
  border: solid 0.0625rem $outline-grey;
  padding: 1.25em 0;
  overflow-y: auto;
  transition: all 0.5s;
  z-index: 2;
  &:not(.active){
    transform: translateX(100%);
  }
  .close-button{
    margin-right: getrem(24);
  }
  .section{
    padding: 0.5rem 1.5rem;
    +.section{
      border-top: 0.0625rem solid $outline-grey;
    }
    .section-header{
      color: $light-blue;
      font-weight: bold;
      padding: 0.5rem 0;
    }
    .sidebar-item{
      color: $text-default;
      padding: 0.5rem;
      width: 15rem;
      display: flex;
      align-items: center;
    }
     .sidebar-item:hover{
      color: $light-blue;
    }
    .material-icons.check{
      color: $light-blue;
      margin-right: 2rem;
      visibility: hidden;
    }
    .active>.material-icons.check{
      visibility: visible;
    }
    #list-search-bar{
      border: 0;
      outline: 0;
      border-bottom: 1px solid lightgrey;
      width:100%;
      margin-bottom: 20px;
      background-color: rgb(250, 250, 250) 
    }
    #list-search-bar:focus{
      box-shadow: none;
    }
    .filter-buttons{
      button {
         width:46%;
      }
      #resetButtonSearch {
        margin-right: 5%;
      }
    }
  }
  .button-container{
    width: 100%;
    text-align: right;
  }

  //NEW SELECT CSS
  /* select starting stylings ------------------------------*/
  .select {
    font-family:
      'Roboto','Helvetica','Arial',sans-serif;
    position: relative;
    width: 350px;
  }

  .select-text {
    position: relative;
    font-family: inherit;
    background-color: transparent;
    width: 350px;
    padding: 10px 10px 10px 0;
    font-size: 18px;
    border-radius: 0;
    border: none;
    border-bottom: 1px solid rgba(0,0,0, 0.12);
  }

  /* Remove focus */
  .select-text:focus {
    outline: none;
    border-bottom: 1px solid rgba(0,0,0, 0);
  }

    /* Use custom arrow */
  .select .select-text {
    appearance: none;
    -webkit-appearance:none
  }

  .select:after {
    position: absolute;
    top: 18px;
    right: 10px;
    /* Styling the down arrow */
    width: 0;
    height: 0;
    padding: 0;
    content: '';
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 6px solid rgba(0, 0, 0, 0.12);
    pointer-events: none;
  }


  /* LABEL ======================================= */
  .select-label {
    color: rgba(0,0,0, 0.26);
    font-size: 18px;
    font-weight: normal;
    position: absolute;
    pointer-events: none;
    left: 0;
    top: 10px;
    transition: 0.2s ease all;
  }

  /* active state */
  .select-text:focus ~ .select-label, .select-text:valid ~ .select-label {
    color: #2F80ED;
    top: -20px;
    transition: 0.2s ease all;
    font-size: 14px;
  }

  /* BOTTOM BARS ================================= */
  .select-bar {
    position: relative;
    display: block;
    width: 350px;
  }

  .select-bar:before, .select-bar:after {
    content: '';
    height: 2px;
    width: 0;
    bottom: 1px;
    position: absolute;
    background: #2F80ED;
    transition: 0.2s ease all;
  }

  .select-bar:before {
    left: 50%;
  }

  .select-bar:after {
    right: 50%;
  }

  /* active state */
  .select-text:focus ~ .select-bar:before, .select-text:focus ~ .select-bar:after {
    width: 50%;
  }

  /* HIGHLIGHTER ================================== */
  .select-highlight {
    position: absolute;
    height: 60%;
    width: 100px;
    top: 25%;
    left: 0;
    pointer-events: none;
    opacity: 0.5;
  }
  //END NEW CSS
  //CSS CHECKBOXES INSIDE
  .selectBox {
    position: relative;
  }
  
  .selectBox select {
    width: 100%;
    font-weight: bold;
  }
  
  .overSelect {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }
  
  #checkboxes {
    display: none;
    border: 1px #dadada solid;
  }
  
  #checkboxes label {
    display: block;
  }
  
  #checkboxes label:hover {
    background-color: #1e90ff;
  }

  #checkboxesBussines {
    display: none;
    border: 1px #dadada solid;
  }
  
  #checkboxesBussines label {
    display: block;
  }
  
  #checkboxesBussines label:hover {
    background-color: #1e90ff;
  }
  
  //END CSS CHECKBOXES



}

