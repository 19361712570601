/*
//	*************
//	*************
//	include all partials to be compiled
//	*************
//	*************
*/
@import '/node_modules/bootstrap-slider/dist/css/bootstrap-slider.css';
@import "/src/css/jquery-ui.min.css";
@import "/src/css/metisMenu.css";
@import "/src/css/sb-admin-2.css";
a {
  color: #20a1db;
}
a:hover, a:focus {
  color: #1c8cbf;
  text-decoration: none;
}

html {
  font-size: 18px;
  font-family: "Roboto", sans-serif;
  -ms-overflow-style: scrollbar;
}

body {
  font-size: 1rem;
  color: #212121;
  color: rgba(0, 0, 0, 0.87);
}

h1, .title-1 {
  font-size: 4.1111111111rem;
}

h2, .title-2 {
  font-size: 2.5rem;
}

h3, .title-3 {
  font-size: 1rem;
  display: block;
  margin-left: auto;
  margin-right: auto;
  overflow-wrap: normal;
}

big, .large {
  font-size: 1.4444444444rem;
}

.text-regular {
  font-size: 1rem;
}

.text-large {
  font-size: 1.3333rem;
}

.text-xlarge {
  font-size: 6rem;
  color: #ccc;
}

.text-semilarge {
  font-size: 4rem;
  color: #ccc;
}

small, .text-small {
  font-size: 0.8888888889rem;
}

.text-tiny {
  font-size: 0.7777777778rem;
}

.title {
  margin-bottom: 0.6667rem;
  margin-top: 0.6667rem;
}

.flex {
  display: flex;
}

@media screen and (min-width: 1920px) {
  .flex-spacer {
    flex: 0.7;
  }
}
@media screen and (min-width: 1600px) and (max-width: 1919px) {
  .flex-spacer {
    flex: 0.6;
  }
}
@media screen and (min-width: 1366px) and (max-width: 1599px) {
  .flex-spacer {
    flex: 0.5;
  }
}
@media screen and (max-width: 1365px) {
  .flex-spacer {
    flex: 0.4;
  }
}
.flex-spacer.results-table, .flex-spacer.package-details {
  flex: 1;
}

.flex-center {
  align-items: center;
  justify-content: center;
  flex: 1;
  justify-content: flex-end;
}

.deleted-flex {
  flex: none !important;
}

.bg-gray {
  background-color: #eee;
}

.font-bold {
  font-weight: bold;
}

.color-light-blue {
  color: #20a1db;
}

.color-dark-blue {
  color: rgba(0, 0, 0, 0.99);
  width: auto;
  margin: auto;
  overflow: hidden;
  word-wrap: break-word;
}

.color-light-blue {
  color: black;
  width: auto;
  margin: auto;
  overflow: hidden;
  word-wrap: break-word;
}

.color-dark-blue-large {
  color: rgba(0, 0, 0, 0.99);
  width: 140%;
  font-size: 0.8vw;
  margin: auto;
  overflow: hidden;
  word-wrap: break-word;
}

.color-dark-blue2 {
  color: rgba(0, 0, 0, 0.99);
  width: 75%;
  margin: auto;
  overflow: hidden;
  word-wrap: break-word;
}

.color-dark-blue3 {
  color: rgba(0, 0, 0, 0.99);
  width: auto;
  margin: auto;
  white-space: nowrap;
  overflow: hidden;
  word-wrap: break-word;
  text-align: center;
}

.font-size-large {
  font-size: 1.4vw;
}

.color-dark-blue4 {
  color: rgba(0, 0, 0, 0.99);
  width: auto;
  margin: auto;
  white-space: nowrap;
  overflow: hidden;
  word-wrap: break-word;
  font-size: 1.2vw;
}

.needsPointer {
  cursor: pointer;
}

.color-light-blue2 {
  color: #20a1db;
  width: 75%;
  margin: auto;
  white-space: nowrap;
  overflow: hidden;
  word-wrap: break-word;
}

.color-light-blue3 {
  border-radius: 50px;
  background: #20a1db;
  color: white;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.text-wrapper-ad {
  color: rgba(0, 0, 0, 0.99);
}

.color-half-black {
  color: #7f7f7f;
  color: rgba(0, 0, 0, 0.5);
}

.w100 {
  width: 100%;
}

.can-be-pressed {
  cursor: pointer;
}

.btn.active, .btn:active {
  box-shadow: none;
}

.invisible {
  visibility: hidden;
}

.spacer {
  height: 24px;
  width: 24px;
}

@media print {
  .color-light-blue {
    color: #20a1db !important;
  }
  .color-dark-blue {
    color: rgba(0, 0, 0, 0.99) !important;
  }
}
.temp-hidden {
  visibility: hidden;
}

.temp-hidden2 {
  visibility: hidden;
  display: none;
}

.no-padding {
  padding: 0 !important;
}

@media screen and (min-width: 1920px) {
  .date-range .tabs-vertical {
    left: -350px !important;
  }
}
@media screen and (min-width: 1600px) and (max-width: 1919px) {
  .date-range .tabs-vertical {
    left: -380px !important;
  }
}
@media screen and (min-width: 1366px) and (max-width: 1599px) {
  .date-range .tabs-vertical {
    left: -400px !important;
  }
}
@media screen and (max-width: 1365px) {
  .date-range .tabs-vertical {
    left: -500px !important;
  }
}

.button {
  display: inline-flex;
  white-space: nowrap;
  height: 2rem;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  border: none;
  border-radius: 0;
  padding: 0 0.8888888889rem;
  line-height: 1;
}
.button.raised {
  color: white;
  background: #20a1db;
}
.button.raised:hover {
  background: #1c8cbf;
}
.button.raised2 {
  color: white;
  background: rgba(0, 0, 0, 0.99);
}
.button.raised2:hover {
  background: #112f51;
}
.button.flat {
  color: #20a1db;
  background: transparent;
  cursor: pointer;
  padding: 0 0.4444444444rem;
}
.button.flat:hover {
  background: #e4e7fa;
  background: rgba(32, 161, 219, 0.12);
}
.button.flat:active, .button.flat:focus {
  background: #e4e7fa;
  background: rgba(32, 161, 219, 0.12);
  outline: solid 1px #20a1db;
}
.button.ghost {
  color: #20a1db;
  background: #fff;
  cursor: pointer;
  padding: 0 0.4444444444rem;
  border: solid 2px #20a1db;
  height: 32px;
}
.button.ghost:hover {
  background: transparent;
  color: #fff;
  border: solid 2px #fff;
}
.button.ghost:active, .button.ghost:focus {
  background: transparent;
  color: #fff;
  border: solid 2px #fff;
}
.button.ghost.disabled {
  color: #d1d1d1 !important;
  background: transparent;
  border: solid 2px #d1d1d1;
}
.button.ghost.disabled:hover {
  color: #d1d1d1;
  background: transparent;
  border: solid 2px #d1d1d1;
}
.button.secondary {
  color: white;
  outline: solid 1px #20a1db;
  background: rgba(0, 0, 0, 0.99);
}
.button.secondary:hover {
  color: white;
  background: #112f51;
}
.button.disabled {
  color: white !important;
  background: #CCC;
}
.button.disabled#calculators-dropdown-menu {
  color: #ccc !important;
}
.button.disabled:hover {
  color: white;
  background: #CCC;
}
.button:focus {
  outline: solid 1px #20a1db;
}
.button.large {
  font-size: 1rem;
  height: 2.6666666667rem;
  padding: 0 1.1111111111rem;
}
.button.dashboardArrangeCharts {
  color: #CCC !important;
  background: #f5f5f5 !important;
  cursor: default !important;
  outline: none !important;
}

#dropdownMenu1 {
  display: inline-block;
}

.flexbox-button {
  cursor: pointer;
  flex-flow: row wrap;
  text-align: center;
  width: 15%;
  display: inline-flex !important;
  align-items: center;
  justify-content: space-around;
}

input:-webkit-autofill, textarea:-webkit-autofill, select:-webkit-autofill {
  background: white;
}

input::placeholder {
  color: #9e9e9e !important;
  color: rgba(0, 0, 0, 0.38) !important;
}

.form-control {
  height: 2rem;
}

.settings-field-value.invalid {
  border: solid 0.01625rem #d91a1a;
}
.settings-field-value.valid {
  border: solid 0.01625rem #66af10;
}

.form-group {
  margin-bottom: 0.8888888889rem;
}
.form-group .form-control.tokenfield {
  height: auto;
}
.form-group .form-control {
  border-radius: 0;
  box-shadow: none;
  color: #212121;
  color: rgba(0, 0, 0, 0.87);
  padding: 0 0.8888888889rem;
  font-size: 1.0555555556rem;
  height: 2rem;
}
.form-group .form-control.invalid {
  border: solid 0.01625rem #d91a1a;
}
.form-group .form-control.valid {
  border: solid 0.01625rem #66af10;
}
.form-group input.form-control {
  height: 2rem;
  font-size: 1.0555555556rem;
}

.dropup .button[aria-expanded=true] .caret {
  border-bottom: none;
  border-top: 4px dashed;
}

.dropdown .button[aria-expanded=true] .caret {
  border-top: none;
  border-bottom: 4px dashed;
}

.dropdown .btn.dropdown-toggle, .dropup .btn.dropdown-toggle {
  text-transform: capitalize;
}
.dropdown .btn.dropdown-toggle .caret, .dropup .btn.dropdown-toggle .caret {
  margin-left: 0.4444444444rem;
}
.dropdown .dropdown-menu, .dropup .dropdown-menu {
  margin: 0;
  min-width: 7rem;
  padding: 0.8888888889rem 0;
  z-index: 1;
  max-height: 21.3888888889rem;
}
.dropdown .dropdown-menu.scrollable, .dropup .dropdown-menu.scrollable {
  overflow-y: scroll;
}
.dropdown .dropdown-menu.dropdown-left, .dropup .dropdown-menu.dropdown-left {
  left: auto;
  right: 0;
}
.dropdown .dropdown-menu .dropdown-item, .dropup .dropdown-menu .dropdown-item {
  padding: 0.125rem 1.3333333333rem;
  display: flex;
  align-items: center;
  color: #212121;
  color: rgba(0, 0, 0, 0.87);
  height: 2rem;
  cursor: pointer;
}
.dropdown .dropdown-menu .dropdown-item .material-icons, .dropup .dropdown-menu .dropdown-item .material-icons {
  color: #20a1db;
  margin-right: 1rem;
  visibility: hidden;
}
.dropdown .dropdown-menu .dropdown-item.active .material-icons, .dropup .dropdown-menu .dropdown-item.active .material-icons {
  visibility: visible;
}

.dropdown-submenu .dropdown-menu {
  top: 0;
  left: 100%;
}
.dropdown-submenu .dropdown-menu.dropdown-submenu-left {
  left: 0;
  transform: translateX(-100%);
}

#company-dropdown-container .dropdown-menu {
  overflow: auto;
}

#test {
  width: 100%;
  display: inline-block;
}

.dropdown-menu .dt-button.dropdown-item:hover {
  background-color: #20a1db;
  color: #fff;
}

.switch {
  position: relative;
  display: inline-flex;
  width: 2rem;
  height: 1.3333333333rem;
  align-items: center;
}

.switch input {
  display: none;
}

.toggler {
  cursor: pointer;
  background-color: #CCC;
  transition: 0.4s;
  height: 0.75rem;
  width: 100%;
}

.toggler:before {
  position: absolute;
  content: "";
  height: 1rem;
  width: 1rem;
  left: 0;
  bottom: 4px;
  background-color: #F5F5F5;
  transition: 0.4s;
  box-shadow: 1px 1px 5px #848484;
}

input:checked + .toggler {
  background-color: #e8fedb;
  background-color: rgba(102, 175, 16, 0.15);
}
input:checked + .toggler:before {
  background-color: #66af10;
}

input:focus + .toggler {
  box-shadow: 0 0 1px black;
}

input:checked + .toggler:before {
  transform: translateX(100%);
}

.toggler.round {
  border-radius: 1rem;
}

.toggler.round:before {
  border-radius: 50%;
}

.slider-handle {
  background: #20a1db;
  box-shadow: none;
}

.slider-selection.tick-slider-selection {
  background: #20a1db;
  box-shadow: none;
}

.slider.slider-horizontal .slider-track {
  height: 6px;
  margin-top: -3px;
  background: transparent;
  box-shadow: none;
}

.slider-track-high {
  background: #CCC;
}

.slider-tick {
  display: none;
}

input:focus {
  outline: none;
  box-shadow: 0 0 5px #20a1db;
}

.dropdown-menu li a:not(.no-active):hover {
  background-color: #20a1db;
  color: #fff !important;
}

.dropdown-menu li.active {
  background-color: #20a1db;
  color: #fff !important;
}

#contact-us-modal textarea {
  resize: vertical;
  min-height: 60px;
  max-height: 150px;
}

.radio label {
  font-size: 14px;
}

#company-select-container .open > .dropdown-toggle.btn-default:focus, .open > .dropdown-toggle.btn-default:hover {
  color: black !important;
  border-color: #8c8c8c;
}

#carrier-select-container .open > .dropdown-toggle.btn-default:focus, .open > .dropdown-toggle.btn-default:hover {
  color: black !important;
  border-color: #8c8c8c;
}

.card {
  border: solid 0.01625rem #dfdfdf;
  padding: 0;
  width: 49%;
}
.card .card-header {
  padding: 0 1rem;
  background-color: #f5f5f5;
  color: rgba(0, 0, 0, 0.87);
  height: 3.3333333333rem;
  display: flex;
  align-items: center;
  font-size: 0.8888888889rem;
}
.card .card-body {
  color: #212121;
  color: rgba(0, 0, 0, 0.87);
  border-top: solid 0.01625rem #dfdfdf;
  padding: 1.3333333333rem;
  background-color: white;
}
.card .card-body-left {
  color: #212121;
  color: rgba(0, 0, 0, 0.87);
  border-top: solid 0.01625rem #dfdfdf;
  padding: 0;
  background-color: white;
}

.modal-open #confirmation-modal {
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10010;
}
.modal-open #confirmation-modal .modal-dialog {
  border-radius: 0;
  margin: 0;
}
.modal-open #confirmation-modal .modal-dialog .modal-content {
  border-radius: 0;
}
.modal-open #confirmation-modal .modal-dialog .modal-content .modal-header {
  color: #20a1db;
  padding: 1.3333333333rem 1.3333333333rem 0.8888888889rem;
  border: none;
}
.modal-open #confirmation-modal .modal-dialog .modal-content .modal-body {
  max-height: 60vh;
  padding: 0 1.3333333333rem;
  overflow-y: visible;
}
.modal-open #confirmation-modal .modal-dialog .modal-content .modal-body .modal-subheader {
  color: rgba(0, 0, 0, 0.99);
  padding: 1.3333333333rem 1.3333333333rem 0.8888888889rem;
  font-weight: bold;
  border: none;
}
.modal-open #confirmation-modal .modal-dialog .modal-content .modal-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border: none;
  padding: 0 1.3333333333rem 1.3333333333rem 1.3333333333rem;
}

.popover {
  background-color: #7f7f7f;
  background-color: rgba(0, 0, 0, 0.5);
  color: #ffffff;
  max-width: 19rem;
}

.popover.right > .arrow:after {
  border-right-color: #848484;
}

.popover.left > .arrow:after {
  border-left-color: #848484;
}

.popover.top > .arrow:after {
  border-top-color: #848484;
}

.popover.bottom > .arrow:after {
  border-bottom-color: #848484;
}

.popover-content {
  padding: 1.1428571429rem;
}

body.modal-open div.modal-backdrop {
  z-index: 1049;
}

#toast-notification {
  height: 2.5rem;
  position: fixed;
  bottom: 2.5rem;
  right: 0;
  display: none;
  background-color: #6D6D6D;
  padding: 1rem;
  align-items: center;
  width: calc(100% - 13rem);
  margin-left: -1rem;
  color: white;
}
#toast-notification.show {
  display: flex !important;
}
#toast-notification .message {
  width: 100%;
}
#toast-notification .button {
  color: white;
  font-weight: bold;
}

.chart-details-wrapper {
  overflow: hidden;
}
.chart-details-wrapper .button:not(:last-child) {
  margin-right: 0.4444444444rem;
}
.chart-details-wrapper .pin-button {
  color: #7f7f7f;
  color: #ccc;
  margin-right: 0.8888888889rem;
  font-size: 1rem;
}

.chart-download-btn {
  cursor: pointer;
}

.charts-row {
  margin-top: 4rem;
}

#home-charts-holder .charts-list-row {
  margin-top: 0.5rem;
}

#locker {
  height: 80%;
  width: 100%;
}

.charts-list-row {
  margin-top: 3.4rem;
}

.chart-details-container {
  margin: 3.5rem -1.4rem 47px;
}
.chart-details-container .toggle-details {
  display: flex;
  justify-content: center;
  padding-top: 20px;
  background-color: #fff;
}
.chart-details-container .toggle-details button {
  background-color: #20a1db;
  color: #ffffff;
  border: none;
  width: 105px;
  font-size: 1rem;
}
.chart-details-container .toggle-details button:hover {
  background-color: #1c8cbf;
}
.chart-details-container .toggle-details button.--outline {
  background-color: #ffffff;
  color: #20a1db;
  border: 2px solid #20a1db;
}
.chart-details-container .toggle-details button.--outline:hover {
  color: #1c8cbf;
  border: 2px solid #1c8cbf;
}
.chart-details-container .panel {
  margin: 0;
}
.chart-details-container .panel > .panel-body {
  height: calc(100vh - 237px);
}
.chart-details-container .panel > .panel-body.with-toggle-buttons {
  height: calc(100vh - 9.87619rem - 62px);
}
.chart-details-container .panel > .panel-body .chart-container {
  height: calc(100vh - 300px);
}
.chart-details-container .panel > .panel-body .chart-container .highcharts-container {
  overflow: hidden;
}
.chart-details-container .panel > .panel-body .chart-container .highcharts-container svg text tspan {
  font-size: 13px !important;
}
.chart-details-container .panel > .panel-body .chart-container .highcharts-container svg text tspan.highcharts-text-outline {
  fill: transparent;
  stroke: transparent;
  stroke-width: 0px;
  stroke-linejoin: round;
}
.chart-details-container .panel > .panel-body .chart-container .highcharts-container svg text tspan:nth-child(2) {
  font: 9pt Open Sans, Verdana, sans-serif;
  font-weight: bold;
}
.chart-details-container .panel > .panel-body .chart-container .highcharts-container svg .highcharts-xaxis-labels text tspan:nth-child(2) {
  font: 13px Lucida Grande, Lucida Sans Unicode, Arial, Helvetica, sans-serif;
  font-weight: 400;
}

.chart-container .panel > .panel-body .chart-container .highcharts-container {
  overflow: hidden;
}
.chart-container .panel > .panel-body .chart-container .highcharts-container svg text tspan.highcharts-text-outline {
  fill: transparent;
  stroke: transparent;
  stroke-width: 0px;
  stroke-linejoin: round;
}
.chart-container .panel > .panel-body .chart-container .highcharts-container svg text tspan:nth-child(2) {
  font: 11px Open Sans, Verdana, sans-serif;
  font-weight: 700;
}

.chart-container a:focus, .chart-details-container a:focus, .chart-details-header-container a:focus {
  color: inherit;
}
.chart-container a:focus:hover, .chart-details-container a:focus:hover, .chart-details-header-container a:focus:hover {
  color: #1c8cbf;
}
.chart-container .menu-button, .chart-details-container .menu-button, .chart-details-header-container .menu-button {
  color: white;
}
.chart-container .btn.menu-button:hover, .chart-details-container .btn.menu-button:hover, .chart-details-header-container .btn.menu-button:hover {
  background-color: #173f6c;
}
.chart-container .button, .chart-details-container .button, .chart-details-header-container .button {
  justify-content: flex-end;
}
.chart-container .panel-heading, .chart-details-container .panel-heading, .chart-details-header-container .panel-heading {
  padding: 0;
}
.chart-container .panel-heading .dropdown-item, .chart-details-container .panel-heading .dropdown-item, .chart-details-header-container .panel-heading .dropdown-item {
  position: relative;
}
.chart-container .panel-heading .button, .chart-details-container .panel-heading .button, .chart-details-header-container .panel-heading .button {
  display: flex;
  color: #20a1db;
}
.chart-container .panel-heading .dropright, .chart-details-container .panel-heading .dropright, .chart-details-header-container .panel-heading .dropright {
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  right: 1.3333333333rem;
  border: solid transparent 4px;
  border-left: solid rgba(0, 0, 0, 0.87) 4px;
}
.chart-container .panel-heading .dropdown .btn.dropdown-toggle, .chart-container .panel-heading .dropup .btn.dropdown-toggle, .chart-details-container .panel-heading .dropdown .btn.dropdown-toggle, .chart-details-container .panel-heading .dropup .btn.dropdown-toggle, .chart-details-header-container .panel-heading .dropdown .btn.dropdown-toggle, .chart-details-header-container .panel-heading .dropup .btn.dropdown-toggle {
  text-transform: none;
  font-size: 1.1666666667rem;
  padding: 0;
}
.chart-container .panel-heading .dropdown.open .menu-button, .chart-details-container .panel-heading .dropdown.open .menu-button, .chart-details-header-container .panel-heading .dropdown.open .menu-button {
  background: rgba(32, 161, 219, 0.12);
}
.chart-container .panel-heading .dropdown.open .btn.menu-button, .chart-details-container .panel-heading .dropdown.open .btn.menu-button, .chart-details-header-container .panel-heading .dropdown.open .btn.menu-button {
  background-color: #173f6c;
}
.chart-container .panel-body, .chart-details-container .panel-body, .chart-details-header-container .panel-body {
  padding: 1.3333333333rem;
  padding-bottom: 0;
  height: 24.8888888889rem;
}
.chart-container .below-chart-caption, .chart-details-container .below-chart-caption, .chart-details-header-container .below-chart-caption {
  height: 1.3333333333rem;
  text-align: center;
  font-size: x-small;
}
.chart-container .below-chart-caption-detail, .chart-details-container .below-chart-caption-detail, .chart-details-header-container .below-chart-caption-detail {
  margin-top: -1.3333333333rem;
  z-index: 1;
  position: relative;
}
.chart-container .panel-footer, .chart-details-container .panel-footer, .chart-details-header-container .panel-footer {
  display: flex;
  align-items: center;
  height: 2.6666666667rem;
  padding: 0 0.4444444444rem;
}
.chart-container .panel-footer > .dropdown, .chart-details-container .panel-footer > .dropdown, .chart-details-header-container .panel-footer > .dropdown {
  margin-right: 0.4444444444rem;
}

.chart-details-container .panel-footer {
  position: fixed;
  width: 88.125%;
  bottom: 0;
}

.chart-title-container {
  padding-left: 1.3333333333rem;
  background-color: #20a1db;
  color: white;
  text-transform: capitalize;
  height: 2.6666666667rem;
  display: flex;
  align-items: center;
}
.chart-title-container .material-icons {
  margin-right: 0.4444444444rem;
}

.chart-title-container:after {
  content: "";
  height: 100%;
  display: inline-block;
  vertical-align: middle;
  overflow: hidden;
}

.chart-title-container span {
  margin-right: 0.4444444444rem;
  display: inline-block;
  display: -webkit-inline-box;
  max-height: 100%;
  vertical-align: middle;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.chart-title {
  flex: 1;
}

.pin-button {
  color: #1891C8;
  width: 1.3333333333rem;
}
.pin-button.active {
  color: #FFCE00;
}

.chart-filter-container {
  min-width: 5.6111111111rem;
  z-index: 1;
}
.chart-filter-container .button.disabled {
  color: #f9f9f9 !important;
  background-color: #ccc;
  cursor: not-allowed;
}
.chart-filter-container .button.disabled.flat:focus {
  outline: none;
}

.toggle-options > * {
  color: #212121;
  color: rgba(0, 0, 0, 0.87);
}
.toggle-options .chart-container {
  height: 400px;
}
.toggle-options small {
  min-width: 3.3333333333rem;
  text-align: center;
}
.toggle-options .disabled {
  color: #CCC !important;
  cursor: not-allowed;
}
.toggle-options .invisible {
  display: none;
}

.dropdown-submenu {
  width: 150px;
  position: relative;
}

.dropdown-submenu > .dropdown-menu {
  top: 0;
  left: 100%;
  display: none;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s linear 0s, opacity 0s linear;
  transition-delay: 0.3s;
}

.dropdown-submenu:hover > .dropdown-menu {
  visibility: visible;
  opacity: 1;
  transition-delay: 100s;
}

.dropdown-submenu > a:after {
  display: block;
  content: " ";
  float: right;
  width: 0;
  height: 0;
  margin-top: 5px;
  margin-right: -10px;
}

.force-show-submenu-element {
  display: block;
}

.no-border-top {
  border-top: none;
}

text.highcharts-subtitle {
  font-size: 0.7778rem !important;
}

.iconcenter {
  width: 100%;
  text-align: center;
  margin-top: 110px;
}

.iconcentertop {
  width: 100%;
  text-align: center;
  margin-top: 1px;
}

.flex-space-between {
  display: flex;
  justify-content: space-between;
}

.filters-container {
  display: flex;
}

.chart-container-column, .single-chart-container {
  min-width: 435px;
}

.demargin {
  cursor: pointer;
  width: 100%;
  height: 544px;
}

.addpix {
  margin: 2px 0 1px 0;
}

.caret.export-menu.disabled {
  visibility: hidden;
}

.chart-loading-time-caption {
  color: black;
  font-size: 14px;
  margin-right: 25px;
}

.chart-title-container .chart-title-loading-time-caption-holder {
  text-transform: none;
  margin-bottom: -1px;
}
.chart-title-container span .chart-title-loading-time-caption {
  margin-right: 1px;
  margin-bottom: 1px;
}

.full-page-loading-time-caption {
  color: red;
  font-size: 22px;
  margin-top: 10px;
}

@media (max-width: 1650px) {
  .month-year {
    position: absolute;
    padding-bottom: 100px;
    left: 25px;
  }
}
@media all and (max-width: 1440px) and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .month-year {
    margin-top: -65px;
  }
}
#breadcrumbs-container {
  display: flex;
  align-items: center;
  min-width: 30rem;
  text-overflow: ellipsis;
}
#breadcrumbs-container > * {
  font-size: 1rem;
  line-height: 1;
}
#breadcrumbs-container > *:not(:last-child) {
  color: #7f7f7f;
  color: rgba(0, 0, 0, 0.5);
}
#breadcrumbs-container .breadcrumb {
  padding: 0;
  margin: 0 0.4444444444rem;
  text-transform: capitalize;
  overflow: hidden;
  height: 21px;
}
#breadcrumbs-container .breadcrumb:first-child {
  margin-left: 0;
}

#chart-details-breadcrumbs-container {
  display: flex;
  align-items: center;
  min-width: 30rem;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
#chart-details-breadcrumbs-container > * {
  font-size: 1rem;
  line-height: 22px;
  float: left;
}
#chart-details-breadcrumbs-container > *:not(:last-child) {
  color: #7f7f7f;
  color: rgba(0, 0, 0, 0.5);
}
#chart-details-breadcrumbs-container .breadcrumb {
  padding: 0;
  margin: 0 0.4444444444rem;
  text-transform: capitalize;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  line-height: 22px;
  flex-shrink: 0;
}
#chart-details-breadcrumbs-container .breadcrumb:first-child {
  margin-left: 0;
}
#chart-details-breadcrumbs-container .breadcrumb--shrink {
  flex-shrink: 1;
}

.chart-title.chart-title-0.chart-title-breadcrumbs {
  flex: 0 0 auto;
}

.chart-title.chart-title-0.contract-title-breadcrumbs {
  flex: 0 0 auto;
}

.dropdown-menu > li > a {
  cursor: pointer;
}

input + .error-messages, textarea + .error-messages {
  color: #d91a1a;
  font-size: 0.6rem;
  margin-top: 0.2222222222rem;
}

input + .success-messages {
  color: #66af10;
  font-size: 0.5rem;
  margin-top: 0.2222222222rem;
}

.settings-box input + .error-messages, .settings-box input + .success-messages {
  position: absolute;
  top: 2.666rem;
  right: 3.333rem;
}
.settings-box .form-control-feedback {
  top: 0.75rem;
  right: 1rem;
}

.message-box {
  border: solid 0.0555555556rem;
  font-size: 0.6666666667rem;
  padding: 0.8888888889rem;
  display: none;
  margin-bottom: 0.8888888889rem;
}
.message-box.error-messages {
  color: #d91a1a;
  border-color: #d91a1a;
  background-color: #fadcdc;
  background-color: rgba(217, 26, 26, 0.15);
}
.message-box.success-messages {
  color: #66af10;
  border-color: #66af10;
  background-color: #e8fedb;
  background-color: rgba(102, 175, 16, 0.15);
}

.caption-text {
  margin-bottom: 2rem;
}

.pin-dropdown {
  margin-bottom: 1.3333333333rem;
}
.pin-dropdown label {
  font-size: 0.7777777778rem;
  font-weight: 500;
}
.pin-dropdown .dropdown-toggle {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 14.7222222222rem;
  text-align: left;
  background-color: transparent;
  border: none;
  border-bottom: solid 0.0555555556rem #7f7f7f;
  border-bottom: solid 0.0555555556rem rgba(0, 0, 0, 0.5);
  padding-right: 1.3333333333rem;
  padding-left: 0;
}
.pin-dropdown .dropdown-toggle .caret {
  position: absolute;
  top: 0.7777777778rem;
  right: 0;
  border-top: 0.3333333333rem dashed #7f7f7f;
  border-top: 0.3333333333rem dashed rgba(0, 0, 0, 0.5);
  border-bottom: 0.3333333333rem solid transparent;
  border-left: 0.3333333333rem solid transparent;
  border-right: 0.3333333333rem solid transparent;
}

.pin-dropdown-row:last-child {
  margin-bottom: 0.4444444444rem;
}

#pin-a-chart {
  border: solid #CCC 0.1111111111rem;
  margin: 15px 15px;
  padding: 0;
  width: calc(50% - 30px);
  height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: #CCC;
}

#calculator-page {
  margin-top: 4.5rem;
}

.calc-menu {
  color: #CCC !important;
  background: #fafafa !important;
  cursor: default !important;
  outline: none !important;
}

.number-label {
  margin-left: 0.6666rem;
  padding-top: 0.3333rem;
  text-align: center;
  font-size: medium;
  color: #CCC;
}

.asterisk-label {
  margin-right: 1rem;
  text-align: start;
  font-size: small;
  color: #d91a1a;
  cursor: pointer;
}

#calculatorsGeneralRateIncrease .table {
  background-color: white;
}
#calculatorsGeneralRateIncrease .table > tbody > tr:hover {
  background: none !important;
  color: black;
}
#calculatorsGeneralRateIncrease .table > tfoot > tr > td {
  cursor: default;
  text-align: right;
  padding: 0.5rem 2rem 0.5rem 0.5rem;
  color: #20a1db;
  font-weight: bold;
  font-size: 80%;
}
#calculatorsGeneralRateIncrease .table > tfoot > tr > td:first-child {
  cursor: default;
  text-align: left;
}
#calculatorsGeneralRateIncrease .table > thead > tr > th, #calculatorsGeneralRateIncrease .table > thead > tr > td, #calculatorsGeneralRateIncrease .table > tbody > tr > th, #calculatorsGeneralRateIncrease .table > tbody > tr > td {
  cursor: default;
  text-align: right;
  font-size: 80%;
}
#calculatorsGeneralRateIncrease .table > thead > tr > th:first-child, #calculatorsGeneralRateIncrease .table > thead > tr > td:first-child, #calculatorsGeneralRateIncrease .table > tbody > tr > th:first-child, #calculatorsGeneralRateIncrease .table > tbody > tr > td:first-child {
  cursor: default;
  text-align: left;
  padding-left: 0.75rem;
}

#calculatorsDimensionalWeight .settings-field-label {
  width: 53%;
}

#calculatorsDimensionalWeight .settings-field-value {
  width: 40%;
}

#calculatorsGeneralRateIncrease .settings-field-label {
  width: 47%;
  font-size: 70%;
  padding-right: 1px;
}

#calculatorsGeneralRateIncrease .settings-field-value {
  width: 45%;
}

.popover {
  background-color: rgba(0, 0, 0, 0.8) !important;
}

.left > .arrow:after {
  border-left-color: rgba(0, 0, 0, 0.8) !important;
}

.top > .arrow:after {
  border-top-color: rgba(0, 0, 0, 0.8) !important;
}

.right > .arrow:after {
  border-right-color: rgba(0, 0, 0, 0.8) !important;
}

#calculatorsDimensionalWeight .card {
  width: 49%;
}

#calculatorsDimensionalWeight input,
#calculatorsGeneralRateIncrease input {
  text-align: right;
}

#calculatorsDimensionalWeight output {
  float: right;
  text-align: left;
  font-weight: bold;
  color: #20a1db;
}

#calculatorsDimensionalWeight input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button,
#calculatorsGeneralRateIncrease input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

#calculatorsDimensionalWeight input[type=number],
#calculatorsGeneralRateIncrease input[type=number] {
  -moz-appearance: textfield;
}

#calculatorsDimensionalWeight .card,
#calculatorsGeneralRateIncrease .card {
  float: left;
}

#calculatorsDimensionalWeight .card:last-child,
#calculatorsGeneralRateIncrease .card:last-child {
  margin-left: 1%;
}

#calculatorsGeneralRateIncrease .inputs-card {
  width: 30%;
}

#calculatorsGeneralRateIncrease .outputs-card {
  width: 69%;
}

#calculatorsGeneralRateIncrease .outputs-card .card-body {
  padding: 1.3333rem 0.6667rem;
}

#calculatorsGeneralRateIncrease .inputs-card .card-body {
  padding: 1.333rem 7%;
}

.button-GeneralRateIncrease {
  position: absolute;
  top: 500px;
}

#calculatorMinimumChargeImpact .outputs-card {
  width: 49%;
  float: left;
  margin-top: 1rem;
}

#calculatorMinimumChargeImpact .outputs-card #table-holder-a-view-option,
#calculatorMinimumChargeImpact .outputs-card #table-holder-b-view-option {
  border: none;
  align-items: right;
  width: auto;
}

#calculatorMinimumChargeImpact select {
  cursor: pointer;
  background: url(/img/arrow.png) no-repeat 95% 50%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding-right: 40px;
}

select::-ms-expand {
  display: none;
}

#calculatorMinimumChargeImpact select:not(:-internal-list-box) {
  overflow: hidden !important;
}

#calculatorMinimumChargeImpact .settings-field-label {
  width: 25%;
}

#calculatorMinimumChargeImpact .settings-field-value {
  width: 73%;
}

#calculatorMinimumChargeImpact .settings-field-value select {
  width: 47%;
  margin-left: 1%;
  cursor: pointer;
}

#calculatorMinimumChargeImpact .settings-field-value input {
  width: 47%;
  background-color: white;
  border: rgb(169, 169, 169) 1px solid;
  margin-left: 1%;
}

.slider.slider-horizontal {
  height: 14px;
  width: 47%;
  margin: 0 1% 0 1% !important;
}

.slider.slider-horizontal .slider-track {
  height: 2px;
}

.slider-tooltip-bubble .tooltip {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.3) !important;
  background-color: #fff;
  border-radius: 8px;
  padding-right: 24px !important;
  z-index: 1010;
}

#calculatorMinimumChargeImpact .tooltip {
  z-index: 0 !important;
}

#calculatorMinimumChargeImpact .bootstrap-select:not([class*=col-]):not([class*=form-control]):not(.input-group-btn) {
  width: 47% !important;
  padding: 0rem !important;
  margin-left: 3px;
}
#calculatorMinimumChargeImpact .btn.dropdown-toggle.btn-default {
  padding-top: 5px;
  padding-bottom: 5px;
  border: #a9a9a9 1px solid;
  line-height: 28px;
}
#calculatorMinimumChargeImpact .dropdown-menu.open {
  z-index: 4 !important;
}
#calculatorMinimumChargeImpact .filter-option.pull-left {
  font-size: 16px !important;
}
#calculatorMinimumChargeImpact #service-type-b-id {
  padding-top: 5px;
}

.slider.slider-horizontal .slider-tick-label-container {
  margin-top: 10px;
  margin-left: -20px !important;
  margin-right: -20px !important;
  overflow: hidden;
}

.slider-tick-label-container .slider-tick-label:first-child {
  margin-left: -24% !important;
}
.slider-tick-label-container .slider-tick-label:last-child {
  margin-left: 20% !important;
}

.slider.slider-horizontal .slider-tick-label-container .slider-tick-label:nth-of-type(1) {
  width: 62% !important;
}

.slider.slider-horizontal .slider-tick-label-container .slider-tick-label:nth-of-type(2) {
  width: 57% !important;
}

.slider-handle {
  width: 10px;
  height: 10px;
}

.table_holder_a_view_option, .table_holder_b_view_option {
  width: 77%;
  text-align: right;
}

#service_type_a_name, #service_type_b_name {
  width: 23%;
  text-align: left;
}

.minimum_charge_amount_text {
  color: red;
  text-align: left;
}

#calculatorMinimumChargeImpact .table > tbody > tr:hover {
  background: none !important;
  color: black;
}
#calculatorMinimumChargeImpact .table > thead > tr > th, #calculatorMinimumChargeImpact .table > thead > tr > td, #calculatorMinimumChargeImpact .table > tbody > tr > th, #calculatorMinimumChargeImpact .table > tbody > tr > td {
  cursor: default;
  padding-right: 0;
}
#calculatorMinimumChargeImpact .table > thead > tr > th:first-child, #calculatorMinimumChargeImpact .table > thead > tr > td:first-child, #calculatorMinimumChargeImpact .table > tbody > tr > th:first-child, #calculatorMinimumChargeImpact .table > tbody > tr > td:first-child {
  cursor: default;
}

.calcminright {
  margin-left: 1rem;
}

.pagination .paginate_button a {
  border: none;
  outline: none;
  background-color: inherit !important;
  color: #20a1db;
}
.pagination .paginate_button a:hover {
  color: #1c8cbf;
}
.pagination .paginate_button.active a {
  color: rgba(0, 0, 0, 0.5) !important;
}
.pagination .paginate_button.disabled a {
  color: #CCC;
  cursor: default;
}

#calculatorMinimumChargeImpact .custom-radio {
  display: none;
}
#calculatorMinimumChargeImpact .custom-radio:checked + .custom-radio-label:before {
  background-color: #20a1db;
}
#calculatorMinimumChargeImpact .custom-radio-label {
  display: flex;
  align-items: center;
}
#calculatorMinimumChargeImpact .custom-radio-label:before {
  content: "";
  display: inline-block;
  width: 16px;
  height: 16px;
  margin-right: 10px;
  background-color: #fff;
  border-radius: 50%;
  border: 3.5px solid #fff;
  box-shadow: 0px 0px 0px 1.5px rgb(149, 152, 154);
}

.columnize-2 {
  width: 230px;
  position: absolute;
  margin-left: 210px;
  height: 600px;
}

.discount-percent-input-box {
  width: 150px;
  background-color: #fff;
  border: #a9a9a9 1px solid;
  margin-left: 1%;
  height: 40px;
  margin-bottom: 10px;
  padding-left: 14px;
}

.columnize-2-right {
  width: 230px;
  margin-left: 453px;
}

.columnize-2 label, .columnize-2-right label {
  font-size: 14px;
  min-width: 80px;
  margin-bottom: 15px;
  text-align: right;
  padding-right: 14px;
}

.columnize-title {
  height: 20px;
  padding-bottom: 10px;
  font-size: 14px;
}

.columnize-title-left {
  height: 20px;
  padding-bottom: 10px;
  font-size: 14px;
  text-align: right;
  padding-right: 14px;
}

.tooltip {
  z-index: 0;
}

.slider-tooltip-bubble .tooltip-inner {
  margin-left: 3px !important;
}

.slider-tooltip-bubble .tooltip.top .tooltip-arrow {
  margin-left: -12px !important;
}

#userDetailsForm .card {
  width: 50%;
  margin-top: 1.3333rem;
}

#userDetailsForm .card-permissions {
  width: 100% !important;
}

#userDetailsForm .settings-field-label {
  width: 25%;
}

#userDetailsForm .settings-field-value {
  width: 72%;
  display: inline-table;
}

#userDetailsForm .settings-field-value select {
  width: 95%;
  height: 80%;
}

.fileform {
  background-color: #FFFFFF;
  cursor: pointer;
  height: fit-content;
  overflow: hidden;
  padding: 2px 0px;
  position: relative;
  text-align: left;
  vertical-align: middle;
  width: 95%;
}

.fileform.disabled,
input.disabled {
  cursor: default !important;
}

.fileform .selectbutton {
  background-color: #20a1db;
  border: 1px solid #939494;
  color: #FFFFFF;
  float: right;
  font-size: 12px;
  height: fit-content;
  line-height: 20px;
  overflow: hidden;
  padding: 2px 4px;
  text-align: center;
  vertical-align: middle;
  width: 33%;
  display: inline-block;
}

.fileform .selectbutton.success {
  background-color: #fafafa;
  border: none;
  color: lightgrey;
}

.fileform .disable {
  background-color: #ccc !important;
}

.fileform #csv-file-upload {
  position: absolute;
  top: 0;
  left: -110px;
  bottom: 0;
  width: calc(100% + 110px);
  -moz-opacity: 0;
  filter: alpha(opacity=0);
  opacity: 0;
  z-index: 20;
  cursor: pointer;
  height: 100%;
}

.fileform .fileformlabel {
  background-color: #FFFFFF;
  border: 1px solid #CCCCCC;
  float: left;
  margin-bottom: 4px;
  height: 30px;
  line-height: 22px;
  overflow: hidden;
  text-align: left;
  vertical-align: middle;
  width: 100%;
  display: flex;
  padding: 0px 0px 0px 2px;
}

.file-label-message {
  color: lightgray;
  font-size: 0.7778rem;
  display: inline-block;
  float: left;
}

.upload-messages {
  font-size: 0.6667rem;
  width: 100%;
  line-height: 40px;
  text-align: left;
  line-height: 0.8889rem;
  padding: 1rem;
}

.progress {
  border-radius: 0px;
  height: 100%;
}

#close {
  border: 1px solid #20a1db;
}

.upload-messages.upload-success {
  color: #66AF10;
  background-color: #E8F3DB;
  border: 1px solid #66AF10;
}

.upload-messages.upload-failed {
  color: #D91A1A;
  background-color: #FADCDC;
  border: 1px solid #D91A1A;
  text-align: left;
}

.disabled.upload-success {
  color: green;
  background-color: greenyellow;
  border: 1px solid green;
}

.bootstrap-select:not([class*=col-]):not([class*=form-control]):not(.input-group-btn) {
  width: 75% !important;
}

#userDetailsForm .dropdown-menu > li > a {
  padding: 3px 3px 3px 7px !important;
}

#userDetailsForm .bootstrap-select.btn-group .dropdown-menu {
  max-width: 100% !important;
  max-height: 295px !important;
  overflow-x: hidden;
}

.btn {
  border-radius: 0px !important;
}

.table > thead > tr > th, .table > thead > tr > td, .table > tbody > tr > th, .table > tbody > tr > td {
  cursor: default !important;
}
.table > thead > tr > th:first-child, .table > thead > tr > td:first-child, .table > tbody > tr > th:first-child, .table > tbody > tr > td:first-child {
  cursor: default !important;
}
.table > thead > tr > th:first-child:focus, .table > thead > tr > td:first-child:focus, .table > tbody > tr > th:first-child:focus, .table > tbody > tr > td:first-child:focus {
  outline: -webkit-focus-ring-color none;
}

.coresults-upload-failed span {
  color: #E36464;
}

.coresults-upload-in-process span {
  color: #FFA700;
}

.coresults-upload-complete span {
  color: #1FA000;
}

#userDetailsForm .card {
  width: 50%;
  margin-top: 1.3333rem;
}

#userDetailsForm .card-permissions {
  width: 100% !important;
}

#userDetailsForm .settings-field-label {
  width: 25%;
}

#userDetailsForm .settings-field-value {
  width: 72%;
  display: inline-table;
}

#userDetailsForm .settings-field-value select {
  width: 95%;
  height: 80%;
}

.fileform {
  background-color: #FFFFFF;
  cursor: pointer;
  height: fit-content;
  overflow: hidden;
  padding: 2px 0px;
  position: relative;
  text-align: left;
  vertical-align: middle;
  width: 95%;
}

.fileform.disabled,
input.disabled {
  cursor: default !important;
}

.fileform .selectbutton {
  background-color: #20a1db;
  border: 1px solid #939494;
  color: #FFFFFF;
  font-size: 12px;
  height: fit-content;
  line-height: 20px;
  overflow: hidden;
  padding: 2px 4px;
  text-align: center;
  vertical-align: middle;
  width: 33%;
  display: inline-block;
}

.fileform .selectbutton.success {
  background-color: #fafafa;
  border: none;
  color: lightgrey;
}

.fileform .disable {
  background-color: #ccc !important;
}

.fileform #csv-file-upload {
  position: absolute;
  top: 0;
  left: -110px;
  bottom: 0;
  width: calc(100% + 110px);
  -moz-opacity: 0;
  filter: alpha(opacity=0);
  opacity: 0;
  z-index: 20;
  cursor: pointer;
  height: 100%;
}

.fileform .fileformlabel {
  background-color: #FFFFFF;
  border: 1px solid #CCCCCC;
  float: left;
  margin-bottom: 4px;
  height: 30px;
  line-height: 22px;
  overflow: hidden;
  text-align: left;
  vertical-align: middle;
  width: 100%;
  display: flex;
  padding: 0px 0px 0px 2px;
}

.file-label-message {
  color: lightgray;
  font-size: 0.7778rem;
  display: inline-block;
}

.upload-messages {
  font-size: 0.6667rem;
  width: 100%;
  line-height: 40px;
  text-align: left;
  line-height: 0.8889rem;
  padding: 1rem;
}

.progress {
  border-radius: 0px;
  height: 100%;
}

#close {
  border: 1px solid #20a1db;
}

.upload-messages.upload-success {
  color: #66AF10;
  background-color: #E8F3DB;
  border: 1px solid #66AF10;
}

.upload-messages.upload-failed {
  color: #D91A1A;
  background-color: #FADCDC;
  border: 1px solid #D91A1A;
  text-align: left;
}

.disabled.upload-success {
  color: green;
  background-color: greenyellow;
  border: 1px solid green;
}

.bootstrap-select:not([class*=col-]):not([class*=form-control]):not(.input-group-btn) {
  width: 75% !important;
}

#userDetailsForm .dropdown-menu > li > a {
  padding: 3px 3px 3px 7px !important;
}

#userDetailsForm .bootstrap-select.btn-group .dropdown-menu {
  max-width: 100% !important;
  max-height: 295px !important;
  overflow-x: hidden;
}

.btn {
  border-radius: 0px !important;
}

.table > thead > tr > th, .table > thead > tr > td, .table > tbody > tr > th, .table > tbody > tr > td {
  cursor: default !important;
}
.table > thead > tr > th:first-child, .table > thead > tr > td:first-child, .table > tbody > tr > th:first-child, .table > tbody > tr > td:first-child {
  cursor: default !important;
}
.table > thead > tr > th:first-child:focus, .table > thead > tr > td:first-child:focus, .table > tbody > tr > th:first-child:focus, .table > tbody > tr > td:first-child:focus {
  outline: -webkit-focus-ring-color none;
}

.coresults-upload-failed span {
  color: #E36464;
}

.coresults-upload-in-process span {
  color: #FFA700;
}

.coresults-upload-complete span {
  color: #1FA000;
}

.details-panel {
  float: left;
  padding: 1.3333333333rem 1.3333333333rem 0;
  min-height: calc(100vh - 7.1666666667rem);
  margin-top: 3.5rem;
}

.details-left-panel {
  background-color: white;
  margin-left: -1.3333333333rem;
  margin-top: 3.5rem;
  z-index: 1;
}

.details-right-panel {
  width: calc(100% - 20rem);
}

.details-icon-title {
  text-transform: uppercase;
}

.details-block {
  border-bottom: solid 0.01625rem #dfdfdf;
  line-height: 2rem;
  font-size: 0.8889rem;
}
.details-block .details-field {
  text-transform: uppercase;
}
.details-block .details-value {
  text-transform: uppercase;
}
.details-block + .details-block {
  padding-top: 1rem;
}
.details-block > .details-header {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 1rem;
}
.details-block > .details-body {
  padding: 1rem 0;
}
.details-block > .details-body > .details-row-header {
  font-weight: bold;
  width: 100%;
  height: 2.3333333333rem;
}
.details-block > .details-body > .details-row-header > .details-field {
  float: left;
}
.details-block > .details-body > .details-row-header > .details-value {
  float: right;
}
.details-block > .details-body > .details-row {
  width: 100%;
  height: 2.3333333333rem;
}
.details-block > .details-body > .details-row > .details-field {
  float: left;
}
.details-block > .details-body > .details-row > .details-value {
  float: right;
}
.details-block > .details-body > .details-row > .details-value > .details-two-row-value {
  padding-left: 10rem;
}
.details-block > .details-body > .details-two-row {
  height: 4.4444444444rem;
}

.details-icon-container {
  display: flex;
  justify-content: space-around;
}
.details-icon-container .details-icon {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 1rem;
}
.details-icon-container .details-icon .details-icon-image {
  height: 5rem;
}
.details-icon-container .details-icon .details-icon-text {
  text-transform: uppercase;
  font-weight: bold;
  max-width: 12.5rem;
  overflow-wrap: normal;
  min-height: 90px;
  text-align: center;
  line-height: 1.333rem;
  white-space: normal;
}

.package-details-right > .details-block > .details-body > .details-row > .details-field {
  width: 10rem;
}
.package-details-right > .details-block > .details-body > .details-row > .details-value {
  float: none;
}

.drilldown .disappears-on-drilldown, .drilldown .pin-button, .drilldown .sidebar-toggle, .drilldown .toggle-options, .drilldown #chart-detail-description-menu-item {
  display: none;
}
.drilldown .disappears-on-drilldown {
  width: 0;
}

.inputservice {
  width: 4rem;
  text-indent: 5px;
}

.navbar {
  background-color: #173f6c;
  box-shadow: 0 0.3125rem 0.3125rem rgba(128, 128, 128, 0.29);
  border: none;
  display: flex;
  align-items: center;
  height: 3.5555555556rem;
  width: 100%;
  margin: 0;
  z-index: 1002;
}
.navbar > .navbar-header {
  float: none;
  margin-left: 1.333rem;
  display: flex;
  align-items: center;
  width: 100%;
}
.navbar > .navbar-header > .navbar-brand {
  margin-right: 1rem;
  height: auto;
  padding: 0;
}
.navbar > .navbar-header > .companyName {
  color: #f5f5f5;
  border-left: 0.01625rem #20a1db solid;
  padding-left: 1rem;
}
.navbar > .navbar-header > .temporary-message {
  border-left: 0.01625rem #20a1db solid;
  margin-left: 15rem;
  text-transform: uppercase;
}
.navbar > .navbar-header > .navbar-banner {
  background-color: #20A1DB;
  display: inline-block;
  color: #fff;
  padding: 5px 15px;
  border-radius: 5px;
  margin-left: 10%;
  font-size: 14px;
  cursor: pointer;
}
.navbar > .navbar-header > .navbar-banner > .navbar-banner-text {
  float: left;
  margin-left: 10px;
  margin-right: 10px;
}
.navbar > .navbar-header > .navbar-banner > .navbar-banner-text > .banner-header {
  font-weight: bold;
  font-size: 16px;
}
.navbar > .navbar-header > .navbar-banner > .navbar-banner-text > .banner-info {
  color: rgb(186, 227, 247);
}
.navbar > .navbar-header > .navbar-banner > .banner-logo {
  float: left;
  width: 73px;
  height: 43px;
}
.navbar > .navbar-header > .navbar-banner > .banner-action {
  float: left;
  margin-top: 6px;
}
.navbar > .navbar-header > .navbar-banner ::after {
  clear: both;
}
.navbar > .custom-search-form {
  margin-bottom: 0.6666666667rem;
  margin-right: 1.3333333333rem;
  width: 35%;
}
.navbar > .custom-search-form > .form-control {
  width: 21.6666666667rem;
}
.navbar > .custom-search-form > .input-group-btn {
  width: auto;
  min-width: 3.1111111111rem;
}
.navbar > .sidebar {
  top: 3.5555555556rem;
  margin: 0;
}
.navbar > .sidebar > .sidebar-nav {
  top: 3.5555555556rem;
  margin: 0;
}
.navbar > .sidebar ul li a {
  padding: 0 1.1111111111rem;
  height: 2.6666666667rem;
}

.top-nav-btn {
  margin-top: 0.7778rem;
}

.nav-menu-bottom {
  bottom: 0;
  width: 100%;
}

.nav-menu-top {
  top: 0;
  width: 13rem;
}

.flexbox-nav {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: inline-flex !important;
  -webkit-flex-flow: row wrap;
  flex-flow: row wrap;
  text-align: center;
  width: 100%;
  height: 2.6666666667rem;
  align-items: center;
  cursor: pointer;
}

@media (max-width: 1440px) {
  .flexbox-nav {
    height: 2.2222222222rem;
    cursor: pointer;
  }
  .nav-menu-bottom {
    margin-top: 40px;
  }
}
.flexbox-nav > * {
  flex: 1 100%;
  cursor: pointer;
}

.flexbox-nav:hover {
  background-color: inherit;
  color: #1c8cbf;
  cursor: pointer;
}
.flexbox-nav:hover .nav-desc-sides-lhs, .flexbox-nav:hover .nav-desc-title {
  color: #1c8cbf;
}

.nav-desc-title {
  flex: 3 0;
  text-align: left;
  order: 2;
  line-height: 1;
  font-size: 0.8888888889rem;
  font-weight: 700;
  color: #212121;
  color: rgba(0, 0, 0, 0.87);
}

.nav-desc-title-gray {
  flex: 3 0;
  text-align: left;
  order: 2;
  line-height: 1;
  font-size: 0.8888888889rem;
  font-weight: 700;
  color: #212121;
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.5;
}

.nav-desc-sides {
  flex: 1 auto;
}

.t-image-c {
  position: relative;
  top: -7px;
  left: 43px;
  opacity: 1;
}

.t-image-modal {
  cursor: pointer;
  position: absolute;
  top: -7px;
  left: 225px;
  opacity: 1;
}

.t-image-r {
  position: relative;
  top: -5px;
  left: 12px;
  opacity: 1;
}

.t-image-p {
  position: relative;
  top: -6px;
  left: 22px;
  opacity: 1;
}

.t-image-i {
  position: relative;
  top: -6px;
  left: 33px;
  opacity: 1;
}

.nav-desc-sides-lhs {
  order: 1;
  text-align: left;
  width: 10px;
  height: 22px;
  margin-top: -2px;
  color: #7f7f7f;
  color: rgba(0, 0, 0, 0.5);
}

.nav-link-active .nav-desc-sides-lhs, .nav-link-active .nav-desc-title {
  color: #20a1db;
}

.nav-desc-sides-rhs {
  order: 3;
}

.sidebar-search-input-box {
  color: #212121;
  color: rgba(0, 0, 0, 0.87);
  font-family: "Roboto Regular", sans-serif;
  font-size: 0.7777777778rem;
  border-radius: 0;
}

.sidebar-search-submit-btn {
  color: white;
  background-color: #20a1db;
  border-radius: 0;
  border-color: #20a1db;
  display: none;
  width: 3.1111111111rem;
}

.sidebar-nav {
  position: fixed;
  left: 0;
  top: 3.5555555556rem;
  width: 13rem;
  padding-left: 10px;
  z-index: 1001;
}

.sidebar-nav-background {
  position: fixed;
  left: 0;
  top: 3.5555555556rem;
  width: 13rem;
  padding-left: 10px;
  height: 100%;
  z-index: 1000;
  background-color: #f5f5f5;
}

div.header-div {
  position: fixed;
  height: 3.6111rem;
  display: flex;
  align-items: center;
  background-color: #f5f5f5;
  padding: 0 1.3333333333rem;
  z-index: 2;
  width: 100%;
}
div.header-div span {
  white-space: nowrap;
}
div.header-div .empty {
  width: 100%;
}
div.header-div .material-icons {
  vertical-align: middle;
}

.header-div-chart-details {
  width: auto !important;
}

.chart-type-navigation {
  border-bottom: solid 0.0555555556rem #dfdfdf;
  margin: 0 -1.3333333333rem 1.3333333333rem;
  padding: 0 1.3333333333rem;
  background-color: #FAFAFA;
  display: flex;
  align-items: center;
  position: fixed;
  width: 88.125%;
  z-index: 5;
  /* css@media screen and(min-width: 1920px){
    width: 87.825%;
  }
  @media screen and(min-width: 1600px)and(max-width: 1919px){
    width: 87.425%;
  }
  @media screen and(min-width: 1440px)and(max-width: 1599px){
    width: 85.325%;
  }
  @media screen and(min-width: 1366px)and(max-width: 1439px){
    width: 82.825%;
  }
  @media screen and (max-width: 1365px){
    width: 80.425%;
  }*/
}
.chart-type-navigation .chart-type-navigation-item {
  padding: 1.05rem;
  color: #848484;
  cursor: pointer;
  transform: translateY(0.1111111111rem);
}
.chart-type-navigation .chart-type-navigation-item.active {
  border-bottom: solid 0.1666666667rem #20a1db;
  color: #20a1db;
  padding-bottom: 0.8889rem;
}
.chart-type-navigation .sidebar-toggle {
  text-transform: uppercase;
  color: #20a1db;
  /*width:100%;*/
}
.chart-type-navigation .sidebar-toggle:hover {
  text-decoration: none;
}

div.dropdown_calc, .top_right {
  position: fixed;
  top: 78px;
  right: 0;
  margin: 0 20px;
}

.top_right_left {
  position: fixed;
  top: 78px;
  right: 0;
  margin: 0 155px;
}

.top_right_middle {
  position: fixed;
  top: 78px;
  right: 0;
  margin: 0 100px;
}

.top_right_middle2 {
  position: fixed;
  top: 78px;
  right: 0;
  margin: 0 85px;
}

.top_right_contract {
  position: fixed;
  right: 0;
  top: 78px;
  margin: 0 30px;
}

.dropdown-toggle .caret {
  height: 0;
  margin-top: auto;
  margin-bottom: auto;
}

.selected-week {
  background: transparent;
  border: none;
  outline: none;
  text-align: center;
  width: 10rem;
}
.selected-week.active {
  background: #ddf0f8;
  background: rgba(32, 161, 219, 0.12);
  border: solid #20a1db 0.01625rem;
}

.conteiner-custom-radio .radio-custom {
  opacity: 0;
  position: absolute;
}
.conteiner-custom-radio .radio-custom, .conteiner-custom-radio .radio-custom-label {
  display: inline-block;
  vertical-align: middle;
  margin: 5px;
  cursor: pointer;
}
.conteiner-custom-radio .radio-custom-label {
  position: relative;
  font-size: 12px;
}
.conteiner-custom-radio .radio-custom + .radio-custom-label:before {
  content: "";
  background: #fff;
  border: 1px solid #ddd;
  display: inline-block;
  vertical-align: middle;
  width: 24px;
  height: 24px;
  padding: 0px;
  margin-right: 10px;
  text-align: center;
}
.conteiner-custom-radio .radio-custom + .radio-custom-label:before {
  border-radius: 50%;
}
.conteiner-custom-radio .radio-custom:checked + .radio-custom-label:before {
  content: "\f107";
  font-family: "FontAwesome";
  color: white;
  background: rgb(4, 185, 4);
  font-size: 18px;
}

.pager-container {
  position: fixed;
  bottom: 0;
  right: 0;
  height: 3rem;
  width: calc(100% - 13rem);
  background: #FAFAFA;
  margin-left: -1rem;
  padding-left: 1rem;
  padding-right: 1.3333333333rem;
  border: solid 0.125rem #E6E6E6;
  display: flex;
  align-items: center;
}
.pager-container .button {
  display: inline-block;
}
.pager-container .pager-status {
  margin-left: 2rem;
}
.pager-container .pager-controls {
  display: inline-flex;
  align-items: center;
  height: 100%;
  position: absolute;
  right: 1rem;
  top: 0;
}
.pager-container .pager-controls .material-icons, .pager-container .pager-controls .previous-pagination-btn, .pager-container .pager-controls .next-pagination-btn {
  vertical-align: middle;
  color: #20a1db;
}
.pager-container .pager-controls .material-icons.disabled, .pager-container .pager-controls .previous-pagination-btn.disabled, .pager-container .pager-controls .next-pagination-btn.disabled {
  color: #CCC;
  pointer-events: none;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .pager-container .pager-controls .pager-controls .material-icons {
    max-height: 17px;
  }
}
.pager-container .pager-controls .page-list a {
  padding: 0.5rem;
}
.pager-container .pager-controls .page-list a.active {
  color: #7f7f7f;
  color: rgba(0, 0, 0, 0.5);
  pointer-events: none;
  cursor: default;
}

.pager-spacer {
  height: 3rem;
}

.off-canvas-sidebar-container {
  height: calc(100vh - 3rem);
  position: fixed;
  background: #FAFAFA;
  right: 0;
  width: auto;
  top: 3rem;
  border: solid 0.0625rem #dfdfdf;
  padding: 1.25em 0;
  overflow-y: auto;
  transition: all 0.5s;
  z-index: 2;
  /* select starting stylings ------------------------------*/
  /* Remove focus */
  /* Use custom arrow */
  /* LABEL ======================================= */
  /* active state */
  /* BOTTOM BARS ================================= */
  /* active state */
  /* HIGHLIGHTER ================================== */
}
.off-canvas-sidebar-container:not(.active) {
  transform: translateX(100%);
}
.off-canvas-sidebar-container .close-button {
  margin-right: 1.3333333333rem;
}
.off-canvas-sidebar-container .section {
  padding: 0.5rem 1.5rem;
}
.off-canvas-sidebar-container .section + .section {
  border-top: 0.0625rem solid #dfdfdf;
}
.off-canvas-sidebar-container .section .section-header {
  color: #20a1db;
  font-weight: bold;
  padding: 0.5rem 0;
}
.off-canvas-sidebar-container .section .sidebar-item {
  color: rgba(0, 0, 0, 0.87);
  padding: 0.5rem;
  width: 15rem;
  display: flex;
  align-items: center;
}
.off-canvas-sidebar-container .section .sidebar-item:hover {
  color: #20a1db;
}
.off-canvas-sidebar-container .section .material-icons.check {
  color: #20a1db;
  margin-right: 2rem;
  visibility: hidden;
}
.off-canvas-sidebar-container .section .active > .material-icons.check {
  visibility: visible;
}
.off-canvas-sidebar-container .section #list-search-bar {
  border: 0;
  outline: 0;
  border-bottom: 1px solid lightgrey;
  width: 100%;
  margin-bottom: 20px;
  background-color: rgb(250, 250, 250);
}
.off-canvas-sidebar-container .section #list-search-bar:focus {
  box-shadow: none;
}
.off-canvas-sidebar-container .section .filter-buttons button {
  width: 46%;
}
.off-canvas-sidebar-container .section .filter-buttons #resetButtonSearch {
  margin-right: 5%;
}
.off-canvas-sidebar-container .button-container {
  width: 100%;
  text-align: right;
}
.off-canvas-sidebar-container .select {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  position: relative;
  width: 350px;
}
.off-canvas-sidebar-container .select-text {
  position: relative;
  font-family: inherit;
  background-color: transparent;
  width: 350px;
  padding: 10px 10px 10px 0;
  font-size: 18px;
  border-radius: 0;
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.off-canvas-sidebar-container .select-text:focus {
  outline: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0);
}
.off-canvas-sidebar-container .select .select-text {
  appearance: none;
  -webkit-appearance: none;
}
.off-canvas-sidebar-container .select:after {
  position: absolute;
  top: 18px;
  right: 10px;
  /* Styling the down arrow */
  width: 0;
  height: 0;
  padding: 0;
  content: "";
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid rgba(0, 0, 0, 0.12);
  pointer-events: none;
}
.off-canvas-sidebar-container .select-label {
  color: rgba(0, 0, 0, 0.26);
  font-size: 18px;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 0;
  top: 10px;
  transition: 0.2s ease all;
}
.off-canvas-sidebar-container .select-text:focus ~ .select-label, .off-canvas-sidebar-container .select-text:valid ~ .select-label {
  color: #2F80ED;
  top: -20px;
  transition: 0.2s ease all;
  font-size: 14px;
}
.off-canvas-sidebar-container .select-bar {
  position: relative;
  display: block;
  width: 350px;
}
.off-canvas-sidebar-container .select-bar:before, .off-canvas-sidebar-container .select-bar:after {
  content: "";
  height: 2px;
  width: 0;
  bottom: 1px;
  position: absolute;
  background: #2F80ED;
  transition: 0.2s ease all;
}
.off-canvas-sidebar-container .select-bar:before {
  left: 50%;
}
.off-canvas-sidebar-container .select-bar:after {
  right: 50%;
}
.off-canvas-sidebar-container .select-text:focus ~ .select-bar:before, .off-canvas-sidebar-container .select-text:focus ~ .select-bar:after {
  width: 50%;
}
.off-canvas-sidebar-container .select-highlight {
  position: absolute;
  height: 60%;
  width: 100px;
  top: 25%;
  left: 0;
  pointer-events: none;
  opacity: 0.5;
}
.off-canvas-sidebar-container .selectBox {
  position: relative;
}
.off-canvas-sidebar-container .selectBox select {
  width: 100%;
  font-weight: bold;
}
.off-canvas-sidebar-container .overSelect {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
.off-canvas-sidebar-container #checkboxes {
  display: none;
  border: 1px #dadada solid;
}
.off-canvas-sidebar-container #checkboxes label {
  display: block;
}
.off-canvas-sidebar-container #checkboxes label:hover {
  background-color: #1e90ff;
}
.off-canvas-sidebar-container #checkboxesBussines {
  display: none;
  border: 1px #dadada solid;
}
.off-canvas-sidebar-container #checkboxesBussines label {
  display: block;
}
.off-canvas-sidebar-container #checkboxesBussines label:hover {
  background-color: #1e90ff;
}

.headline-panel {
  border-radius: 0;
  height: 11.66rem;
}
.headline-panel.carousel {
  padding: 0;
}
.headline-panel.carousel .carousel-inner {
  height: 100%;
}
.headline-panel.carousel .carousel-inner .item {
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("http://placehold.it/350x150");
  height: 100%;
  padding: 1.3333333333rem 1.3333333333rem 0;
  color: white;
}
.headline-panel.carousel .carousel-inner .item > *:not(:first-child) {
  margin-top: 1.3333333333rem;
}
.headline-panel.carousel .carousel-indicators {
  right: 0.8888888889rem;
  left: auto;
  bottom: auto;
  height: auto;
  width: auto;
  top: 50%;
  transform: translateY(-50%);
}
.headline-panel.carousel .carousel-indicators li {
  display: list-item;
  width: 0.4444444444rem;
  height: 0.4444444444rem;
  margin: 0;
}
.headline-panel.carousel .carousel-indicators li.active {
  width: 0.4444444444rem;
  height: 0.4444444444rem;
}
.headline-panel.carousel .carousel-indicators li + li {
  margin: 0.4444444444rem 0 0 0;
}
.headline-panel .left-panel {
  border-right: solid 0.0555555556rem #CCC;
}
.headline-panel .left-panel > .row {
  height: 3.88rem;
  display: flex;
  align-items: center;
}
.headline-panel .left-panel > .row + .row {
  border-top: solid 0.0555555556rem #CCC;
}
.headline-panel .left-panel > .row.row:hover {
  background-color: #F5F5F5;
  cursor: pointer;
}
.headline-panel .left-panel > .row.active .title-3 {
  color: #20a1db;
}
.headline-panel .left-panel > .row > *:first-child {
  padding: 0 0 0 1.3333333333rem;
}
.headline-panel .left-panel > .row > *:last-child {
  padding: 0 1.3333333333rem 0 0;
}
.headline-panel .left-panel .rowt {
  height: 2.88rem;
  display: flex;
  align-items: center;
}
.headline-panel .left-panel .rowt + .rowt {
  border-top: solid 0.0555555556rem #CCC;
}
.headline-panel .left-panel .rowt.rowt:hover {
  background-color: #F5F5F5;
  cursor: pointer;
}
.headline-panel .left-panel .rowt.active .title-3 {
  color: #20a1db;
}
.headline-panel .left-panel .rowt > *:first-child {
  padding: 0 0 0 1.3333333333rem;
}
.headline-panel .left-panel .rowt > *:last-child {
  padding: 0 1.3333333333rem 0 0;
}
.headline-panel .left-panel .sub {
  font-size: 0.7222222222rem;
  color: #7f7f7f;
  color: rgba(0, 0, 0, 0.5);
}
.headline-panel .left-panel .title-3 {
  text-align: right;
}
.headline-panel .right-panel > *:not(.active) {
  display: none;
}

.spread {
  margin-right: 1rem;
}

.card-headline {
  border-bottom: solid 0.0555555556rem #20a1db;
}

.headline-panel-super-short {
  border: solid 0.0555555556rem #20a1db;
  height: 2rem;
}
.headline-panel-super-short.carousel {
  padding: 0;
}
.headline-panel-super-short.carousel .carousel-inner {
  height: 100%;
}
.headline-panel-super-short.carousel .carousel-inner .item {
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("http://placehold.it/350x150");
  height: 100%;
  padding: 1.3333333333rem 1.3333333333rem 0;
  color: white;
}
.headline-panel-super-short.carousel .carousel-inner .item > *:not(:first-child) {
  margin-top: 1.3333333333rem;
}
.headline-panel-super-short.carousel .carousel-indicators {
  right: 0.8888888889rem;
  left: auto;
  bottom: auto;
  height: auto;
  width: auto;
  top: 50%;
  transform: translateY(-50%);
}
.headline-panel-super-short.carousel .carousel-indicators li {
  display: list-item;
  width: 0.4444444444rem;
  height: 0.4444444444rem;
  margin: 0;
}
.headline-panel-super-short.carousel .carousel-indicators li.active {
  width: 0.4444444444rem;
  height: 0.4444444444rem;
}
.headline-panel-super-short.carousel .carousel-indicators li + li {
  margin: 0.4444444444rem 0 0 0;
}
.headline-panel-super-short .left-panel {
  border-right: solid 0.0555555556rem #CCC;
}
.headline-panel-super-short .left-panel > .row {
  height: 3.88rem;
  display: flex;
  align-items: center;
}
.headline-panel-super-short .left-panel > .row + .row {
  border-top: solid 0.0555555556rem #CCC;
}
.headline-panel-super-short .left-panel > .row.row:hover {
  background-color: #F5F5F5;
  cursor: pointer;
}
.headline-panel-super-short .left-panel > .row.active .title-3 {
  color: #20a1db;
}
.headline-panel-super-short .left-panel > .row > *:first-child {
  padding: 0 0 0 1.3333333333rem;
}
.headline-panel-super-short .left-panel > .row > *:last-child {
  padding: 0 1.3333333333rem 0 0;
}
.headline-panel-super-short .left-panel .rowt {
  height: 2.88rem;
  display: flex;
  align-items: center;
}
.headline-panel-super-short .left-panel .rowt + .rowt {
  border-top: solid 0.0555555556rem #CCC;
}
.headline-panel-super-short .left-panel .rowt.rowt:hover {
  background-color: #F5F5F5;
  cursor: pointer;
}
.headline-panel-super-short .left-panel .rowt.active .title-3 {
  color: #20a1db;
}
.headline-panel-super-short .left-panel .rowt > *:first-child {
  padding: 0 0 0 1.3333333333rem;
}
.headline-panel-super-short .left-panel .rowt > *:last-child {
  padding: 0 1.3333333333rem 0 0;
}
.headline-panel-super-short .left-panel .sub {
  font-size: 0.7222222222rem;
  color: #7f7f7f;
  color: rgba(0, 0, 0, 0.5);
}
.headline-panel-super-short .left-panel .title-3 {
  text-align: right;
}
.headline-panel-super-short .right-panel > *:not(.active) {
  display: none;
}

.headline-panel-short {
  border: solid 0.0555555556rem #20a1db;
  height: 9rem;
}
.headline-panel-short.carousel {
  padding: 0;
}
.headline-panel-short.carousel .carousel-inner {
  height: 100%;
}
.headline-panel-short.carousel .carousel-inner .item {
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("http://placehold.it/350x150");
  height: 100%;
  padding: 1.3333333333rem 1.3333333333rem 0;
  color: white;
}
.headline-panel-short.carousel .carousel-inner .item > *:not(:first-child) {
  margin-top: 1.3333333333rem;
}
.headline-panel-short.carousel .carousel-indicators {
  right: 0.8888888889rem;
  left: auto;
  bottom: auto;
  height: auto;
  width: auto;
  top: 50%;
  transform: translateY(-50%);
}
.headline-panel-short.carousel .carousel-indicators li {
  display: list-item;
  width: 0.4444444444rem;
  height: 0.4444444444rem;
  margin: 0;
}
.headline-panel-short.carousel .carousel-indicators li.active {
  width: 0.4444444444rem;
  height: 0.4444444444rem;
}
.headline-panel-short.carousel .carousel-indicators li + li {
  margin: 0.4444444444rem 0 0 0;
}
.headline-panel-short .left-panel {
  border-right: solid 0.0555555556rem #CCC;
}
.headline-panel-short .left-panel > .row {
  height: 3.88rem;
  display: flex;
  align-items: center;
}
.headline-panel-short .left-panel > .row + .row {
  border-top: solid 0.0555555556rem #CCC;
}
.headline-panel-short .left-panel > .row.row:hover {
  background-color: #F5F5F5;
  cursor: pointer;
}
.headline-panel-short .left-panel > .row.active .title-3 {
  color: #20a1db;
}
.headline-panel-short .left-panel > .row > *:first-child {
  padding: 0 0 0 1.3333333333rem;
}
.headline-panel-short .left-panel > .row > *:last-child {
  padding: 0 1.3333333333rem 0 0;
}
.headline-panel-short .left-panel .rowt {
  height: 2.88rem;
  display: flex;
  align-items: center;
}
.headline-panel-short .left-panel .rowt + .rowt {
  border-top: solid 0.0555555556rem #CCC;
}
.headline-panel-short .left-panel .rowt.rowt:hover {
  background-color: #F5F5F5;
  cursor: pointer;
}
.headline-panel-short .left-panel .rowt.active .title-3 {
  color: #20a1db;
}
.headline-panel-short .left-panel .rowt > *:first-child {
  padding: 0 0 0 1.3333333333rem;
}
.headline-panel-short .left-panel .rowt > *:last-child {
  padding: 0 1.3333333333rem 0 0;
}
.headline-panel-short .left-panel .sub {
  font-size: 0.7222222222rem;
  color: #7f7f7f;
  color: rgba(0, 0, 0, 0.5);
}
.headline-panel-short .left-panel .title-3 {
  text-align: right;
}
.headline-panel-short .right-panel > *:not(.active) {
  display: none;
}

.headline-panel-tall {
  overflow: hidden;
  border: solid 0.0555555556rem #20a1db;
  height: 18rem;
}
.headline-panel-tall.carousel {
  padding: 0;
}
.headline-panel-tall.carousel .carousel-inner {
  height: 100%;
}
.headline-panel-tall.carousel .carousel-inner .item {
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("http://placehold.it/350x150");
  height: 100%;
  padding: 1.3333333333rem 1.3333333333rem 0;
  color: white;
}
.headline-panel-tall.carousel .carousel-inner .item > *:not(:first-child) {
  margin-top: 1.3333333333rem;
}
.headline-panel-tall.carousel .carousel-indicators {
  right: 0.8888888889rem;
  left: auto;
  bottom: auto;
  height: auto;
  width: auto;
  top: 50%;
  transform: translateY(-50%);
}
.headline-panel-tall.carousel .carousel-indicators li {
  display: list-item;
  width: 0.4444444444rem;
  height: 0.4444444444rem;
  margin: 0;
}
.headline-panel-tall.carousel .carousel-indicators li.active {
  width: 0.4444444444rem;
  height: 0.4444444444rem;
}
.headline-panel-tall.carousel .carousel-indicators li + li {
  margin: 0.4444444444rem 0 0 0;
}
.headline-panel-tall .left-panel {
  border-right: solid 0.0555555556rem #CCC;
}
.headline-panel-tall .left-panel > .row {
  height: 3.88rem;
  display: flex;
  align-items: center;
}
.headline-panel-tall .left-panel > .row + .row {
  border-top: solid 0.0555555556rem #CCC;
}
.headline-panel-tall .left-panel > .row.row:hover {
  background-color: #F5F5F5;
  cursor: pointer;
}
.headline-panel-tall .left-panel > .row.active .title-3 {
  color: #20a1db;
}
.headline-panel-tall .left-panel > .row > *:first-child {
  padding: 0 0 0 1.3333333333rem;
}
.headline-panel-tall .left-panel > .row > *:last-child {
  padding: 0 1.3333333333rem 0 0;
}
.headline-panel-tall .left-panel .rowt {
  height: 2.88rem;
  display: flex;
  align-items: center;
}
.headline-panel-tall .left-panel .rowt + .rowt {
  border-top: solid 0.0555555556rem #CCC;
}
.headline-panel-tall .left-panel .rowt.rowt:hover {
  background-color: #F5F5F5;
  cursor: pointer;
}
.headline-panel-tall .left-panel .rowt.active .title-3 {
  color: #20a1db;
}
.headline-panel-tall .left-panel .rowt > *:first-child {
  padding: 0 0 0 1.3333333333rem;
}
.headline-panel-tall .left-panel .rowt > *:last-child {
  padding: 0 1.3333333333rem 0 0;
}
.headline-panel-tall .left-panel .sub {
  font-size: 0.7222222222rem;
  color: #7f7f7f;
  color: rgba(0, 0, 0, 0.5);
}
.headline-panel-tall .left-panel .title-3 {
  text-align: right;
}
.headline-panel-tall .right-panel > *:not(.active) {
  display: none;
}

#freight-spend-chart,
#audit-savings-chart,
#optimization-savings-chart {
  padding-top: 18px;
}

.panel {
  margin: 0px 0 0px 0;
}

.panel-spread-right {
  margin-left: auto;
  margin-right: auto;
  width: 150px;
  overflow: hidden;
}

.panel-spread-middle {
  margin-left: auto;
  margin-right: auto;
  width: 150px;
  overflow: hidden;
}

.panel-spread-middle-right {
  margin-left: auto;
  margin-right: auto;
  width: 150px;
  overflow: hidden;
}

.panel-spread-left {
  margin-left: auto;
  margin-right: auto;
  width: 150px;
  overflow: hidden;
}

.panel-padding {
  padding: 14px !important;
}

.panel-no-padding {
  padding: 0 !important;
}

/*
//  **************
//  **************
//  Package Search Interface
//  (Input box below left sidebar)
//  **************
*/
.package-detail-table-title {
  font-size: 3rem;
  margin-top: 15px;
  margin-bottom: 15px;
}

.delivery-details, .address-details, .delivery-confirmation {
  border: 1px solid black;
  border-radius: 15px;
  height: 140px;
  padding: 10px;
}

.delivery-confirmation {
  height: 100px;
}

.left-side {
  width: 50%;
  text-align: left;
}

.right-side {
  width: 50%;
  text-align: right;
}

.package-search-button {
  outline: none !important;
}

.package-search-button:hover, .package-search-button:active {
  color: white;
  background-color: #173f6c;
}

.package-search-button:focus {
  border-color: rgba(82, 168, 236, 0.8);
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(82, 168, 236, 0.6);
  -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(82, 168, 236, 0.6);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(82, 168, 236, 0.6);
  outline: 1;
  outline: thin dotted \9 ;
}

.package-search-active {
  border-color: rgba(82, 168, 236, 0.8);
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(82, 168, 236, 0.6);
  -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(82, 168, 236, 0.6);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(82, 168, 236, 0.6);
  outline: 0;
  outline: thin dotted \9 ;
  color: white;
  background-color: #173f6c;
}

/*
//	**************
//	**************
//	Login page CSS
//	**************
//	**************
*/
.panel-heading {
  padding: 0.7777777778rem 1.3333333333rem;
}

.panel-body {
  padding: 0 1.3333333333rem 0.8888888889rem;
}

.login-page-body {
  height: 100%;
  background: url("/img/login_background.jpg") center no-repeat;
  background-size: cover;
}
.login-page-body .left-panel {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  height: 100%;
  padding-left: 5rem;
}
.login-page-body .left-panel > * {
  margin: 1rem 0;
}
.login-page-body .left-panel h2 {
  color: white;
}
.login-page-body .left-panel p {
  color: #CCC;
}
.login-page-body .left-panel .button {
  display: flex;
}
.login-page-body .right-panel {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
}
.login-page-body .right-panel .form-group .form-control, .login-page-body .right-panel .button.large {
  font-size: 0.8889rem;
}
.login-page-body .middle-panel {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
}
.login-page-body .middle-panel .form-group .form-control, .login-page-body .middle-panel .button.large {
  font-size: 0.8889rem;
}
.login-page-body .legal {
  display: flex;
  width: 100%;
  justify-content: center;
  position: absolute;
  bottom: 0;
  color: white;
  margin-bottom: 0.5rem;
}
.login-page-body .legal > a {
  color: #B5BFCC;
  padding: 0 0.5rem;
}
.login-page-body .legal > a:hover {
  color: #ffffff;
}

.upper-side-content {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}

.side-content {
  margin-left: auto;
  margin-right: auto;
  position: relative;
  top: 50%;
  transform: translate(0, -50%);
}

.request-demo-btn {
  background-color: #20a1db;
  border: none;
  border-radius: 0;
  color: white;
  margin-top: 10%;
  font-size: 2.3rem;
}

.forgot-password-div {
  text-align: left;
  display: flex;
}
.forgot-password-div span {
  float: left;
}
.forgot-password-div span input {
  float: left;
  margin-right: 0.4rem;
}
.forgot-password-div a {
  float: right;
}

.no-panel-body-shadow {
  box-shadow: none !important;
}

.forgot-password-text:hover {
  font-weight: 500;
}

.forgot-password-text {
  font-weight: 100;
  font-size: 1.25rem;
  color: rgb(81, 81, 81);
}

.login-box {
  background-color: white;
  position: relative;
}

.panel-default > .login-panel-heading {
  background-color: white;
  border-color: white;
  text-align: center;
}
.panel-default > .login-panel-heading img {
  margin-top: 1.4444444444rem;
  width: 13.8888888889rem;
}

.login-panel {
  margin: 0;
  max-width: 340px;
}

.login-button {
  background-color: RGBA(0, 153, 206, 1);
  border: 0.01625rem solid RGBA(0, 153, 206, 1);
  margin-top: 2.5rem;
}

.login-button:hover {
  opacity: 0.8;
  background-color: RGBA(0, 153, 206, 1);
  border: 0.01625 solid RGBA(0, 153, 206, 1);
}

.login-button:active {
  background-color: RGBA(0, 153, 206, 1);
  opacity: 0.7;
}

.reset-pass-modal {
  text-align: center;
  padding: 0;
  z-index: 100011;
}
.reset-pass-modal:before {
  content: "";
  display: inline-block;
  height: 100%;
  vertical-align: middle;
  margin-right: -0.25rem;
}
.reset-pass-modal .button {
  display: flex;
  text-align: center;
}
.reset-pass-modal .modal-header .close {
  transform: translate(0, -0.4375rem);
}
.reset-pass-modal .modal-dialog {
  display: inline-block;
  text-align: left;
  vertical-align: middle;
  width: 15%;
  margin: auto;
  min-width: 22.5rem;
  min-height: 18.75rem;
}
.reset-pass-modal .modal-dialog .modal-content {
  border-radius: 0;
}
.reset-pass-modal .modal-footer {
  text-align: center;
}

.reset-email-prompt-text {
  font-size: larger;
  padding: 0.625rem 0.625rem 1.25rem;
  line-height: 1.1rem;
}

.reset-success {
  border: 0.01625rem solid #66af10;
  font-size: 0.8888888889rem;
  padding: 1.3333333333rem;
  color: #66af10;
  background: #e8fedb;
  background: rgba(102, 175, 16, 0.15);
  margin-bottom: 1rem;
}

.reset-failure {
  border: 0.01625rem solid #d91a1a;
  font-size: 0.8888888889rem;
  padding: 1.3333333333rem;
  color: #d91a1a;
  background: #fadcdc;
  background: rgba(217, 26, 26, 0.15);
  margin-bottom: 1rem;
}

#forgot-message {
  font-size: 0.75rem;
  padding: 0.75rem;
}

.shipware-button {
  width: 100%;
  border: 0;
  border-radius: 0;
  font-size: 1rem;
}

.shipware-button.submit-btn {
  background-color: rgba(0, 0, 0, 0.99);
  color: white;
}
.shipware-button.submit-btn:hover, .shipware-button.submit-btn:active, .shipware-button.submit-btn:focus, .shipware-button.submit-btn:active:focus {
  background-color: #112f51;
  color: white;
}

.shipware-button.cancel-btn {
  background-color: transparent;
  color: #20a1db;
  margin-top: 8px;
}
.shipware-button.cancel-btn:hover, .shipware-button.cancel-btn:active, .shipware-button.cancel-btn:focus, .shipware-button.cancel-btn:active:focus {
  color: #1c8cbf;
  background: rgba(32, 161, 219, 0.12);
}

.remember_me_checkbox_div {
  position: relative;
  display: inline-flex;
}

.remember_me_checkbox_div label {
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  border: 0.01625rem solid #ccc;
  margin-bottom: 0;
  width: 1rem;
  height: 1rem;
  margin-top: 0.125rem;
}

.remember_me_checkbox_div label:hover::after {
  opacity: 0.5;
}

/**
 * Create the checkbox state for the tick
 */
.remember_me_checkbox_div input[type=checkbox]:checked + label {
  background-color: #20a1db;
}

.remember_me_checkbox_div input[type=checkbox]:checked + label:after {
  content: "";
  position: absolute;
  width: 0.625rem;
  height: 0.3125rem;
  background: transparent;
  top: 0.1875rem;
  left: 0.125rem;
  border: 0.125rem solid #fff;
  border-top: none;
  border-right: none;
  transform: rotate(-45deg);
  opacity: 1;
}

.remember_me_label {
  width: 6.25rem;
  display: inline-flex;
  margin-left: 1.4444rem;
  margin-right: 2rem;
}

.remember_me_checkbox {
  display: none;
}

#terms-modal .modal-header,
#terms-modal .modal-footer {
  border-bottom: none;
  border-top: none;
  color: #0099ce;
  color: rgb(0, 153, 206);
}
#terms-modal .modal-footer {
  text-align: right;
}
#terms-modal .modal-dialog {
  width: 40%;
}
#terms-modal .modal-body {
  overflow-y: auto;
  height: 65vh;
  padding-top: 0;
}

.has-feedback label ~ .form-control-feedback {
  top: 31px;
}

#place-holder-gray-email ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #999 !important;
  opacity: 1 !important; /* Firefox */
}
#place-holder-gray-email :-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #999 !important;
}
#place-holder-gray-email ::-ms-input-placeholder { /* Microsoft Edge */
  color: #999 !important;
}

#place-holder-gray-password ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #999 !important;
  opacity: 1 !important; /* Firefox */
}
#place-holder-gray-password :-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #999 !important;
}
#place-holder-gray-password ::-ms-input-placeholder { /* Microsoft Edge */
  color: #999 !important;
}

/*
//	***********************
//	***********************
//	Reset Password page CSS
//	***********************
//	***********************
*/
.info-icon {
  color: #CCC;
  pointer-events: initial;
  cursor: pointer;
}

#reset-password-form {
  display: flex;
  flex-direction: column;
  align-items: center;
}
#reset-password-form * {
  width: 100%;
}
#reset-password-form .button {
  margin-top: 8px;
}

table.table {
  background-color: white;
}
table.table > tbody > tr:hover {
  background: #20A1DB;
  color: #fff;
}
table.table > thead {
  font-size: 0.7777777778rem;
}
table.table > tbody {
  font-size: 0.8888888889rem;
}
table.table > thead > tr, table.table > tbody > tr {
  height: 2.6666666667rem;
  text-align: right;
}
table.table > thead > tr > th, table.table > thead > tr > td, table.table > tbody > tr > th, table.table > tbody > tr > td {
  border-top: solid 0.0555555556rem #dfdfdf;
  border-bottom: none;
  padding: 0;
  vertical-align: middle;
  cursor: pointer !important;
  text-align: right;
  padding-right: 2rem;
}
table.table > thead > tr > th:first-child, table.table > thead > tr > td:first-child, table.table > tbody > tr > th:first-child, table.table > tbody > tr > td:first-child {
  padding-left: 1.3333333333rem;
  cursor: pointer !important;
}
table.table > thead > tr > th, table.table > tbody > tr > th {
  color: #888888;
  color: rgba(0, 0, 0, 0.5);
}
table.table > thead > tr > th .custom-dropdown-submenu label, table.table > tbody > tr > th .custom-dropdown-submenu label {
  color: rgba(0, 0, 0, 0.87);
}
table.table > thead > tr > th .custom-dropdown-submenu label.disabled, table.table > tbody > tr > th .custom-dropdown-submenu label.disabled {
  color: #CCC;
  pointer-events: none;
}

.contract-first-column-width {
  width: 55% !important;
}

.contract-sec-third-column-width {
  width: 12% !important;
}

.contract-fourth-column-width {
  width: 15% !important;
}

#calculatorMinimumChargeImpact .table > thead > tr > th, #service_a_table_data .table > tbody > tr > th {
  padding-right: 0 !important;
}

.result-item .coresults-upload-complete.material-icons, .result-item .coresults-upload-failed.material-icons, .result-item .coresults-upload-in-process.material-icons {
  display: table-cell;
}

.no-select-datalist {
  cursor: default !important;
  background-color: white !important;
  color: black !important;
}

table.table > tbody > tr.no-select-datalist > td {
  cursor: default !important;
}
table.table > tbody > tr.no-select-datalist > td:first-child {
  cursor: default !important;
}

.sorting_disabled {
  width: 85px !important;
}

.textcolumn-left {
  text-align: left !important;
}

.textcolumn-center {
  text-align: center !important;
}

.table > thead:first-child > tr:first-child > th.select-column-finder {
  padding: 0 !important;
  width: 20px !important;
  text-align: center !important;
}

.select-column-finder {
  padding: 0 !important;
  width: 27px !important;
  text-align: right !important;
}

.search-icon-column {
  padding: 10px !important;
  width: 40px !important;
  text-align: center !important;
}

.company-id-width {
  width: 40%;
}

.company-name-width {
  width: 40%;
}

.users tr:hover td > span.user-company-count {
  color: #fff !important;
}
.users tbody > tr > td > span.user-company-count {
  text-decoration: underline;
  color: #20a1db;
}
.users .tooltip.in {
  opacity: 1 !important;
  filter: alpha(opacity=100);
}
.users .tooltip-inner {
  max-width: fit-content !important;
  background-color: #fff !important;
  color: #000;
  font-size: 14px !important;
}
.users .tooltip-inner div {
  margin-top: 10px;
  margin-bottom: 10px;
}
.users .tooltip.right .tooltip-arrow {
  border-right-color: #fff;
}

#record-count {
  color: #20a1db;
}

.invoice-container {
  margin-left: -1rem;
}

.package-detail-table-title {
  margin-top: 30px;
}

.button.flat.sidebar-btn-close {
  color: rgba(0, 0, 0, 0.99);
}

#charge-summary-part .details-block table {
  background: rgb(238, 238, 238);
}
#charge-summary-part .details-block .details-header {
  padding-left: 1.333rem;
}
#charge-summary-part .invoice-category-header {
  font-weight: 700;
  page-break-before: always;
}
#charge-summary-part .invoice-subcategory {
  padding-left: 32px;
  color: #a7a7a7;
}
#charge-summary-part .invoice-sub-subcategory {
  vertical-align: bottom;
  color: rgba(0, 0, 0, 0.99);
  padding-left: 42px;
}
#charge-summary-part .invoice-item {
  padding-left: 62px;
}
#charge-summary-part #result-table-charges {
  width: calc(100% + 60px);
  max-width: calc(100% + 60px);
  margin-bottom: 0;
}
#charge-summary-part #result-table-charges tr {
  color: black;
}
#charge-summary-part #result-table-charges tr > td, #charge-summary-part #result-table-charges tr th {
  cursor: default !important;
  border: none !important;
  padding-right: 1rem !important;
}
#charge-summary-part #result-table-charges tr > td:first-child, #charge-summary-part #result-table-charges tr th:first-child {
  text-align: left;
}
#charge-summary-part #result-table-charges tr:hover {
  background: rgb(238, 238, 238);
}
#charge-summary-part #result-table-charges .color-blue {
  color: #20A1DB;
}
#charge-summary-part #result-table-charges .charge-summary-total {
  color: black;
  font-weight: 700;
}
#charge-summary-part #result-table-charges .charge-summary-total > td {
  border-top: 0.05556rem solid #dfdfdf !important;
}

@media screen and (max-width: 1440px) {
  #charge-summary-part .details-block .details-header {
    padding-left: 0.5rem;
  }
}
/*
//	**************
//	**************
//	Datepicker CSS
//	**************
//	**************
*/
.date-picker {
  margin-left: 80px;
  display: inline-flex;
}
.date-picker > .flex {
  align-items: center;
}
.date-picker .selected-week {
  width: 6rem;
}
.date-picker .selected-week.active {
  background: #ddf0f9;
  background: rgba(32, 161, 219, 0.12);
  border: solid #20a1db 0.01625rem;
}

.ui-datepicker {
  padding: 0;
  width: 12.5rem;
  box-shadow: 0.01625rem 0.01625rem 0.3125rem;
}
.ui-datepicker > .ui-datepicker-header {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  background-color: #20a1db;
  border: none;
  color: white;
  font-weight: normal;
}
.ui-datepicker > .ui-datepicker-header .ui-datepicker-next, .ui-datepicker > .ui-datepicker-header .ui-datepicker-prev {
  display: flex;
  align-items: center;
  height: 100%;
  top: auto;
  justify-content: center;
}
.ui-datepicker > .ui-datepicker-header .ui-datepicker-next span, .ui-datepicker > .ui-datepicker-header .ui-datepicker-prev span {
  top: auto;
  left: auto;
  margin: auto;
  background-image: none;
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  font-size: 1.5rem;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
  text-indent: 0;
  width: auto;
  height: auto;
  transform: translateY(-10%);
  color: white;
  cursor: pointer;
  position: relative;
  font-feature-settings: "liga" 1;
}
.ui-datepicker > .ui-datepicker-header .ui-state-hover {
  color: white;
  background-color: transparent;
  top: 2px;
  border: none;
  background-image: none;
}
.ui-datepicker > .ui-datepicker-header .ui-state-hover.ui-datepicker-prev {
  left: 2px;
}
.ui-datepicker > .ui-datepicker-header .ui-state-hover.ui-datepicker-next {
  right: 2px;
}
.ui-datepicker > .ui-datepicker-calendar th {
  color: #7f7f7f;
  color: rgba(0, 0, 0, 0.5);
}
.ui-datepicker > .ui-datepicker-calendar th:first-child, .ui-datepicker > .ui-datepicker-calendar td:first-child {
  padding-left: 0.7rem;
}
.ui-datepicker > .ui-datepicker-calendar th:last-child, .ui-datepicker > .ui-datepicker-calendar td:last-child {
  padding-right: 0.7rem;
}
.ui-datepicker > .ui-datepicker-calendar .ui-datepicker-selected-week {
  opacity: 1;
}
.ui-datepicker > .ui-datepicker-calendar .ui-datepicker-selected-week > .ui-state-default {
  color: #20a1db;
}
.ui-datepicker > .ui-datepicker-calendar .ui-datepicker-selected-week.ui-datepicker-current-day > .ui-state-default {
  background: #20a1db;
  color: white;
}
.ui-datepicker > .ui-datepicker-calendar .ui-state-default {
  background: transparent;
  border: none;
  text-align: center;
  border-radius: 50%;
}
.ui-datepicker > .ui-datepicker-calendar .ui-state-active {
  color: initial;
}

.ui-datepicker table {
  font-size: 0.8888888889rem;
}

.selected-week.hasDatepicker.disabled {
  color: #848484;
}

.date-range-on-search-page {
  margin-right: 60px;
  margin-top: 64px;
}

@media (max-width: 1440px) {
  .date-range-on-search-page {
    margin-right: 300px;
  }
}
.search-modal-content {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  padding: 2rem;
  height: 1400px;
  margin-top: 10vh;
}

.account-number-value, .carrier-value, .date-value,
.numberOfPackages-value, .spend-per-package-value, .savings-per-package-value,
.invoice-number-value, .service-type-value, .zone-value, .weight-value,
.weight-per-package-value, .total-weight-value {
  font-weight: 900;
  font-size: 2rem;
}

@media (min-width: 992px) {
  .account-number-value, .carrier-value, .date-value,
  .numberOfPackages-value, .spend-per-package-value, .savings-per-package-value,
  .invoice-number-value, .service-type-value, .zone-value, .weight-value,
  .weight-per-package-value, .total-weight-value {
    font-weight: 900;
    font-size: 1.3rem;
  }
  .search-modal-content {
    font-size: 1.2rem;
  }
}
@media (min-width: 1400px) {
  .account-number-value, .carrier-value, .date-value,
  .numberOfPackages-value, .spend-per-package-value, .savings-per-package-value,
  .invoice-number-value, .service-type-value, .zone-value, .weight-value,
  .weight-per-package-value, .total-weight-value {
    font-weight: 900;
    font-size: 2rem;
  }
  .search-modal-content {
    font-size: 1.8rem;
  }
}
.delivery-confirmation {
  padding-top: 25px;
}

.delivery-value {
  font-weight: 900;
  font-size: 2.2rem;
}

.search-submit-btn:hover {
  background-color: #173f6c;
  color: white;
}

.package-detail-table-title {
  font-size: 2rem;
}

.total-spend-sub-left {
  text-align: left;
  margin-top: 15px;
}

.total-spend-sub-right {
  text-align: right;
  margin-top: 15px;
}

.total-spend-sub-left span {
  font-size: 1.4rem;
  padding-left: 70px;
}

.total-spend-sub-right span {
  font-size: 1.4rem;
  padding-right: 70px;
}

.value-title {
  font-size: 1.75rem;
  line-height: 0;
  font-weight: 900;
}

.total-spend-value, .discount-value, .original-spend-value {
  font-size: 1.75rem;
  line-height: 0;
  font-weight: 900;
}

.search-box {
  margin-top: 15vh;
  height: 400px;
  border: 1px solid grey;
  border-radius: 15px;
  background-color: #173f6c;
  box-shadow: 0 3px 4px 0 rgba(166, 166, 166, 0.5);
}

.search-box-content {
  padding: 7rem 10px 10px;
  text-align: center;
}

.search-box-content h4 {
  color: #DEDEDE;
  margin-bottom: 20px;
}

@media (min-width: 992px) {
  .search-modal-content {
    height: 750px;
  }
}
.invoice-id-text-box, .package-id-text-box {
  border: 1px solid black;
  border-radius: 15px;
  padding-top: 10px;
  padding-bottom: 10px;
  width: 50%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 30px;
}

.search-modal-btn {
  opacity: 0.25;
  height: 50px;
  border: 1px solid black;
  border-radius: 15px;
  padding-top: 14px;
}

.start-new-search-btn:hover {
  opacity: 1;
}

.search-modal-btn:hover span.dl-pdf {
  display: none;
}

.download-pdf-btn:hover:after {
  content: "Coming Soon!";
}

.invoice-id-text, .package-id-text {
  font-size: 1.4rem;
  font-weight: 900;
  line-height: 0.75;
}

@media (min-width: 800px) {
  .invoice-id-text, .package-id-text {
    font-size: 1.85rem;
  }
}
@media (min-width: 1300px) {
  .invoice-id-text, .package-id-text {
    font-size: 2.8rem;
  }
}
.right-side-invoice-content {
  border: 1px solid grey;
  border-radius: 15px;
  padding: 5px;
  height: 575px;
}

.left-side-invoice-content {
  border: 1px solid grey;
  border-radius: 15px;
  padding: 5px;
  height: 275px;
  margin-bottom: 25px;
}

.chart-result-icon {
  color: #173F6C;
}

.package-result-icon {
  color: #998465;
}

.invoice-result-icon {
  color: #66AF10;
}

.highlight-when-clicked {
  cursor: pointer !important;
}

.highlight-when-clicked:active {
  background-color: #20a1db;
}

#result-table {
  margin-bottom: 0;
}

.options-column {
  text-align: center;
}
.options-column .dropdown.open .material-icons {
  cursor: pointer;
  background: rgba(0, 0, 0, 0.99);
}
.options-column .dropdown.open span {
  vertical-align: text-top;
  line-height: 1.7;
}
.options-column .dropdown.open .disabled {
  color: #CCC;
  pointer-events: none;
}
.options-column .material-icons {
  cursor: pointer;
}
.options-column .material-icons:active, .options-column .material-icons:focus {
  background: rgba(0, 0, 0, 0.99);
}

.dropdown .always-visible-block {
  display: block;
}
.dropdown .dropdown-menu .disabled {
  color: #CCC;
  pointer-events: none;
}
.dropdown .dropdown-menu .disabled .dropdown-item {
  color: #CCC;
  pointer-events: none;
}
.dropdown .disabled {
  color: #CCC;
  pointer-events: none;
}

.dt-checkboxes-cell {
  padding: 0 !important;
}
.dt-checkboxes-cell .dt-checkboxes {
  width: 15px;
}

#search-selected-rows-export-csv {
  border-top: 1px solid #dfdfdf;
}

.tooltip-search {
  position: relative;
  display: inline-block;
}

.tooltip-search .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: #333333;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  width: 100%;
}

.tooltip-search:hover .tooltiptext {
  visibility: visible;
  visibility: visible;
  position: absolute;
  left: -166px;
  width: 100%;
  white-space: normal;
  padding: 5px;
  top: -34px;
  background: #959292;
}

/*
//	***********************
//	***********************
//	Settings page CSS
//	***********************
//	***********************
*/
.card-profile {
  margin-top: 4.5rem;
}

.card-security {
  margin-top: 4.5rem;
}

.settings-box {
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem 0.5rem 0;
  position: relative;
}

.settings-field-label {
  text-align: right;
  padding: 0.5rem 1rem 0.5rem 0;
  font-weight: normal;
  font-family: "Roboto Regular", sans-serif;
  font-size: 0.7777777778rem;
  white-space: nowrap;
  width: 9rem;
}
.settings-field-label.required:after {
  content: " *";
  color: #d91a1a;
}

.settings-field-value {
  padding: 0.5rem 1rem;
  font-weight: normal;
  font-family: "Roboto Regular", sans-serif;
  font-size: 0.8888888889rem;
  max-width: 550px;
}

#securityForm .settings-field-label {
  width: 16rem;
}

#securityForm .forgot-password-by-email {
  font-size: 0.77778rem;
  padding-left: 0;
  border: none;
  margin-top: 0.5rem;
}

table.DTCR_clonedTable.dataTable {
  position: absolute !important;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 202;
}

div.DTCR_pointer {
  width: 1px;
  background-color: #0259C4;
  z-index: 201;
}

table.dataTable {
  clear: both;
  max-width: none !important;
  border-collapse: separate !important;
}
table.dataTable tr.active {
  font-weight: bold;
}
table.dataTable tr.active > td {
  background-color: transparent;
}
table.dataTable td, table.dataTable th {
  box-sizing: content-box;
}
table.dataTable td.dataTables_empty, table.dataTable th.dataTables_empty {
  text-align: center;
}
table.dataTable.nowrap th, table.dataTable.nowrap td {
  white-space: nowrap;
}

div.dataTables_wrapper div.dataTables_length label {
  font-weight: normal;
  text-align: left;
  white-space: nowrap;
}
div.dataTables_wrapper div.dataTables_length select {
  width: 75px;
  display: inline-block;
}
div.dataTables_wrapper div.dataTables_filter {
  text-align: right;
}
div.dataTables_wrapper div.dataTables_filter label {
  font-weight: normal;
  white-space: nowrap;
  text-align: left;
}
div.dataTables_wrapper div.dataTables_filter input {
  margin-left: 0.5em;
  display: inline-block;
  width: auto;
}
div.dataTables_wrapper div.dataTables_info {
  padding-top: 8px;
  white-space: nowrap;
}
div.dataTables_wrapper div.dataTables_paginate {
  margin: 0;
  white-space: nowrap;
  text-align: right;
}
div.dataTables_wrapper div.dataTables_paginate ul.pagination {
  margin: 2px 0;
  white-space: nowrap;
}
div.dataTables_wrapper div.dataTables_processing {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 200px;
  margin-left: -100px;
  margin-top: -26px;
  text-align: center;
  padding: 1em 0;
}

table.dataTable thead > tr > th.sorting_asc, table.dataTable thead > tr > th.sorting_desc, table.dataTable thead > tr > th.sorting {
  cursor: pointer !important;
  text-align: right;
}
table.dataTable thead > tr > td.sorting_asc, table.dataTable thead > tr > td.sorting_desc, table.dataTable thead > tr > td.sorting {
  padding-right: 30px;
  cursor: pointer !important;
}
table.dataTable thead > tr > th:active, table.dataTable thead > tr > td:active {
  outline: none;
}
table.dataTable thead .sorting, table.dataTable thead .sorting_asc, table.dataTable thead .sorting_desc, table.dataTable thead .sorting_asc_disabled, table.dataTable thead .sorting_desc_disabled {
  cursor: pointer;
  position: relative;
}
table.dataTable thead .sorting_asc::before {
  content: "\f0de";
  font-family: fontawesome;
  padding-right: 0.5em;
}
table.dataTable thead .sorting_desc::before {
  content: "\f0dd";
  font-family: fontawesome;
  padding-right: 0.5em;
}
table.dataTable thead .sorting::before {
  opacity: 0.2;
  content: "\f0dc";
  font-family: fontawesome;
  padding-right: 0.5em;
}
table.dataTable thead .sorting::after {
  display: none;
}
table.dataTable thead .sorting_asc::after {
  display: none;
}
table.dataTable thead .sorting_desc::after {
  display: none;
}

div.dataTables_scrollHead table.dataTable {
  margin-bottom: 0 !important;
}
div.dataTables_scrollBody table {
  border-top: none;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
div.dataTables_scrollBody table thead .sorting:after, div.dataTables_scrollBody table thead .sorting_asc:after, div.dataTables_scrollBody table thead .sorting_desc:after {
  display: none;
}
div.dataTables_scrollBody table tbody tr:first-child th, div.dataTables_scrollBody table tbody tr:first-child td {
  border-top: none;
}
div.dataTables_scrollFoot table {
  margin-top: 0 !important;
  border-top: none;
}

@media screen and (max-width: 767px) {
  div.dataTables_wrapper div.dataTables_length, div.dataTables_wrapper div.dataTables_filter, div.dataTables_wrapper div.dataTables_info, div.dataTables_wrapper div.dataTables_paginate {
    text-align: center;
  }
}
table.dataTable.table-condensed > thead > tr > th {
  padding-right: 20px;
}
table.dataTable.table-condensed .sorting:after, table.dataTable.table-condensed .sorting_asc:after, table.dataTable.table-condensed .sorting_desc:after {
  top: 6px;
  right: 6px;
}
table.table-bordered.dataTable th, table.table-bordered.dataTable td {
  border-left-width: 0;
}
table.table-bordered.dataTable th:last-child, table.table-bordered.dataTable td:last-child {
  border-right-width: 0;
}
table.table-bordered.dataTable tbody th, table.table-bordered.dataTable tbody td {
  border-bottom-width: 0;
}

div.dataTables_scrollHead table.table-bordered {
  border-bottom-width: 0;
}
div.table-responsive > div.dataTables_wrapper > div.row {
  margin: 0;
}
div.table-responsive > div.dataTables_wrapper > div.row > div[class^=col-]:first-child {
  padding-left: 0;
}
div.table-responsive > div.dataTables_wrapper > div.row > div[class^=col-]:last-child {
  padding-right: 0;
}

table.dataTable.dtr-inline.collapsed > tbody > tr > td.child, table.dataTable.dtr-inline.collapsed > tbody > tr > th.child {
  cursor: default !important;
}
table.dataTable.dtr-inline.collapsed > tbody > tr > td.dataTables_empty {
  cursor: default !important;
}
table.dataTable.dtr-inline.collapsed > tbody > tr > td.child:before {
  display: none !important;
}
table.dataTable.dtr-inline.collapsed > tbody > tr > th.child:before {
  display: none !important;
}
table.dataTable.dtr-inline.collapsed > tbody > tr > td.dataTables_empty:before {
  display: none !important;
}
table.dataTable.dtr-inline.collapsed > tbody > tr > td:first-child {
  position: relative;
  padding-left: 30px;
  cursor: pointer;
}
table.dataTable.dtr-inline.collapsed > tbody > tr > th:first-child {
  position: relative;
  padding-left: 30px;
  cursor: pointer;
}
table.dataTable.dtr-inline.collapsed > tbody > tr > td:first-child:before, table.dataTable.dtr-inline.collapsed > tbody > tr > th:first-child:before {
  top: 9px;
  left: 4px;
  height: 14px;
  width: 14px;
  display: block;
  position: absolute;
  color: white;
  border: 2px solid white;
  border-radius: 14px;
  box-shadow: 0 0 3px #444;
  box-sizing: content-box;
  text-align: center;
  font-family: "Courier New", Courier, monospace;
  line-height: 14px;
  content: "+";
  background-color: #31b131;
}
table.dataTable.dtr-inline.collapsed > tbody > tr.parent > td:first-child:before, table.dataTable.dtr-inline.collapsed > tbody > tr.parent > th:first-child:before {
  content: "-";
  background-color: #d33333;
}
table.dataTable.dtr-inline.collapsed > tbody > tr.child td:before {
  display: none;
}
table.dataTable.dtr-inline.collapsed.compact > tbody > tr > td:first-child, table.dataTable.dtr-inline.collapsed.compact > tbody > tr > th:first-child {
  padding-left: 27px;
}
table.dataTable.dtr-inline.collapsed.compact > tbody > tr > td:first-child:before, table.dataTable.dtr-inline.collapsed.compact > tbody > tr > th:first-child:before {
  top: 5px;
  left: 4px;
  height: 14px;
  width: 14px;
  border-radius: 14px;
  line-height: 14px;
  text-indent: 3px;
}
table.dataTable.dtr-column > tbody > tr > td.control, table.dataTable.dtr-column > tbody > tr > th.control {
  position: relative;
  cursor: pointer;
}
table.dataTable.dtr-column > tbody > tr > td.control:before, table.dataTable.dtr-column > tbody > tr > th.control:before {
  top: 50%;
  left: 50%;
  height: 16px;
  width: 16px;
  margin-top: -10px;
  margin-left: -10px;
  display: block;
  position: absolute;
  color: white;
  border: 2px solid white;
  border-radius: 14px;
  box-shadow: 0 0 3px #444;
  box-sizing: content-box;
  text-align: center;
  font-family: "Courier New", Courier, monospace;
  line-height: 14px;
  content: "+";
  background-color: #31b131;
}
table.dataTable.dtr-column > tbody > tr.parent td.control:before, table.dataTable.dtr-column > tbody > tr.parent th.control:before {
  content: "-";
  background-color: #d33333;
}
table.dataTable > tbody > tr.child {
  padding: 0.5em 1em;
}
table.dataTable > tbody > tr.child:hover {
  background: transparent !important;
}
table.dataTable > tbody > tr.child ul {
  display: inline-block;
  list-style-type: none;
  margin: 0;
  padding: 0;
}
table.dataTable > tbody > tr.child ul li {
  border-bottom: 1px solid #efefef;
  padding: 0.5em 0;
}
table.dataTable > tbody > tr.child ul li:first-child {
  padding-top: 0;
}
table.dataTable > tbody > tr.child ul li:last-child {
  border-bottom: none;
}
table.dataTable > tbody > tr.child span.dtr-title {
  display: inline-block;
  min-width: 75px;
  font-weight: bold;
}

div.dtr-modal {
  position: fixed;
  box-sizing: border-box;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 100;
  padding: 10em 1em;
}
div.dtr-modal div.dtr-modal-display {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 50%;
  height: 50%;
  overflow: auto;
  margin: auto;
  z-index: 102;
  overflow: auto;
  background-color: #f5f5f7;
  border: 1px solid black;
  border-radius: 0.5em;
  box-shadow: 0 12px 30px rgba(0, 0, 0, 0.6);
}
div.dtr-modal div.dtr-modal-content {
  position: relative;
  padding: 1em;
}
div.dtr-modal div.dtr-modal-close {
  position: absolute;
  top: 6px;
  right: 6px;
  width: 22px;
  height: 22px;
  border: 1px solid #eaeaea;
  background-color: #f9f9f9;
  text-align: center;
  border-radius: 3px;
  cursor: pointer;
  z-index: 12;
}
div.dtr-modal div.dtr-modal-close:hover {
  background-color: #eaeaea;
}
div.dtr-modal div.dtr-modal-background {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 101;
  background: rgba(0, 0, 0, 0.6);
}

@media screen and (max-width: 767px) {
  div.dtr-modal div.dtr-modal-display {
    width: 95%;
  }
}
#page-wrapper.help-page {
  margin-top: 1rem;
}
#page-wrapper.help-page .card {
  padding-top: 10rem;
  float: left;
  border: none;
}
#page-wrapper.help-page .menu {
  width: 17.15rem;
  padding-top: 3.9rem;
  border: none;
}
#page-wrapper.help-page .menu > .card-body {
  padding: 0;
}
#page-wrapper.help-page .menu > .card-body div {
  padding: 0 0.1rem 0 1.5rem;
  border: solid #dfdfdf 0.01625rem;
  color: #212121;
  color: rgba(0, 0, 0, 0.87);
  height: 2.2222222222rem;
  display: flex;
  align-items: center;
}
#page-wrapper.help-page .menu > .card-body div.font-bold {
  padding: 0 1rem;
}
#page-wrapper.help-page .menu > .card-body div:hover {
  background-color: #20a1db;
  color: white;
}
#page-wrapper.help-page .menu > .card-body div.active {
  background-color: #20a1db;
  color: white;
  font-weight: bold;
}
#page-wrapper.help-page .topic-contents {
  padding-top: 3.9rem;
}
#page-wrapper.help-page .display {
  width: calc(100% - 20rem);
  margin-left: 1rem;
}
#page-wrapper.help-page .services-description {
  padding: 0 0 1.75em 0;
}
#page-wrapper.help-page .services-description > .service-table {
  line-height: 1.75em;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  width: 100%;
}
#page-wrapper.help-page .services-description > .service-table > thead > tr {
  font-weight: bold;
  font-size: 14px;
  height: 2.2222rem;
  background-color: #E0E0E0;
}
#page-wrapper.help-page .services-description > .service-table > thead > tr > th:first-child {
  padding-left: 24px;
}
#page-wrapper.help-page .services-description > .service-table > tbody {
  font-size: 12px;
}
#page-wrapper.help-page .services-description > .service-table > tbody > tr {
  border: solid #E0E0E0 0.01625rem;
}
#page-wrapper.help-page .services-description > .service-table > tbody > tr > td:first-child {
  font-weight: bold;
  padding-left: 24px;
}

#onboarding-page {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
#onboarding-page .carousel {
  width: 21.3888888889rem;
  box-shadow: 0.01625rem 0.01625rem 0.5rem rgba(0, 0, 0, 0.3);
  margin-top: 100px;
}
#onboarding-page .carousel .item {
  background: white;
}
#onboarding-page .carousel .item .slide-header {
  width: 100%;
}
#onboarding-page .carousel .item .slide-body {
  height: 245px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: space-around;
  padding: 1rem 3rem;
}
#onboarding-page .carousel .item .slide-body > * {
  width: 100%;
}
#onboarding-page .carousel .item .slide-body .button {
  display: flex;
}
#onboarding-page .slide-body h3 {
  margin-top: 0px;
}
#onboarding-page .carousel-control {
  top: 50%;
  transform: translateY(-50%);
  height: 2rem;
  width: 2rem;
  border-radius: 50%;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0.01625rem 0.01625rem 0.5rem rgba(0, 0, 0, 0.3);
  text-shadow: none;
  color: #20a1db;
}
#onboarding-page .carousel-control.left {
  background-image: none;
  left: -3rem;
}
#onboarding-page .carousel-control.right {
  background-image: none;
  right: -3rem;
}
#onboarding-page .carousel-control.disabled {
  background-color: #CCC;
  color: #848484;
  box-shadow: none;
  pointer-events: none;
}
#onboarding-page .carousel-indicators {
  position: relative;
  margin: 1rem;
  padding: 0;
  width: 8%;
  left: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
#onboarding-page .carousel-indicators li {
  height: 0.6666666667rem;
  width: 0.6666666667rem;
  border-radius: 50%;
  background: #929292;
}
#onboarding-page .carousel-indicators li.active {
  background: #20a1db;
  height: 0.7777777778rem;
  width: 0.7777777778rem;
}

#contractDetailsForm {
  margin: 1.3333333333rem -0.6666666667rem;
}
#contractDetailsForm > div {
  width: 50%;
  float: left;
  padding: 0 0.6666666667rem;
  margin-top: 3.5rem;
}
#contractDetailsForm .card-body label {
  font-weight: normal;
  width: 4rem;
}
#contractDetailsForm .card-body label.volume-based-discount-caption {
  width: 10rem;
}
#contractDetailsForm .card-body .radio label {
  width: 50%;
  padding-left: 0;
}
#contractDetailsForm table {
  min-width: 17.7777777778rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
}
#contractDetailsForm table td, #contractDetailsForm table th, #contractDetailsForm table input {
  width: 3.5555555556rem;
  height: 1.3333333333rem;
}
#contractDetailsForm table td + td, #contractDetailsForm table th + th {
  padding-left: 0.8888888889rem;
}
#contractDetailsForm table tr + tr > td {
  padding-top: 0.4444444444rem;
}
#contractDetailsForm .service-status {
  border-radius: 50%;
  border: 1px solid #CCC;
  width: 1.3333333333rem;
  height: 1.3333333333rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
}
#contractDetailsForm .services-status-title {
  margin-left: auto;
}
#contractDetailsForm .service-item {
  height: 3rem;
  align-items: center;
  border-bottom: 1px solid #e3e3e3;
  padding: 0 1.3333333333rem;
}
#contractDetailsForm .service-item + .service-item {
  border-top: solid 1px #dfdfdf;
}
#contractDetailsForm .edit {
  background: #f5d16e;
  color: white;
  border: none;
}
#contractDetailsForm .active {
  background: #e8fedb;
  background: rgba(102, 175, 16, 0.15);
  color: #66af10;
  border: none;
  font-weight: bold;
}
#contractDetailsForm .settings-box input + .error-messages, #contractDetailsForm .settings-box input + .success-messages {
  left: 9rem;
}
#contractDetailsForm #carrier-prefix {
  position: absolute;
  left: 8.2rem;
  top: 50%;
  transform: translateY(-50%);
}

.right-column {
  margin-left: 3rem;
  min-width: 6rem;
}

#contractDetailsForm table input {
  text-indent: 5px;
}

.card.service-settings {
  min-width: 515px;
}

.js-split-table-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  white-space: nowrap;
  overflow: auto;
}

@media screen and (max-width: 1690px) {
  .js-split-table-container {
    display: block;
  }
  .js-split-table-container.js-split-table-2 .js-split-table-item2 {
    margin-top: -34px !important;
  }
  .js-split-table-container.js-split-table-2 .js-split-table-item2 thead {
    visibility: hidden;
  }
}
.custom-radio {
  display: none;
}
.custom-radio:checked + .custom-radio-label:before {
  background-color: #20a1db;
}

.custom-radio-label {
  display: flex;
  align-items: center;
}
.custom-radio-label:before {
  content: "";
  display: inline-block;
  width: 16px;
  height: 16px;
  margin-right: 10px;
  background-color: #fff;
  border-radius: 50%;
  border: 3.5px solid #fff;
  box-shadow: 0px 0px 0px 1.5px rgb(149, 152, 154);
}

#calculator-page {
  margin-top: 4.5rem;
}

.calc-menu {
  color: #CCC !important;
  background: #fafafa !important;
  cursor: default !important;
  outline: none !important;
}

.number-label {
  margin-left: 0.6666rem;
  padding-top: 0.3333rem;
  text-align: center;
  font-size: medium;
  color: #CCC;
}

.asterisk-label {
  margin-right: 1rem;
  text-align: start;
  font-size: small;
  color: #d91a1a;
  cursor: pointer;
}

#calculatorsGeneralRateIncrease .table {
  background-color: white;
}
#calculatorsGeneralRateIncrease .table > tbody > tr:hover {
  background: none !important;
  color: black;
}
#calculatorsGeneralRateIncrease .table > tfoot > tr > td {
  cursor: default;
  text-align: right;
  padding: 0.5rem 2rem 0.5rem 0.5rem;
  color: #20a1db;
  font-weight: bold;
  font-size: 80%;
}
#calculatorsGeneralRateIncrease .table > tfoot > tr > td:first-child {
  cursor: default;
  text-align: left;
}
#calculatorsGeneralRateIncrease .table > thead > tr > th, #calculatorsGeneralRateIncrease .table > thead > tr > td, #calculatorsGeneralRateIncrease .table > tbody > tr > th, #calculatorsGeneralRateIncrease .table > tbody > tr > td {
  cursor: default;
  text-align: right;
  font-size: 80%;
}
#calculatorsGeneralRateIncrease .table > thead > tr > th:first-child, #calculatorsGeneralRateIncrease .table > thead > tr > td:first-child, #calculatorsGeneralRateIncrease .table > tbody > tr > th:first-child, #calculatorsGeneralRateIncrease .table > tbody > tr > td:first-child {
  cursor: default;
  text-align: left;
  padding-left: 0.75rem;
}

#calculatorsDimensionalWeight .settings-field-label {
  width: 53%;
}

#calculatorsDimensionalWeight .settings-field-value {
  width: 40%;
}

#calculatorsGeneralRateIncrease .settings-field-label {
  width: 47%;
  font-size: 70%;
  padding-right: 1px;
}

#calculatorsGeneralRateIncrease .settings-field-value {
  width: 45%;
}

.popover {
  background-color: rgba(0, 0, 0, 0.8) !important;
}

.left > .arrow:after {
  border-left-color: rgba(0, 0, 0, 0.8) !important;
}

.top > .arrow:after {
  border-top-color: rgba(0, 0, 0, 0.8) !important;
}

.right > .arrow:after {
  border-right-color: rgba(0, 0, 0, 0.8) !important;
}

#calculatorsDimensionalWeight .card {
  width: 49%;
}

#calculatorsDimensionalWeight input,
#calculatorsGeneralRateIncrease input {
  text-align: right;
}

#calculatorsDimensionalWeight output {
  float: right;
  text-align: left;
  font-weight: bold;
  color: #20a1db;
}

#calculatorsDimensionalWeight input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button,
#calculatorsGeneralRateIncrease input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

#calculatorsDimensionalWeight input[type=number],
#calculatorsGeneralRateIncrease input[type=number] {
  -moz-appearance: textfield;
}

#calculatorsDimensionalWeight .card,
#calculatorsGeneralRateIncrease .card {
  float: left;
}

#calculatorsDimensionalWeight .card:last-child,
#calculatorsGeneralRateIncrease .card:last-child {
  margin-left: 1%;
}

#calculatorsGeneralRateIncrease .inputs-card {
  width: 30%;
}

#calculatorsGeneralRateIncrease .outputs-card {
  width: 69%;
}

#calculatorsGeneralRateIncrease .outputs-card .card-body {
  padding: 1.3333rem 0.6667rem;
}

#calculatorsGeneralRateIncrease .inputs-card .card-body {
  padding: 1.333rem 7%;
}

.button-GeneralRateIncrease {
  position: absolute;
  top: 500px;
}

#calculatorMinimumChargeImpact .outputs-card {
  width: 49%;
  float: left;
  margin-top: 1rem;
}

#calculatorMinimumChargeImpact .outputs-card #table-holder-a-view-option,
#calculatorMinimumChargeImpact .outputs-card #table-holder-b-view-option {
  border: none;
  align-items: right;
  width: auto;
}

#calculatorMinimumChargeImpact select {
  cursor: pointer;
  background: url(/img/arrow.png) no-repeat 95% 50%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding-right: 40px;
}

select::-ms-expand {
  display: none;
}

#calculatorMinimumChargeImpact select:not(:-internal-list-box) {
  overflow: hidden !important;
}

#calculatorMinimumChargeImpact .settings-field-label {
  width: 25%;
}

#calculatorMinimumChargeImpact .settings-field-value {
  width: 73%;
}

#calculatorMinimumChargeImpact .settings-field-value select {
  width: 47%;
  margin-left: 1%;
  cursor: pointer;
}

#calculatorMinimumChargeImpact .settings-field-value input {
  width: 47%;
  background-color: white;
  border: rgb(169, 169, 169) 1px solid;
  margin-left: 1%;
}

.slider.slider-horizontal {
  height: 14px;
  width: 47%;
  margin: 0 1% 0 1% !important;
}

.slider.slider-horizontal .slider-track {
  height: 2px;
}

.slider-tooltip-bubble .tooltip {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.3) !important;
  background-color: #fff;
  border-radius: 8px;
  padding-right: 24px !important;
  z-index: 1010;
}

#calculatorMinimumChargeImpact .tooltip {
  z-index: 0 !important;
}

#calculatorMinimumChargeImpact .bootstrap-select:not([class*=col-]):not([class*=form-control]):not(.input-group-btn) {
  width: 47% !important;
  padding: 0rem !important;
  margin-left: 3px;
}
#calculatorMinimumChargeImpact .btn.dropdown-toggle.btn-default {
  padding-top: 5px;
  padding-bottom: 5px;
  border: #a9a9a9 1px solid;
  line-height: 28px;
}
#calculatorMinimumChargeImpact .dropdown-menu.open {
  z-index: 4 !important;
}
#calculatorMinimumChargeImpact .filter-option.pull-left {
  font-size: 16px !important;
}
#calculatorMinimumChargeImpact #service-type-b-id {
  padding-top: 5px;
}

.slider.slider-horizontal .slider-tick-label-container {
  margin-top: 10px;
  margin-left: -20px !important;
  margin-right: -20px !important;
  overflow: hidden;
}

.slider-tick-label-container .slider-tick-label:first-child {
  margin-left: -24% !important;
}
.slider-tick-label-container .slider-tick-label:last-child {
  margin-left: 20% !important;
}

.slider.slider-horizontal .slider-tick-label-container .slider-tick-label:nth-of-type(1) {
  width: 62% !important;
}

.slider.slider-horizontal .slider-tick-label-container .slider-tick-label:nth-of-type(2) {
  width: 57% !important;
}

.slider-handle {
  width: 10px;
  height: 10px;
}

.table_holder_a_view_option, .table_holder_b_view_option {
  width: 77%;
  text-align: right;
}

#service_type_a_name, #service_type_b_name {
  width: 23%;
  text-align: left;
}

.minimum_charge_amount_text {
  color: red;
  text-align: left;
}

#calculatorMinimumChargeImpact .table > tbody > tr:hover {
  background: none !important;
  color: black;
}
#calculatorMinimumChargeImpact .table > thead > tr > th, #calculatorMinimumChargeImpact .table > thead > tr > td, #calculatorMinimumChargeImpact .table > tbody > tr > th, #calculatorMinimumChargeImpact .table > tbody > tr > td {
  cursor: default;
  padding-right: 0;
}
#calculatorMinimumChargeImpact .table > thead > tr > th:first-child, #calculatorMinimumChargeImpact .table > thead > tr > td:first-child, #calculatorMinimumChargeImpact .table > tbody > tr > th:first-child, #calculatorMinimumChargeImpact .table > tbody > tr > td:first-child {
  cursor: default;
}

.calcminright {
  margin-left: 1rem;
}

.pagination .paginate_button a {
  border: none;
  outline: none;
  background-color: inherit !important;
  color: #20a1db;
}
.pagination .paginate_button a:hover {
  color: #1c8cbf;
}
.pagination .paginate_button.active a {
  color: rgba(0, 0, 0, 0.5) !important;
}
.pagination .paginate_button.disabled a {
  color: #CCC;
  cursor: default;
}

#calculatorMinimumChargeImpact .custom-radio {
  display: none;
}
#calculatorMinimumChargeImpact .custom-radio:checked + .custom-radio-label:before {
  background-color: #20a1db;
}
#calculatorMinimumChargeImpact .custom-radio-label {
  display: flex;
  align-items: center;
}
#calculatorMinimumChargeImpact .custom-radio-label:before {
  content: "";
  display: inline-block;
  width: 16px;
  height: 16px;
  margin-right: 10px;
  background-color: #fff;
  border-radius: 50%;
  border: 3.5px solid #fff;
  box-shadow: 0px 0px 0px 1.5px rgb(149, 152, 154);
}

.columnize-2 {
  width: 230px;
  position: absolute;
  margin-left: 210px;
  height: 600px;
}

.discount-percent-input-box {
  width: 150px;
  background-color: #fff;
  border: #a9a9a9 1px solid;
  margin-left: 1%;
  height: 40px;
  margin-bottom: 10px;
  padding-left: 14px;
}

.columnize-2-right {
  width: 230px;
  margin-left: 453px;
}

.columnize-2 label, .columnize-2-right label {
  font-size: 14px;
  min-width: 80px;
  margin-bottom: 15px;
  text-align: right;
  padding-right: 14px;
}

.columnize-title {
  height: 20px;
  padding-bottom: 10px;
  font-size: 14px;
}

.columnize-title-left {
  height: 20px;
  padding-bottom: 10px;
  font-size: 14px;
  text-align: right;
  padding-right: 14px;
}

.tooltip {
  z-index: 0;
}

.slider-tooltip-bubble .tooltip-inner {
  margin-left: 3px !important;
}

.slider-tooltip-bubble .tooltip.top .tooltip-arrow {
  margin-left: -12px !important;
}

.userEditAddForm .card {
  width: 45% !important;
  margin-top: 1.3333rem;
}

.userEditAddForm .settings-field-label {
  width: 25% !important;
}

.userEditAddForm .settings-field-value,
.companyEditAddForm .settings-field-value {
  height: 2rem !important;
}

.radio {
  margin-top: 8px;
}

.userEditAddForm .bootstrap-select:not([class*=col-]):not([class*=form-control]):not(.input-group-btn) {
  width: 100% !important;
}

#select_company {
  padding: 0px;
}

.user-radio {
  display: none;
}
.user-radio:checked + .user-radio-label:before {
  background-color: #20a1db;
}

#user-companyid:focus {
  box-shadow: none !important;
}

.user-radio-label {
  display: flex;
  align-items: center;
}
.user-radio-label:before {
  content: "";
  display: inline-block;
  width: 16px;
  height: 16px;
  margin-right: 10px;
  background-color: #fff;
  border-radius: 50%;
  border: 3.5px solid #fff;
  box-shadow: 0px 0px 0px 1.5px rgb(149, 152, 154);
}

.checkbox {
  padding-left: 20px;
  width: 100%;
}

.checkbox label {
  display: inline-block;
  position: relative;
  padding-left: 15px;
  font-size: 14px;
  font-weight: 400;
}

/*.checkbox label::before {
  content: "";
  display: inline-block;
  position: absolute;
  width: 17px;
  height: 17px;
  left: 0;
  margin-left: -20px;
  border: 2px solid #757575;
  border-radius: 4px;
  background-color: #ffffff;
  -webkit-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
  -o-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
  transition: border 0.15s ease-in-out, color 0.15s ease-in-out; 
}

.checkbox label::after {
  display: inline-block;
  position: absolute;
  width: 16px;
  height: 16px;
  left: 0;
  top: 0;
  margin-left: -20px;
  padding-left: 3px;
  padding-top: 1px;
  font-size: 11px;
  color: #ffffff;
}
.checkbox input[type="checkbox"] {
  opacity: 0;
}

.checkbox input[type="checkbox"]:focus + label::before {
  outline: thin dotted;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}

.checkbox input[type="checkbox"]:checked + label::after {
  font-family: 'FontAwesome';
    content: "\f14a";
    color: #20a1db;
    font-size: 20px;
    padding: 0;
    margin-top: -5px;
}
*/
.dashboard-filter-company-list-checkbox.checkbox input[type=checkbox]:checked + label::after {
  font-family: "FontAwesome";
  content: "\f14a";
  /* background-color: #20a1db; */
  color: #20a1db;
  font-size: 20px;
  padding: 0;
  margin-top: 3px;
}

.checkbox input[type=checkbox]:disabled + label {
  opacity: 0.65;
}

.checkbox input[type=checkbox]:disabled + label::before {
  background-color: #eeeeee;
  cursor: not-allowed;
}

#cardMargin {
  position: relative;
  left: 20px;
}

.letra {
  font-weight: bold;
  padding-top: 10px;
}

/* .ui-select-choices {
  position: fixed;
  top: auto;
  left: auto;
  width: inherit;
}
 */
.dropdown-menu .open {
  overflow-x: auto;
}

#userDetailsForm .bootstrap-select.btn-group .dropdown-menu {
  max-width: 100% !important;
  max-height: 295px !important;
  overflow: hidden;
}

tfoot {
  display: table-header-group;
}

.dropdown-menu > li > .dropdown-menu-item {
  clear: both;
  color: #333;
  display: block;
  padding: 3px 20px;
  white-space: nowrap;
  margin: 5px 0;
  width: 100%;
  text-align: left;
  text-decoration: none;
  outline: none;
  cursor: pointer;
  -moz-user-select: none;
  user-select: none;
}

.dropdown-menu > li:hover .dropdown-menu-item,
.dropdown-menu > li:focus .dropdown-menu-item {
  background-color: #f5f5f5;
  color: #262625;
}

.dropdown-menu > li > .dropdown-menu-item.checkbox {
  margin: 0;
  font-weight: normal;
}

.dropdown-menu > li > .dropdown-menu-item.checkbox input {
  display: none;
}

.table-permissions {
  margin-bottom: 0;
}
.table-permissions .head {
  height: 0.16667rem !important;
}

.head-text {
  color: rgba(0, 0, 0, 0.87) !important;
  font-weight: 700;
  text-align: left !important;
  font-size: 14px;
}

.head-text-center {
  color: rgba(0, 0, 0, 0.87) !important;
  font-weight: 700;
  text-align: center !important;
}

.row-text {
  text-align: left !important;
  padding-left: 28px !important;
}

.row-text-center {
  text-align: center !important;
}

.dropdown-menu inner {
  max-height: 235px !important;
}

.dynamicCompanyTable.table > tbody > tr:hover {
  background: #f5f5f5 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

#user-permissions-section {
  display: flex;
  justify-content: center;
}

.permissions-cat {
  height: auto;
  flex-wrap: wrap;
  flex-basis: 200px;
  font-size: 14px;
  min-width: 180px;
  max-width: 300px;
  align-content: flex-start;
}
.permissions-cat .title {
  margin-bottom: 20px;
  width: 100%;
  color: #006290;
  font-size: 14px;
  text-transform: capitalize;
  font-weight: 400;
}

.checkbox {
  margin: 0 0 5px 0;
  font-size: 16px;
}

.button-bottom {
  margin: 20px 0;
}

.token-input {
  font-size: 14px;
}

.tokenfield .token {
  font-size: 14px;
  margin: 4px 5px 0 0 !important;
}

.tokenfield {
  padding-left: 5px !important;
}

.fa-remove:before, .fa-close:before, .fa-times:before {
  content: "\f057";
  font-size: 16px;
  color: #c9c9c9;
}

.company-email-input {
  background-image: none !important;
  font-size: 14px !important;
}

.accounts-th {
  height: 90px !important;
}

.accounts-row {
  padding-left: 24px !important;
}

.dt-checkboxes-select-all {
  text-align: left !important;
}

.dt-checkboxes-cell {
  text-align: left !important;
  padding-left: 14px !important;
}

.btn-group {
  width: 250px;
}

.btn-select {
  width: 250px;
  text-align: left;
}

#listOfUniqueBU.dropdown-menu {
  width: 250px;
  margin-top: -1px;
  padding: 14px;
}

#listOfUniqueCarriers.dropdown-menu {
  width: 250px;
  margin-top: -1px;
  padding: 14px;
}

#columnAccountFilter {
  width: 250px;
}

.no-margin-bottom {
  margin-bottom: 0 !important;
}

.accounts-select-arrow {
  margin-top: 10px;
  color: #999;
}
.accounts-select-arrow .caret {
  position: absolute;
  right: 10px;
  top: 15px;
}

#save-top-button.disabled {
  color: #ccc;
  pointer-events: none;
  font-size: 18px;
  text-transform: uppercase;
  position: absolute;
  right: 8px;
  top: 5px;
}

/*.dropdown-menu {
  width: 472px !important;
  height: 400px !important;
}*/
/*.dropdown-menu.accounts-filter-dropdown-content {
  width: 1100px !important;
  height: 1000px !important;
  .row.content-full-width {
    margin: 0;
    .col-xs-12.col-sm-12 {
      padding: 0;
    }
    .result-container {
      margin: 0;
    }
  }
}*/
.dataTables_scrollBody thead {
  visibility: hidden;
}

.dataTables_scrollHead {
  overflow: visible !important;
}

.open > .dropdown-toggle.btn-default:focus, .open > .dropdown-toggle.btn-default:hover {
  background-color: #fff !important;
  border-color: #8c8c8c;
}

.tooltip {
  box-shadow: 0px 4px 17px 0px rgba(0, 0, 0, 0.3);
  background-color: #fff;
  border-radius: 8px;
  z-index: 1010;
  padding-right: 24px !important;
}

.tooltip-inner {
  max-width: 350px;
}

@media screen and (max-width: 1400px) {
  #userDetailsForm {
    max-width: 1050px;
  }
}
#userDetailsForm .tooltip {
  box-shadow: 0px 4px 17px 0px rgba(0, 0, 0, 0.3);
  background-color: #fff;
  border-radius: 8px;
  z-index: 1010;
  margin-top: -5px !important;
  margin-left: 0px !important;
}
#userDetailsForm .tooltip-inner {
  max-width: 350px;
  max-height: 150px !important;
  overflow-y: scroll;
  padding-right: 24px;
}

#companiesSummaryTooltip .dropdown-menu {
  min-width: 200px;
}
#companiesSummaryTooltip .dropdown-menu.columns-2 {
  min-width: 400px;
}
#companiesSummaryTooltip .dropdown-menu.columns-3 {
  min-width: 600px;
}
#companiesSummaryTooltip .dropdown-menu li a {
  padding: 5px 15px;
  font-weight: 300;
}
#companiesSummaryTooltip .multi-column-dropdown {
  list-style: none;
  margin: 0px;
  padding: 0px;
}
#companiesSummaryTooltip .multi-column-dropdown li a {
  display: block;
  clear: both;
  line-height: 1.428571429;
  color: #333;
  white-space: normal;
}
#companiesSummaryTooltip .multi-column-dropdown li a:hover {
  text-decoration: none;
  color: #262626;
  background-color: #999;
}
@media (max-width: 767px) {
  #companiesSummaryTooltip .dropdown-menu.multi-column {
    min-width: 240px !important;
    overflow-x: hidden;
  }
}
#companiesSummaryTooltip .border-right-dropdown {
  border-right: 1px solid #e5e5e5;
}

/*#tooltipWithScrollDown {


  .tooltip {
    transform: none;
    margin: 50px;    
  }

  .tooltip:hover > .tooltip-text, .tooltip:hover > .wrapper {
    pointer-events: auto;
    opacity: 1.0;
  }

  .tooltip > .tooltip-text, .tooltip >.wrapper {
    display: block;
    position: absolute;
    z-index: 6000;
    overflow: visible;
    padding: 5px 8px;
    margin-top: 10px;
    line-height: 16px;
    border-radius: 4px;
    text-align: left;
    color: #fff;
    background: #000;
    pointer-events: none;
    opacity: 0.0;
    -o-transition: all 0.3s ease-out;
    -ms-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -webkit-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
  }


  .tooltip > .tooltip-text:before, .tooltip > .wrapper:before  {
    display: inline;
    top: -5px;
    content: "";
    position: absolute;
    border: solid;
    border-color: rgba(0, 0, 0, 1) transparent;
    border-width: 0 .5em .5em .5em;
    z-index: 6000;
    left: 20px;
  }


  .tooltip > .tooltip-text:after, .tooltip > .wrapper:after  {
    top: -20px;
    content: " ";
    display: block;
    height: 20px;
    position: absolute;
    width: 60px;
    left: 20px;
  }

  .wrapper > .tooltip-text {
    overflow-y: auto;
    max-height: 40px;
    display: block;
  }
}*/
.company-filters {
  max-height: fit-content;
  width: 737px !important;
}

.filter-column-left-title {
  margin: 14px 0 36px 0;
}
.filter-column-left-title span {
  padding-left: 14px;
  font-weight: 900;
}
.filter-column-left-title input {
  width: 50%;
}

.filter-title-color {
  color: #20a1db;
}

.filter-title-arrow {
  color: #20a1db;
  padding: 0 7px;
}

.filter-title-regular {
  font-weight: 400 !important;
}

.popover {
  width: 380px;
  border: none;
  background-color: #fff !important;
  box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  z-index: 1001 !important;
}

.popover-content {
  overflow: hidden;
  color: rgba(0, 0, 0, 0.87);
}

.popover.bottom > .arrow {
  opacity: 0;
}

.popover-column-left {
  border-right: 1px solid #E8E8E8;
  border-bottom: 1px solid #E8E8E8;
  padding: 14px 14px 14px 24px;
  min-height: 150px;
}
.popover-column-left label {
  color: rgba(0, 0, 0, 0.87);
  width: 140px;
}

.popover-column-left-title {
  border-right: 1px solid #E8E8E8;
  padding: 11px 0 0 24px;
  height: 45px;
}
.popover-column-left-title ul {
  list-style: none;
  padding-left: 14px;
}
.popover-column-left-title label {
  color: rgba(0, 0, 0, 0.54);
  font-size: 12px;
}

.popover-column-right {
  padding: 14px 5px 14px 14px;
  border-bottom: 1px solid #E8E8E8;
  background-color: #f5f5f5;
  width: 170px;
}

.popover-column-right-title {
  background-color: #f5f5f5;
  padding: 11px 0 0 14px;
}
.popover-column-right-title ul {
  list-style: none;
  padding-left: 0;
}
.popover-column-right-title label {
  color: rgba(0, 0, 0, 0.54);
  font-size: 12px;
}

.popover.bottom {
  margin-top: 20px !important;
}

.popover-row {
  border-bottom: 1px solid #E8E8E8;
}

.popover-label-company {
  color: #006290 !important;
  margin-bottom: 0;
}

.popover-label-units {
  color: #888888 !important;
  font-size: 12px;
  font-weight: 400;
}

#userDetailsForm .bootstrap-select.btn-group .dropdown-menu {
  overflow: visible;
}

.popover-subcontent {
  margin-top: -23px;
  margin-bottom: -57px;
  min-height: 150px;
  height: 100%;
  max-height: 496px;
  overflow-y: auto;
  overflow-x: hidden;
  margin: -21px;
}

.popover-units-list {
  font-size: 12px;
  height: 121px;
  overflow: auto;
}
.popover-units-list label {
  font-weight: 500 !important;
}

.scroll-padding-right {
  padding-right: 14px;
}

/*#userDetailsForm .error-messages {
  overflow: visible;
}*/
/*#userDetailsForm {
  input+.error-messages, textarea+.error-messages{
    color:$red;
    font-size: 0.6rem;
    margin-top: getrem(4);
  }
}*/
.hline {
  width: 100%;
  height: 1px;
  background: rgb(255, 255, 255);
}

.table-striped > tbody > tr:nth-of-type(odd), .table-striped > tbody > tr:nth-of-type(even) {
  background: #f5f5f5;
}

td.dataTables_empty {
  color: red;
}

#requiredAsterisk .head-text:after {
  content: "*";
  color: #d91a1a;
}

#emailErrorMessage {
  color: #d91a1a;
  font-size: 0.6rem;
  margin-top: 0.22222rem;
}

/*#footerTableFilter {
  .row {
    margin-right: 0px!important;
    margin-left: 0px!important;
  }
}*/
.footerTableFilter {
  background-color: #fff;
  float: right;
  width: 100%;
  padding: 17px;
  border-top: 1px solid #e8e8e8;
  height: 70px;
  margin-top: -28px;
  text-align: right;
}

.color-blue {
  color: #20a1db !important;
}

.tooltip-business-units-hover tr td > div.user-company-count {
  color: #20a1db;
}
.tooltip-business-units-hover tr:hover td > div.user-company-count {
  color: #fff !important;
}
.tooltip-business-units-hover .tooltip.in {
  opacity: 1 !important;
  filter: alpha(opacity=100);
}
.tooltip-business-units-hover .tooltip-inner {
  min-width: 150px;
  background-color: #fff !important;
  color: #000;
  font-size: 14px !important;
  max-width: 1000px;
}
.tooltip-business-units-hover .tooltip-inner div {
  margin-top: 10px;
  margin-bottom: 10px;
}
.tooltip-business-units-hover .tooltip.bottom .tooltip-arrow {
  border-bottom-color: #fff;
}

.dt-checkboxes checkbox_checked {
  width: auto;
  height: auto;
}

#busi-tool-try {
  color: #20a1db;
}

#busi-tool-try:hover {
  color: #fff !important;
}

table.table > tbody > tr:hover #busi-tool-try {
  color: #fff !important;
}

#busi-tool-try-numerator {
  color: #20a1db;
}

#busi-tool-try-numerator:hover {
  color: #fff !important;
}

table.table > tbody > tr:hover #busi-tool-try-numerator {
  color: #fff !important;
}

#divider-span {
  color: #20a1db;
}

table.table > tbody > tr:hover #divider-span {
  color: #fff !important;
}

#request-contact-client-modal .modal-dialog, #suggestion-modal .modal-dialog {
  min-width: 30%;
}
#request-contact-client-modal .modal-header, #suggestion-modal .modal-header {
  border-bottom: none;
}
#request-contact-client-modal .text-large, #suggestion-modal .text-large {
  color: #20A1DB;
}
#request-contact-client-modal .suggestion-buttons, #suggestion-modal .suggestion-buttons {
  width: 100%;
  height: 40px;
  line-height: 40px;
  text-align: right;
}
#request-contact-client-modal .suggestion-buttons button, #suggestion-modal .suggestion-buttons button {
  float: right;
}
#request-contact-client-modal .suggestion-buttons button.disabled, #suggestion-modal .suggestion-buttons button.disabled {
  background: transparent !important;
  color: #ccc !important;
  cursor: default !important;
}
#request-contact-client-modal #suggestion-form textarea, #suggestion-modal #suggestion-form textarea {
  resize: vertical;
  width: 100%;
  max-height: 200px;
  min-height: 40px;
  border: none;
  border-bottom: 1px rgb(75, 72, 72) solid;
}
#request-contact-client-modal #report-form textarea, #suggestion-modal #report-form textarea {
  resize: vertical;
  width: 100%;
  max-height: 200px;
  min-height: 40px;
  border: none;
  border-bottom: 1px rgb(75, 72, 72) solid;
}

#thanks-modal .modal-dialog {
  min-width: 30%;
}
#thanks-modal .modal-header {
  border-bottom: none;
}
#thanks-modal .text-large {
  color: #20A1DB;
}
#thanks-modal .thanks-buttons {
  width: 100%;
  height: 40px;
  line-height: 40px;
  text-align: right;
}
#thanks-modal .thanks-buttons button {
  float: right;
}
#thanks-modal .thanks-buttons button.disabled {
  background: transparent !important;
  color: #ccc !important;
  cursor: default !important;
}
#thanks-modal #thanks-form textarea {
  resize: vertical;
  width: 100%;
  max-height: 200px;
  min-height: 40px;
  border: none;
  border-bottom: 1px rgb(75, 72, 72) solid;
}

#date-range-filter .tabs-vertical {
  margin-top: 14px;
  position: absolute;
  width: 680px;
  padding: 0 !important;
  height: 430px !important;
}
#date-range-filter .tabs-vertical .tabs-nav {
  float: left;
  width: 15%;
  margin-left: 0;
  padding-left: 0;
  height: 280px;
  min-height: 240px;
}
#date-range-filter .tabs-vertical .tabs-nav li {
  clear: left;
  width: 100%;
  list-style-type: none;
  height: 40px;
}
#date-range-filter .tabs-vertical .tabs-nav li a {
  padding-left: 14px;
  height: 40px;
  line-height: 40px;
  display: block;
  color: black;
  font-weight: 700;
}
#date-range-filter .tabs-vertical .tabs-nav li.tabs-active {
  background-color: #20a1db;
}
#date-range-filter .tabs-vertical .tabs-nav li.tabs-active a {
  color: white;
}
#date-range-filter .tabs-vertical .tabs-panel {
  float: right;
  width: 85%;
  height: 100%;
  background: rgb(245, 245, 245);
  border-left: 1px #dfdfdf solid;
}
#date-range-filter .tabs-vertical .tabs-panel div.tab {
  height: 85%;
}
#date-range-filter .tabs-vertical .tabs-panel div.tab.tab-hidden {
  display: none;
}
#date-range-filter .tabs-vertical .tabs-panel .tab-header {
  width: 100%;
  height: 40px;
  line-height: 40px;
  padding-left: 2%;
  color: #006290;
  font-weight: 700;
  font-size: 12px;
}
#date-range-filter .tabs-vertical .tabs-panel .tab-content {
  width: 100%;
}
#date-range-filter .tabs-vertical .tabs-panel .tab-content input {
  width: 260px;
  height: 40px;
  padding-left: 3%;
  margin-left: 14px;
}
#date-range-filter .tabs-vertical .tabs-panel .tabs-footer {
  text-align: right;
  position: absolute;
  right: 14px;
  bottom: 5%;
  width: 50%;
}
#date-range-filter .tabs-vertical .tabs-panel .tabs-footer button {
  margin-right: 2%;
  font-weight: 700;
  margin-top: 5%;
}
#date-range-filter .tabs-vertical .tabs-panel .tabs-footer button.disabled {
  color: white;
  outline: none;
}
#date-range-filter .tabs-vertical .tabs-panel .tab-content-child {
  width: 100%;
  height: 40px;
  line-height: 40px;
  padding-left: 14px;
  padding-right: 14px;
  margin-bottom: 2%;
  color: #20a1db;
  font-weight: 700;
}
#date-range-filter .tabs-vertical .tabs-panel .tab-content-child button.month-button, #date-range-filter .tabs-vertical .tabs-panel .tab-content-child button {
  width: 120px;
  background-color: white;
  margin-right: 7px;
  justify-content: left;
}
#date-range-filter .tabs-vertical .tabs-panel .tab-content-child button.selected {
  background-color: #20a1db !important;
  color: white;
}
#date-range-filter .tabs-vertical .tabs-panel .tab-content-child button.quarter-button {
  width: 264px;
  background-color: white;
  margin-right: 7px;
}
#date-range-filter .tabs-vertical .ui-datepicker {
  width: 60%;
  margin: 0 auto;
  box-shadow: none !important;
  border: none !important;
}
#date-range-filter .tabs-vertical .ui-datepicker .ui-datepicker-header {
  height: 40px;
}
#date-range-filter .tabs-vertical .ui-datepicker .ui-datepicker-title {
  line-height: 35px;
}
#date-range-filter .tabs-vertical #Custom .ui-datepicker {
  width: 100% !important;
}
#date-range-filter .tabs-vertical .ui-datepicker .ui-datepicker-calendar .ui-state-active,
#date-range-filter .tabs-vertical .datepicker table tr td.active:active,
#date-range-filter .tabs-vertical .datepicker table tr td.active.highlighted:active,
#date-range-filter .tabs-vertical .datepicker table tr td.active.active,
#date-range-filter .tabs-vertical .datepicker table tr td.active.highlighted.active {
  background-color: #20a1db !important;
  color: white !important;
  border-radius: 0 !important;
}

#accounts-filter-id .custom-dropdown-submenu li label, #accounts-filter-id .tabs-vertical .tabs-panel .tab-content .custom-dropdown-submenu li label, #accountsTablePartial .custom-dropdown-submenu li label, #accountsTablePartial .tabs-vertical .tabs-panel .tab-content .custom-dropdown-submenu li label {
  padding-left: 100px;
  font-weight: 400;
}
#accounts-filter-id .custom-dropdown-submenu li label input, #accounts-filter-id .tabs-vertical .tabs-panel .tab-content .custom-dropdown-submenu li label input, #accountsTablePartial .custom-dropdown-submenu li label input, #accountsTablePartial .tabs-vertical .tabs-panel .tab-content .custom-dropdown-submenu li label input {
  margin-left: -17px !important;
  width: 13px !important;
}

#accounts-filter-id #accounts-filter-dashboard-content-holder {
  max-height: none;
  width: 737px;
  overflow: hidden;
}
#accounts-filter-id .dashboard-filter-flexbox-container {
  display: flex;
  height: 373px;
}
#accounts-filter-id .dashboard-filter-flexbox-container .dashboard-filter-flexbox-item-rhs {
  flex-grow: 1;
}
#accounts-filter-id .dashboard-filter-flexbox-container .dashboard-filter-company-list-holder {
  overflow-y: hidden;
}
#accounts-filter-id .dashboard-filter-flexbox-container .tabs-vertical .tabs-panel div#Filters.tab {
  height: 100%;
  overflow-y: hidden;
}
#accounts-filter-id .dashboardFooterTableFilter {
  margin: 0;
}
#accounts-filter-id .button-container {
  float: right;
}
#accounts-filter-id .tabs-vertical {
  margin-top: 14px;
  position: absolute;
  width: 680px;
  padding: 0 !important;
  height: 445px !important;
}
#accounts-filter-id .tabs-vertical .tabs-nav.dashboard-filter-company-list-holder {
  height: 100%;
}
#accounts-filter-id .tabs-vertical .tabs-nav {
  float: left;
  width: 250px;
  margin-left: 0;
  padding: 0;
  height: 280px;
  min-height: 240px;
}
#accounts-filter-id .tabs-vertical .tabs-nav label {
  padding: 5px 10px 0 14px;
  font-weight: 400;
  width: 224px;
  white-space: pre-line;
  line-height: 20px;
  vertical-align: middle;
}
#accounts-filter-id .tabs-vertical .tabs-nav label:active {
  color: #fff;
}
#accounts-filter-id .tabs-vertical .tabs-nav .count {
  margin-left: -10px;
}
#accounts-filter-id .tabs-vertical .tabs-nav li {
  clear: left;
  width: 250px;
  list-style-type: none;
  margin-left: -4px;
}
#accounts-filter-id .tabs-vertical .tabs-nav li a {
  padding-left: 14px;
  line-height: 40px;
  display: block;
  color: inherit;
  font-weight: 300;
}
#accounts-filter-id .tabs-vertical .tabs-nav li.tabs-active {
  background-color: #20a1db;
}
#accounts-filter-id .tabs-vertical .tabs-nav li.tabs-active a {
  color: white;
}
#accounts-filter-id .tabs-vertical .tabs-panel {
  float: right;
  width: 485px;
  height: 100%;
  background: rgb(245, 245, 245);
  border-left: 1px #dfdfdf solid;
}
#accounts-filter-id .tabs-vertical .tabs-panel div.tab {
  height: 85%;
}
#accounts-filter-id .tabs-vertical .tabs-panel div.tab.tab-hidden {
  display: none;
}
#accounts-filter-id .tabs-vertical .tabs-panel .tab-header {
  width: 100%;
  height: 50px;
  line-height: 40px;
  color: rgba(0, 0, 0, 0.87);
  font-size: 14px;
  padding: 5px 0 0 14px;
}
#accounts-filter-id .tabs-vertical .tabs-panel .tab-header span {
  font-weight: 900;
}
#accounts-filter-id .tabs-vertical .tabs-panel .tab-content {
  width: 100%;
}
#accounts-filter-id .tabs-vertical .tabs-panel .tab-content input {
  width: 260px;
  height: 40px;
  padding-left: 3%;
  margin-left: 14px;
}
#accounts-filter-id .tabs-vertical .tabs-panel .tabs-footer {
  text-align: right;
  position: absolute;
  right: 14px;
  bottom: 5%;
  width: 50%;
}
#accounts-filter-id .tabs-vertical .tabs-panel .tabs-footer button {
  margin-right: 2%;
  font-weight: 700;
  margin-top: 5%;
}
#accounts-filter-id .tabs-vertical .tabs-panel .tabs-footer button.disabled {
  color: white;
  outline: none;
}
#accounts-filter-id .tabs-vertical .tabs-panel .tab-content-child {
  width: 100%;
  height: 40px;
  line-height: 40px;
  padding-left: 14px;
  padding-right: 14px;
  margin-bottom: 2%;
  color: #20a1db;
  font-weight: 700;
}
#accounts-filter-id .tabs-vertical .tabs-panel .tab-content-child button.month-button, #accounts-filter-id .tabs-vertical .tabs-panel .tab-content-child button {
  width: 127px;
  background-color: white;
  margin-right: 7px;
  justify-content: left;
}
#accounts-filter-id .tabs-vertical .tabs-panel .tab-content-child button.selected {
  background-color: #20a1db !important;
  color: white;
}
#accounts-filter-id .tabs-vertical .tabs-panel .tab-content-child button.quarter-button {
  width: 264px;
  background-color: white;
  margin-right: 7px;
}
#accounts-filter-id .tabs-vertical .ui-datepicker {
  width: 60%;
  margin: 0 auto;
  box-shadow: none !important;
  border: none !important;
}
#accounts-filter-id .tabs-vertical .ui-datepicker .ui-datepicker-header {
  height: 40px;
}
#accounts-filter-id .tabs-vertical .ui-datepicker .ui-datepicker-title {
  line-height: 35px;
}
#accounts-filter-id .tabs-vertical #Custom .ui-datepicker {
  width: 100% !important;
}
#accounts-filter-id .tabs-vertical .ui-datepicker .ui-datepicker-calendar .ui-state-active,
#accounts-filter-id .tabs-vertical .datepicker table tr td.active:active,
#accounts-filter-id .tabs-vertical .datepicker table tr td.active.highlighted:active,
#accounts-filter-id .tabs-vertical .datepicker table tr td.active.active,
#accounts-filter-id .tabs-vertical .datepicker table tr td.active.highlighted.active {
  background-color: #20a1db !important;
  color: white !important;
  border-radius: 0 !important;
}
#accounts-filter-id .company-dropdown-divs {
  display: inline-block;
}
#accounts-filter-id .company-dropdown-divs .dashboard-num-companies-label {
  color: #20a1db;
  cursor: pointer;
}
#accounts-filter-id .tabs-vertical .tabs-panel .tab-content {
  /*                input[type="checkbox"]{
                      width: 32px !important;
                  }*/
}
#accounts-filter-id .tabs-vertical .tabs-panel .tab-content input {
  width: auto;
  height: auto;
  padding: 0 !important;
  margin: 0 !important;
}
#accounts-filter-id .tabs-vertical .tabs-nav .accounts-filter-dashboard-company-list {
  padding: 0 0 0 4px;
  height: 302px;
  overflow-y: auto;
  overflow-x: hidden;
}
#accounts-filter-id .tabs-vertical .tabs-nav .accounts-filter-dashboard-company-list li {
  padding: 0;
}
#accounts-filter-id .tabs-vertical .tabs-nav .accounts-filter-dashboard-company-list li .dashboard-filter-company-list-checkbox {
  display: inline-table;
}
#accounts-filter-id .tabs-vertical .tabs-nav .accounts-filter-dashboard-company-list li .dashboard-filter-company-list-checkbox.checkbox input[type=checkbox]:checked label::after {
  margin-top: -11px;
}
#accounts-filter-id .dropdown-submenu {
  position: relative;
}
#accounts-filter-id .dropdown-submenu .dropdown-menu {
  top: 100%;
  left: 34px;
  margin-top: -1px;
  transition: none;
  visibility: visible;
  opacity: 1;
}
#accounts-filter-id .dropdown-submenu:hover .dropdown-menu {
  transition: none;
  visibility: visible;
  opacity: 1;
}
#accounts-filter-id .filter-column-width {
  width: 148px !important;
}
#accounts-filter-id .filter-column-width .custom-dropdown-submenu {
  width: 148px;
}
#accounts-filter-id .filter-column-label {
  line-height: 31px;
  color: #20a1db !important;
  font-size: 12px;
}
#accounts-filter-id th.dt-checkboxes-select-all.sorting_disabled {
  padding-left: 12px;
  width: 20px !important;
}
#accounts-filter-id th.dt-checkboxes-select-all.sorting_disabled input[type=checkbox] {
  margin: 0 10px 0 0 !important;
  padding: 0;
}
#accounts-filter-id td.row-text-left {
  padding-left: 2px;
  padding-right: 0;
}

.custom-dropdown-submenu li label {
  padding-left: 5px;
}

.accounts-filter-dashboard-openclose-button-container {
  position: fixed;
  top: 15px;
  left: 400px;
  z-index: 10000;
}

#date-range-filter .tabs-vertical .ui-datepicker > .ui-datepicker-header {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  background-color: #EBEBEB;
  border: none;
  color: #006290;
  font-weight: 400;
}

#date-range-filter .tabs-vertical .ui-datepicker > .ui-datepicker-header .ui-datepicker-next span, .ui-datepicker > .ui-datepicker-header .ui-datepicker-prev span {
  color: #6B6B6B;
}

#week-picker {
  background-color: #fff;
}

#date-range-filter .tabs-vertical #week-picker .ui-datepicker > .ui-datepicker-header {
  width: 575px;
  margin-left: -115px;
}

#date-range-filter .tabs-vertical #week-picker .ui-datepicker > .ui-datepicker-header .ui-datepicker-prev {
  left: 135px;
}

#date-range-filter .tabs-vertical #week-picker .ui-datepicker > .ui-datepicker-header .ui-datepicker-next {
  right: 135px;
}

.date-range {
  right: 170px;
  top: 14px;
}

.disappears-on-drilldown {
  margin-right: 95px;
}

@media (max-width: 1440px) {
  .date-range {
    margin-right: 10px;
  }
}
.dr-charts {
  position: absolute;
}

.dataTables_scrollBody {
  position: relative;
  overflow-x: hidden !important;
  overflow-y: scroll !important;
  width: 100%;
  max-height: 241px !important;
}

.filter-buttons-bottom {
  float: right;
  padding: 18px;
}

.bottom-margin {
  margin-right: 14px;
}

.filter-column-width {
  width: 130px !important;
  text-align: left !important;
}

.filter-column-button {
  width: 120px !important;
  text-align: left;
  color: #999;
}

.column-no-padding {
  padding-right: 0 !important;
}

.filter-head-table {
  background-color: rgb(245, 245, 245);
}

.column-checkbox {
  width: 20px !important;
  padding-right: 10px !important;
}

.filter-last-column {
  padding-right: 14px !important;
}

.filter-head-table-hidden {
  display: table-footer-group;
}

.dataTables_scrollHead {
  padding-bottom: 6px;
}

table#dashboard-accounts-filter-table > tbody > tr {
  height: 40px;
  font-size: 14px;
}

table#dashboard-accounts-filter-table {
  background: transparent !important;
}

input#columnAccountDashboardFilter {
  height: 34px !important;
  width: 110px !important;
}

table#dashboard-accounts-filter-table > tbody > tr > td:first-child {
  width: 20px !important;
}

.row-text-left {
  text-align: left !important;
}

.filter-arrow-right {
  position: absolute;
  right: 40px;
  top: 45px;
}

.filter-submenu {
  width: 200px;
  padding: 14px !important;
}

#buDashboardOptions.filter-submenu {
  overflow: auto !important;
  max-height: 475% !important;
}

#carrierDashboardOptions.filter-submenu {
  overflow: auto !important;
  max-height: 475% !important;
}

/*


<style>  

.tabs-vertical{position:absolute; width:55em; padding:0!important; height:380px!important; max-height: 380px!important; }
.tabs-vertical .tabs-nav {float:left; width:15%; margin-left:0;padding-left:0; height:280px; min-height: 240px;}
.tabs-vertical .tabs-nav li { clear: left; width: 100%; list-style-type: none;  height:40px;  }
.tabs-vertical .tabs-nav li a {padding-left: 5%; height:40px; line-height: 40px; display:block; color:black; font-weight: 700; }
.tabs-vertical .tabs-nav li.tabs-active {background-color: #20a1db;}
.tabs-vertical .tabs-nav li.tabs-active a{color:white;}
.tabs-vertical .tabs-panel {float: right; width: 85%;  height:100%; background: rgb(235, 235, 228); border-left: 1px #dfdfdf solid;}
.tabs-vertical .tabs-panel div.tab {height: 100%;}
.tabs-vertical .tabs-panel .tab-header { width: 100%; height: 40px; line-height: 40px; padding-left: 2%; color: #20a1db; font-weight: 700; }
.tabs-vertical .tabs-panel .tab-content { width: 100%; padding-left:2%; }
.tabs-vertical .tabs-panel .tab-content input { width: 47%; height: 40px; margin-right: 2%; padding-left: 3%;}
.tabs-vertical .tabs-panel .tabs-footer { text-align: right; position: absolute; right: 5%; bottom: 5%; width: 50%; }
.tabs-vertical .tabs-panel .tabs-footer button { margin-right: 2%; font-weight: 700; margin-top: 5%;}
.tabs-vertical .tabs-panel .tabs-footer button.disabled { color: white; outline: none;}
.tabs-vertical .tabs-panel .tab-content-child { width: 100%; height: 40px; line-height: 40px; padding-left: 5%; padding-right: 5%; margin-bottom: 2%; color: #20a1db; font-weight: 700; }
.tabs-vertical .tabs-panel .tab-content-child button.month-button,
.tabs-vertical .tabs-panel .tab-content-child button { width:22%; background-color: white; margin-right:2%; }
.tabs-vertical .tabs-panel .tab-content-child button.selected {background-color: #20a1db!important; color:white; }
.tabs-vertical .tabs-panel .tab-content-child button.quarter-button { width:46%; background-color: white; margin-right:2%; }
/*datepicker*/
/*
.ui-datepicker { width:60%; box-shadow: none!important; border: none!important;}
#Custom .ui-datepicker {width: 100%!important; }
.ui-datepicker .ui-datepicker-header { height: 40px; }
.ui-datepicker .ui-datepicker-title {line-height: 35px;}
.ui-datepicker .ui-datepicker-calendar .ui-state-active,
.datepicker table tr td.active:active, 
.datepicker table tr td.active.highlighted:active, 
.datepicker table tr td.active.active, 
.datepicker table tr td.active.highlighted.active {
  background-color: #20a1db!important;
  color:white!important;
  border-radius: 0!important;
}
</style>
*/
.color-half-white {
  color: white;
  color: rgba(255, 255, 255, 0.5);
}

.line {
  color: black;
  height: 1px;
  width: 100%;
  background-color: black;
}

.pagination > li > a {
  color: grey;
}

.pagination > .active > a, .pagination > .active > a:focus, .pagination > .active > a:hover, .pagination > .active > span,
.pagination > .active > span:focus, .pagination > .active > span:hover {
  z-index: 2;
  color: #fff;
  cursor: default;
  background-color: #173f6c;
  border-color: #173f6c;
}

.btn-default.active.focus, .btn-default.active:focus, .btn-default.active:hover, .btn-default:active.focus, .btn-default:active:focus, .btn-default:active:hover {
  color: #fff;
  background-color: #127bab;
  border-color: #8c8c8c;
}

.bootstrap-select .dropdown-toggle:focus {
  background-color: #f5f5f5;
}

.dropdown-backdrop {
  z-index: 0 !important;
  display: none;
}

/*
//	*************
//	*************
//	BEGIN SCSS
//	*************
//	*************
*/
.modal-lg {
  width: 100%;
}

@media (min-width: 768px) {
  .modal-lg {
    width: 80%;
  }
}
.search-box-header {
  height: 2.34375rem;
  background-color: black;
  width: 100%;
  padding: 0;
  border-radius: 0.75rem;
}

@media (min-width: 1500px) {
  .headline-box {
    overflow: hidden;
    border-radius: 0.75rem;
    margin-bottom: 1rem;
    margin-top: 1rem;
  }
}
.invoice-table-button {
  border: 0.01625rem solid black;
  color: black;
  border-radius: 0.5rem;
  opacity: 0.3;
  padding: 0.3rem;
}

.invoice-table-button:hover {
  opacity: 0.6;
  text-decoration: none;
  color: black;
}

.invoice-table-button:active {
  opacity: 0.5;
  box-shadow: 0 0.14rem 0.01rem 0 rgb(196, 196, 196);
  outline: 0;
}

.copyToClipboard, .downloadPartialCsvBtn {
  margin-left: 0.75rem;
}

#datatables_buttons_info {
  display: none !important;
}

.loader {
  z-index: 1000000000000;
  height: 4.6875rem;
  width: 4.6875rem;
  position: fixed;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  display: block;
}

.spacer-1 {
  margin-left: 1.125rem;
}

.btn-default:active:focus,
.info-button-1:active, .info-button-1:focus,
.info-button-2:active, .info-button-2:focus,
.info-button-3:active, .info-button-3:focus,
.info-button-4:active, .info-button-4:focus,
.info-button-5:active, .info-button-5:focus,
.info-button-6:active, .info-button-6:focus {
  outline: none;
}

.red-border {
  border: 0.01625rem solid #d91a1a;
}

body {
  font-family: "Roboto", sans-serif;
  background-color: whitesmoke;
}

.today-date {
  color: #d3d3d3;
  color: rgba(81, 81, 81, 0.25);
  text-align: center;
  margin-top: 2.5rem;
  margin-left: 1.2rem;
  font-size: 1.5rem;
}

.today-date:hover {
  color: #a8a8a8;
  color: rgba(81, 81, 81, 0.5);
}

.countDown, .countUp, .week-ending {
  color: rgb(81, 81, 81);
  background-color: #F7F7F7;
  padding: 0.75rem;
  font-size: 2rem;
}

.interval-button {
  margin-left: 0.5rem;
  padding: 0.375rem 0.75rem;
  background-color: #F7F7F7;
  color: #d3d3d3;
  color: rgba(81, 81, 81, 0.25);
  font-size: 2rem;
}

.interval-button:focus, .countDown:focus, .countUp:focus, .week-ending:focus, .interval-button:active, .countDown:active, .countUp:active, .week-ending:active {
  outline: none !important;
  box-shadow: 0 0.14rem 0.25rem 0 rgb(173, 173, 173);
}

.interval-active {
  background-color: #7fcce6;
  background-color: rgba(0, 153, 206, 0.5);
  color: #F7F7F7;
}

.interval-active:hover, .interval-active:active, .interval-active:focus {
  color: white;
  outline: 0;
}

.chart-title-preview {
  white-space: normal;
  display: block;
}

.sidebar li.active > a,
.sidebar ul li a.active:hover,
.sidebar ul li a:hover {
  background-color: rgb(225, 225, 225);
  color: #212121;
  color: rgba(0, 0, 0, 0.87);
}

.nav > li > a:focus, .nav > li > a:hover {
  background-color: whitesmoke;
}

.nav {
  background-color: whitesmoke;
}

.sidebar ul li {
  border-bottom: none;
}

.sidebar ul li a {
  color: #212121;
  color: rgba(0, 0, 0, 0.87);
  font-family: "Roboto Regular", sans-serif;
  font-size: 1rem;
  font-weight: bold;
}

.custom-search-form {
  display: none;
  width: 100%;
  position: absolute;
  top: 3rem;
  padding: 0;
  border: none;
}

.zero-height-element {
  height: 0;
  min-height: 0;
  border: 0;
  padding: 0;
  margin: 0;
}

#Union_1 {
  fill: currentColor;
}

.blue-circle-white-text {
  position: absolute;
  display: block;
  height: 1.5rem;
  width: 1.5rem;
  line-height: 1.5rem;
  border-radius: 0.75rem;
  background-color: #20a1db;
  color: #fff;
  text-align: center;
  top: 0.6rem;
}

.content-full-width {
  box-shadow: 0 0.01625rem 0.25rem rgba(128, 128, 128, 0.59);
  padding: 0;
  margin-right: -1.3333333333rem;
  margin-left: -1.3333333333rem;
}

.centered-vertically-relative {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.option-current {
  border-radius: 0;
}

#page-wrapper {
  display: table;
  width: calc(100% - 13rem);
  margin: 3.5555555556rem 0 0 13rem;
  padding: 0 24px;
  height: calc(100vh - 3.5555555556rem);
}

.result-container {
  border: solid 0.01625rem #dfdfdf;
  margin: 0 -15px;
  margin-top: 3.6rem;
}

/*
//	*************
//	*************
//	MEDIA QUERIES
//	*************
//	*************
*/
@media (min-width: 768px) {
  .sidebar {
    background-color: #f5f5f5;
    width: 13rem;
  }
  .nav {
    background-color: #f5f5f5;
  }
  .custom-search-form {
    float: right;
    display: inline-flex;
    width: 14rem;
    margin-right: 2.35rem;
    margin-top: 0.6rem;
    position: relative;
    top: 0;
  }
  .navbar-fixed-large {
    position: fixed;
    top: 0;
  }
  .nav-menu-bottom {
    position: fixed;
    width: 12.4444rem;
    margin-bottom: 1rem;
  }
  .nav-menu-top {
    position: fixed;
    width: 13rem;
    margin-top: 2rem;
    display: block;
  }
  .sidebar-search-submit-btn {
    display: inherit;
  }
}
@media (min-width: 992px) {
  .huge {
    font-size: 2.5rem;
  }
}
@media (min-width: 1300px) {
  .huge {
    font-size: 3.5rem;
  }
}
.testmeasure {
  font-size: 63px;
  font-size: 3rem;
}

.pin-modal {
  z-index: 1150;
}

@media (max-width: 1440px) {
  .nav-menu-bottom {
    position: relative;
    margin-top: 40px;
  }
}
/*@media (max-width:1440px) {
  table.table>thead>tr>th, table.table>tbody>tr>td, table.table>tfoot>tr>td {
    padding-right: 0.5rem!important;
  }
  table.table>thead>tr>th:first-child, table.table>tbody>tr>td:first-child, table.table>tfoot>tr>td:first-child {
    padding-left: 1.5rem!important;
  }
  table.table>thead>tr>th:last-child, table.table>tbody>tr>td:last-child, table.table>tfoot>tr>td:last-child {
    padding-right: 1.5rem!important;
  }
  .textcolumn-center, table.table>tbody>tr>td, table.table>tfoot>tr>td {
    font-size: 14px!important;
  }
  }
*/
/* width */
::-webkit-scrollbar {
  width: 1vw;
}

/* Track */
::-webkit-scrollbar-track {
  background: #fff;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #1894aa;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #B9B9B9;
}

table.dataTable thead > tr > th.sorting, table.dataTable thead > tr > th.sorting_asc, table.dataTable thead > tr > th.sorting_desc {
  outline: none;
}

.package-data table.tbl-chart-packages {
  margin-bottom: 60px;
}

input[type=search] {
  border: 1px solid gray;
  padding: 0.2em 0.4em;
  border-radius: 0.2em;
  color: black;
}

input[type=search]::-webkit-search-cancel-button {
  -webkit-appearance: none;
  display: inline-block;
  width: 12px;
  height: 12px;
  margin-left: 10px;
  background: linear-gradient(45deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 43%, #000 45%, #000 55%, rgba(0, 0, 0, 0) 57%, rgba(0, 0, 0, 0) 100%), linear-gradient(135deg, transparent 0%, transparent 43%, #000 45%, #000 55%, transparent 57%, transparent 100%);
}