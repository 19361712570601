#breadcrumbs-container{
  display: flex;
  align-items: center;
  min-width: 30rem;
  text-overflow: ellipsis;
  >*{
    &:not(:last-child){
      color: $half-black-fallback;
      color: $half-black;
    }
    font-size: getrem(18);
    line-height: 1;
  }
  .breadcrumb{
    padding:0;
    margin: 0 getrem(8);
    text-transform: capitalize;
    overflow: hidden;
    height: 21px;
    &:first-child{
      margin-left: 0;
    }
  }
}

#chart-details-breadcrumbs-container{
  display: flex;
  align-items: center;
  min-width: 30rem;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  >*{
    &:not(:last-child){
      color: $half-black-fallback;
      color: $half-black;
    }
    font-size: getrem(18);
    line-height: 22px;
    float: left;
  }
  .breadcrumb{
    padding:0;
    margin: 0 getrem(8);
    text-transform: capitalize;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    line-height: 22px;
    flex-shrink: 0;
    &:first-child{
      margin-left: 0;
    }
    &--shrink {
      flex-shrink: 1;
    }
  }
}

.chart-title.chart-title-0.chart-title-breadcrumbs {
  flex: 0 0 auto;
}
.chart-title.chart-title-0.contract-title-breadcrumbs {
  flex: 0 0 auto;
}
.dropdown-menu>li>a  {
cursor:pointer;
}