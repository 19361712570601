.chart-type-navigation{
  border-bottom: solid getrem(1) $outline-grey;
  margin: 0 getrem(-24) getrem(24);
  padding: 0 getrem(24);
  background-color: $bg-grey;
  display: flex;
  align-items: center;
  position: fixed;
  width:88.125%;
  z-index: 5;
  .chart-type-navigation-item{
    padding:1.05rem;
    color: $dark-gray;
    cursor: pointer;
    transform: translateY(getrem(2));
    &.active{
      border-bottom: solid getrem(3) $light-blue;
      color: $light-blue;
      padding-bottom: 0.8889rem;
    }
  }
  .sidebar-toggle{
    text-transform: uppercase;
    color: $light-blue; 
    /*width:100%;*/
    &:hover{
      text-decoration: none;
    }
  }
  /* css@media screen and(min-width: 1920px){
    width: 87.825%;
  }
  @media screen and(min-width: 1600px)and(max-width: 1919px){
    width: 87.425%;
  }
  @media screen and(min-width: 1440px)and(max-width: 1599px){
    width: 85.325%;
  }
  @media screen and(min-width: 1366px)and(max-width: 1439px){
    width: 82.825%;
  }
  @media screen and (max-width: 1365px){
    width: 80.425%;
  }*/
}
div.dropdown_calc, .top_right{
position: fixed;
top: 78px;
right: 0;
margin: 0 20px;
}
.top_right_left{
  position: fixed;
  top: 78px;
  right: 0;
  margin: 0 155px;
  }
.top_right_middle{
  position: fixed;
  top: 78px;
  right: 0;
  margin: 0 100px;
  }
  .top_right_middle2{
    position: fixed;
    top: 78px;
    right: 0;
    margin: 0 85px;
    }  
  .top_right_contract{
    position: fixed;
    right: 0;
    top: 78px;
    margin: 0 30px;
    }
.dropdown-toggle .caret {
  height: 0;
  margin-top: auto;
  margin-bottom: auto;
}

//sidebar object to select the currently displayed week.
.selected-week{
  background: transparent;
  border: none;
  outline: none;
  text-align: center;
  width: 10rem;
  &.active{
    background: #ddf0f8;
    background: rgba($light-blue, 0.12);
    border: solid $light-blue 0.01625rem;
  }
}

//custom radio for trends charts

.conteiner-custom-radio{
  .radio-custom {
    opacity: 0;
    position: absolute;   
  }

  .radio-custom, .radio-custom-label {
    display: inline-block;
    vertical-align: middle;
    margin: 5px;
    cursor: pointer;
  }

  .radio-custom-label {
    position: relative;
    font-size:12px;
  }

  .radio-custom + .radio-custom-label:before {
    content: '';
    background: #fff;
    border: 1px solid #ddd;
    display: inline-block;
    vertical-align: middle;
    width: 24px;
    height: 24px;
    padding: 0px;
    margin-right: 10px;
    text-align: center;
  }



  .radio-custom + .radio-custom-label:before {
    border-radius: 50%;
  }

  .radio-custom:checked + .radio-custom-label:before {
    content: "\f107";
    font-family: 'FontAwesome';
    color: white;
    background:  rgb(4, 185, 4);
    font-size: 18px;
  }
}